import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GeneralText } from '../../common/CommonStyles';

const Explanation = ({ content }) => {
    let key = 0;

    return (
        <React.Fragment>
            {content.map((line) => (
                <GeneralText key={key++} align="center">
                    <ReactMarkdown>{line}</ReactMarkdown>
                </GeneralText>
            ))
            }
        </React.Fragment>
    );
};

export default Explanation;