import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    border: 'solid 2.5px #FFF',
    borderRadius: '10px',
    background: theme => theme.background,
    boxShadow: theme => 
        `0 0 0 2px ${theme.shadow},
        0 2px 0 3px ${theme.shadow}`,
    lineHeight: '0.9',
    marginLeft: '15px',
    alignItems: 'center',
    color: '#FFF',
  },
}));

export default function CustomButton(props) {
  const {content, onClick, icon} = props;

  const findColor = (content) => {
    switch (content) {
      case 'Save':
      case 'Play':
        return {background: '#35D461', shadow: '#29A547'};
      case 'Logout':
        return {background: '#E7655A', shadow: '#963636'};
      case 'Reset':
        return {background: '#CB55FF', shadow: '#8200BC'};
    }
  }
  const classes = useStyles(findColor(content));

  return (
    <Button
      className={classes.container}
      onClick={onClick}
    >
      {icon}
      {content}
    </Button>
  );
}