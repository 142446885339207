import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DonutLargeSharpIcon from '@material-ui/icons/DonutLargeSharp';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const Dashboard = ({ exercises }) => {
  const classes = useStyles();

  const getType = (type) => {
    switch (type) {
      case 'maze':
        return 'Maze Exercise';
      case 'artist':
        return 'Artist Exercise';
      case 'python':
        return 'Python Exercise';
      case 'lesson':
        return 'Lesson';
      case 'true-false':
        return 'True or False';
      case 'code-input':
        return 'Code Input';
      case 'code-input-only':
        return 'Code Input';
      case 'text-boxes':
        return 'Fill in the Blank';
      case 'drag-drop':
        return 'Drag and Drop';
      default:
        return `${type} is not a valid solution type`
    }

  }

  const generateElem = () => {
    // Exercises displayed in the dashboard need to be previously completed
    return exercises.filter((element) => (element.completed === false)).map((exercise, index) => (
      <Link
        to={exercise.type === 'python' ? `/exercise/${exercise.id}` : `/${exercise.type}/${exercise.id}`}
        key={exercise.id}
        style={{ textDecoration: 'none' }}
      >
        <ListItem button>
          <ListItemAvatar>
            <Avatar>
              {exercise.completed ? (
                <CheckCircleIcon />
              ) : (
                <DonutLargeSharpIcon />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${getType(exercise.type)}: ${(exercise.id)}`} />
        </ListItem>
      </Link>
    ));
  };

  return (
    <div className={classes.root}>
      <h2>Exercises</h2>
      <List>{generateElem()}</List>
    </div>
  );
};

Dashboard.propTypes = {
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ).isRequired,
  completes: PropTypes.arrayOf(
    PropTypes.shape({
      completed: PropTypes.bool.isRequired
    })
  ).isRequired
};

const mapStateToProps = (state) => ({
  exercises: state.exercises.ids,
  completes: state.exercises.performance
});

export default connect(mapStateToProps, {})(Dashboard);
