import React, { useState, useEffect, useReducer } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import ExerciseDisplay from './ExerciseDisplay';
import LoadingPage from '../utils/LoadingPage';
import Timer from '../utils/Timer';
import CongratsModal from '../utils/CongratsModal';
import Typography from '@material-ui/core/Typography';
import {
    NumberUrls,
    sleep,
    pathExists,
    getUserCookies
} from '../utils/utils';
import { setCurrentExercise, completeExercise, setAttempt } from '../../actions';
import CodeInputArea from '../utils/CodeInputArea'
import ReadonlyDisplay from '../utils/ReadonlyDisplay';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 800
    },
    instruction: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    }
}));

const Pattern = (props) => {
    var {
        match: {
            params: { id: exerciseId }
        },
        exercise, // exercise data
        exerciseIds, // exercise id list used for link to next exercise
        history // Routing variable
    } = props;
    const [loading, setLoading] = useState(true);

    // Component styling and screen resolution
    const classes = useStyles();
    const bigScreen = useMediaQuery('(min-width: 1650px)');
    const mediumScreen = useMediaQuery(
        '(min-width: 1400px) and (max-width: 1650px)'
    );
    const smallScreen = useMediaQuery(
        '(min-width: 1150px) and (max-width: 1400px)'
    );
    const [SCALE, setSCALE] = useState(60); // Image scale
    useEffect(() => {
        if (bigScreen) setSCALE(60);
        else if (mediumScreen) setSCALE(50);
        else if (smallScreen) setSCALE(40);
        else setSCALE(30);
    }, [bigScreen, mediumScreen, smallScreen]);

    const [completed, setCompleted] = useState(false);
    const [nextExercise, setnextExercise] = useState(); // next exercise after exercise completion (used in modal)
    const [solveTime, setSolveTime] = useState(0);
    const [clockInterval, setClockInterval] = useState();
    const [previouslyCompleted, setPreviouslyCompleted] = useState(false);
    const [code, setCode] = useState('# Input code here');
    const [previousCode, setPreviousCode] = useState('# Input code here');
    const codeResultReducer = (state, action) => {
        switch (action.type) {
            case "SET_TOKEN":
                return {
                    ...state,
                    token: action.token,
                }
            case "WAITING_INPUT":
                return {
                    ...state,
                    isLoading: false,
                    error: "",
                    output: "Waiting for input submission...",
                    description: action.type
                };
            case "WAITING_EXECUTION":
                return { ...state, isLoading: true, description: action.type };
            case "SUCCESS":
            case "WRONG_ANSWER":
                return { ...state, isLoading: false, output: action.output, description: action.type };
            case "COMPILE_ERROR":
                return { ...state, isLoading: false, error: action.error, description: action.type };
            case "RUNTIME_ERROR":
                return { ...state, isLoading: false, error: action.error, description: action.type };
            default:
                return state;
        }
    }
    const initialCodeResultState = {
        isLoading: false, token: "",
        error: "", output: "Waiting for input submission...", description: "WAITING_INPUT"
    }
    const [{ isLoading, token, error, output, description }, dispatchCodeResult] = useReducer(
        codeResultReducer, initialCodeResultState);

    // Get exercise data from database
    useEffect(() => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_EXE_API}/pattern/${exerciseId}`)
            .then((response) => {
                props.setCurrentExercise(response.data);
                setCode(response.data.initialCode);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                props.enqueueSnackbar('Failed to fetch exercise', {
                    variant: 'error'
                });
            });
    }, [exerciseId]);

    // Handle next exercise
    useEffect(() => {
        if (exercise && exerciseIds && exerciseIds.length > 0) {
            // find the current exercise index in exercise id list in redux store
            const currentIndex = exerciseIds.findIndex(
                (elem) => elem.id === exercise._id
            );

            // if found current exercise and index is within bounds
            // set next exercise by incrementing the current index, otherwise set to 0
            if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
                setnextExercise(
                    {
                        id: exerciseIds[currentIndex + 1].id,
                        type: exerciseIds[currentIndex + 1].type
                    }
                );
            } else if (currentIndex == exerciseIds.length - 1) {
                setnextExercise(null);
            } else {
                // exerciseIds.length > 0
                setnextExercise(
                    {
                        id: exerciseIds[0].id,
                        type: exerciseIds[0].type
                    }
                );
            }
        } else {
            setnextExercise(null);
        }
    }, [exercise, exerciseIds]);

    const restartClock = () => {
        // Start the clock
        clearInterval(clockInterval);
        const interval = setInterval(() => {
            setSolveTime((time) => time + 1);
        }, 1000);
        setClockInterval(interval);
    }

    /* eslint-enable no-unused-vars */

    const saveWorkspace = () => {
        const performanceData = {
            Actual_lines: 'a',
            ExerciseCode:
                exercise.level +
                String(exercise.difficulty) +
                String(exercise.story).padStart(3, '0'),
            Expected_lines: 'a',
            Exp_time: exercise.expectedTime,
            Act_time: solveTime,
            Code: 'A',
            Status: 'Incomplete'
        };

        props.setAttempt({
            previous_code: performanceData.Code,
            previous_line: 0, // unused
            previous_time: performanceData.Act_time
        });

        axios
            .post(`${process.env.REACT_APP_LMS_API}/save`, performanceData, {
                headers: { Authorization: `Token ${getUserCookies()}` }
            })
            .then((response) => {
                if (response.data.success === 'True') {
                    props.enqueueSnackbar('Code Saved', {
                        variant: 'success'
                    });
                } else {
                    props.enqueueSnackbar('Failed to save code', {
                        variant: 'error'
                    });
                }
            });
    };

    const loadPreviousCode = () => {
        const currentIndex = exerciseIds.findIndex(
            (elem) => elem.id === exerciseId
        );

        if (currentIndex < exerciseIds.length && currentIndex >= 0) {
            try {
                // TODO: Code from exerciseIds[currentIndex].previous_code to workspace
                setSolveTime(exerciseIds[currentIndex].previous_time);
            } catch (err) {
                props.enqueueSnackbar('Previous code outdated or not found', {
                    variant: 'error'
                });
            }
        } else {
            props.enqueueSnackbar('Exercise not listed in dashboard', {
                variant: 'error'
            });
        }
    };

    const sendPerformance = () => {
        const performanceData = {
            Actual_lines: 1,
            ExerciseCode:
                exercise.level +
                String(exercise.difficulty) +
                String(exercise.story).padStart(3, '0'),
            Expected_lines: exercise.expectedBlocks,
            Exp_time: exercise.expectedTime,
            Act_time: solveTime,
            Code: 'A',
            Status: 'Complete'
        };

        axios
            .post(`${process.env.REACT_APP_LMS_API}/save`, performanceData, {
                headers: { Authorization: `Token ${getUserCookies()}` }
            })
            .then((response) => {
                if (response.data.success === 'True') {
                    props.enqueueSnackbar('Performance saved', {
                        variant: 'success'
                    });
                } else {
                    props.enqueueSnackbar('Failed to save performance', {
                        variant: 'error'
                    });
                }
            });
    };

    const submissions = () => {
        // TODO: Handle batch submission if exercise required multiple tests
        if (code !== '' && code !== previousCode) {
            setPreviousCode(code);
            const submissionData = {
                source_code: Buffer.from(code).toString('base64'),
                expected_output: Buffer.from(exercise.test.expected_output).toString('base64'),
                stdin: Buffer.from(exercise.test.stdin).toString('base64')
            }

            axios.post(
                `${process.env.REACT_APP_EXE_API}/submission/${exercise.language}`, submissionData).
                then((submissionResponse) => {
                    dispatchCodeResult({ type: "SET_TOKEN", token: submissionResponse.data });
                    dispatchCodeResult({ type: "WAITING_EXECUTION" });
                }).catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
        }
        else if (code === '') {
            console.log("You must enter your codes before click on play button");
        } else if (code === previousCode) {
            refresh();
        }
    }

    const refresh = () => {
        // TODO: Maybe a bit timing sensitive.
        if (token !== '') {
            console.log(token);
            axios.get(`${process.env.REACT_APP_EXE_API}/submission/python/${token}`)
                .then((codeResultResponse) => {
                    // stdout and stderr from judge0 can only be null or base64 encoded string
                    // This converts the field from either null or base64 encoded string into ASCII string
                    const stdout = (codeResultResponse.data.stdout) ?
                        (Buffer.from(codeResultResponse.data.stdout, 'base64').toString()) : '';
                    const stderr = (codeResultResponse.data.stderr) ?
                        (Buffer.from(codeResultResponse.data.stderr, 'base64').toString()) : '';
                    if (codeResultResponse.data.status.description === 'Compilation Error') {
                        dispatchCodeResult({
                            type: "COMPILE_ERROR",
                            output: '',
                            error: Buffer.from(codeResultResponse.data.compile_output, 'base64').toString()
                        });
                    }
                    if (codeResultResponse.data.status.description === 'Accepted') {
                        dispatchCodeResult({
                            type: "SUCCESS", output: stdout, error: stderr
                        });
                    }
                    if (codeResultResponse.data.status.description === 'Processing') {
                        console.log('still on loading process, please click on the refresh button again');
                    }
                    if (codeResultResponse.data.status.description === "Runtime Error (NZEC)") {
                        console.log("There is runtime error (NZEC)");
                    }
                    if (codeResultResponse.data.status.description === "Wrong Answer") {
                        dispatchCodeResult({
                            type: "WRONG_ANSWER", output: stdout, error: stderr
                        });
                    }
                    console.log(codeResultResponse);
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        }

    }

    useEffect(() => {
        refresh();
    }, [token]);

    if (loading) return <LoadingPage />;
    return (
        <div>
            {/* Modal to next exercise after completion */}
            {/* <CongratsModal
                nextExercise={nextExercise}
                open={completed}
                setOpen={setCompleted}
                lineNum={actualBlocks}
                history={history}
                clockInterval={clockInterval}
                restartClock={restartClock}
                successGIF={exercise.character.success}
                solveTime={solveTime}
                previouslyCompleted={previouslyCompleted}
            /> */}
            {/* Annotation and Instruction */}
            {/* <Box display="flex" m={1} p={1} bgcolor="background.paper">
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Paper className={classes.instruction}>
                            <Instruction
                                avatar={exercise.character.right}
                                instruction={exercise.instruction}
                                errMessage={exercise.errMessage}
                                error={error}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper className={classes.instruction}>
                            <Instruction
                                avatar={exercise.character.right}
                                instruction={exercise.instruction}
                                errMessage={exercise.errMessage}
                                error={error}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Box> */}
            {/* Clock */}
            <Box display="flex" m={1} p={1} bgcolor="background.paper">
                <Timer time={solveTime} />
            </Box>
            {/* Code Area*/}
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        m={1}
                        p={1}
                        bgcolor="background.paper"
                    >
                        <Paper className={classes.paper}>
                            <Typography>
                                Language: {exercise.language}
                            </Typography>
                            <Typography>
                                Description: {description}
                            </Typography>
                            <ExerciseDisplay exercise={exercise} />
                            <Typography align='left' fontWeight="fontWeightBold">
                                <Box fontWeight="fontWeightBold" m={1}>
                                    Output:
                                </Box>
                                <ReadonlyDisplay
                                    value={output}
                                    height={120}
                                    theme='tomorrow_night'
                                />
                            </Typography>
                            <Typography align='left' fontWeight="fontWeightBold">
                                <Box fontWeight="fontWeightBold" m={1}>
                                    Error:
                                </Box>
                            </Typography>
                            <ReadonlyDisplay
                                value={error}
                                height={120}
                                theme='tomorrow_night'
                            />
                            <p>{token}</p>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        m={1}
                        p={1}
                        bgcolor="background.paper"
                    >
                        <CodeInputArea
                            language={exercise.language}
                            code={code} setCode={setCode}
                            theme={'tomorrow_night'}
                        />
                    </Box>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                m={1}
                                p={1}
                                bgcolor="background.paper"
                            >
                                <Button variant="contained" color="primary"
                                    onClick={submissions}
                                >
                                    RUN
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                m={1}
                                p={1}
                                bgcolor="background.paper"
                            >
                                <Button variant="contained" color="primary"
                                    onClick={refresh}
                                >
                                    REFRESH
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Box
                        display="flex"

                        bgcolor="background.paper">

                        {isLoading ? <LoadingPage /> :
                            <div>
                                <Typography align='left' fontWeight="fontWeightBold">
                                    <Box fontWeight="fontWeightBold" m={1}>
                                        Error:
                                    </Box>
                                    <ReadonlyDisplay
                                        value={error}
                                        height={150}
                                        theme='tomorrow_night'
                                    />
                                </Typography>
                                <Typography align='left' fontWeight="fontWeightBold">
                                    <Box fontWeight="fontWeightBold" m={1}>
                                        Output:
                                    </Box>
                                    <ReadonlyDisplay
                                        value={output}
                                        height={150}
                                        theme='tomorrow_night'
                                    />
                                </Typography>
                                <p>Description: {description}</p>
                                <p>{token}</p>
                            </div>
                        }
                    </Box> */}
            </Grid>
        </div >
    );
};

Pattern.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.objectOf(PropTypes.string).isRequired
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    setCurrentExercise: PropTypes.func.isRequired,
    exercise: PropTypes.object,
    completeExercise: PropTypes.func.isRequired,
    setAttempt: PropTypes.func.isRequired,
    exerciseIds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        })
    )
};

Pattern.defaultProps = {
    exercise: null,
    exerciseIds: null
};

const mapStateToProps = (state) => ({
    exercise: state.exercises.current,
    exerciseIds: state.exercises.ids
});

export default connect(mapStateToProps, {
    setCurrentExercise,
    completeExercise,
    setAttempt
})(withSnackbar(Pattern));
