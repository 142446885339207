import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: "3px 15px",
        background: "#2196f3",
        width: "flex",
        //   height: "40%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        margin: "1px 1px",
        borderRadius: "8px",
        color: "#FFFFFF",
        zIndex: "101"
    }
}));

const Card = (props) => {

    const classes = useStyles();

    const dragStart = (event) => {
        const target = event.target;

        event.dataTransfer.setData("card_id", target.id);

        event.dataTransfer.setData("value", props.value);

        event.dataTransfer.setData("parent_board", document.getElementById(target.id).parentNode.id);
    }

    const swap = async (event) => {
        event.preventDefault();

        // set the event target to the board
        // prevents the card from being dropped into the other card
        if (event.target.id.includes("card")) {
            event.target = event.target.parentElement;
        } else if (event.target.id.includes("text")) {
            event.target = event.target.parentElement.parentElement;
        }

        let card1Id = props.id;
        let card1 = document.getElementById(card1Id);
        let card1Board = card1.parentElement;
        let card1Value = props.value;

        let card2Id = event.dataTransfer.getData("card_id");
        let card2 = document.getElementById(card2Id);
        let card2Board = card2.parentElement;
        let card2Value = event.dataTransfer.getData("value");

        if (card1Board == card2Board) {
            // do not swap cards if they're on the same board
            event.stopPropagation();
        } else {
            // swap cards between different boards
            let newCard1 = card2Board.removeChild(card2);
            let newCard2 = card1Board.removeChild(card1);
            card1Board.appendChild(newCard1);
            card2Board.appendChild(newCard2);
            
            // update the answer and set the cards' width accordingly
            if (card1Board.id == "selection-board") {
                // swapping cards between selection board and answer board
                props.updateAnswer(card1Value, card2Board.id.substring(6));

                // use requestAnimationFrame to wait for the next frame
                // ensures that the styles below apply to newCard1 properly
                await new Promise((resolve) => requestAnimationFrame(resolve));

                newCard1.style.width = "max-content";
                newCard2.style.width = "60%";
            } else if (card2Board.id == "selection-board") {
                // swapping cards between selection board and answer board
                props.updateAnswer(card2Value, card1Board.id.substring(6));
                
                await new Promise((resolve) => requestAnimationFrame(resolve));

                newCard1.style.width = "60%";
                newCard2.style.width = "max-content";
            } else {
                // swapping cards between answer boards
                props.swapAnswers(card1Board.id.substring(6), card2Board.id.substring(6));

                await new Promise((resolve) => requestAnimationFrame(resolve));

                newCard1.style.width = "60%";
                newCard2.style.width = "60%";
            }
        }
    }

    return (
        <div
            id={props.id}
            className={classes.card}
            draggable={props.draggable}
            onDragStart={dragStart}
            onDrop={swap}
            value={props.value}
        >
            {props.children}
        </div>
    )
}

export default Card;
