import styled from "@emotion/styled";
import { Typography } from "@material-ui/core"

// font size constants for text used in lesson and exercise content display component
export const GENERAL_FONT_SIZE_M = '14px'
export const GENERAL_FONT_SIZE_T = '16px'
export const GENERAL_FONT_SIZE = '18px'

// font sizes for the headers used in lesson component 
export const HEADER_FONT_SIZE = '24px'
export const SUBHEADER_FONT_SIZE = '22px'

// font size for the headers used in exercise component 
export const EXERCISE_HEADER_FONT_SIZE_M = "18px"
export const EXERCISE_HEADER_FONT_SIZE_T = "20px"
export const EXERCISE_HEADER_FONT_SIZE = "22px"

export const GeneralText = styled(Typography)`
    font-size: ${GENERAL_FONT_SIZE_M};
    @media (min-width: 600px) and (max-width:1024px) {
        font-size: ${GENERAL_FONT_SIZE_T};  
    }
    @media (min-width: 1024px){
        font-size: ${GENERAL_FONT_SIZE};  
    }
`;

export const ExerciseHeader = styled(Typography)`
    font-size: ${EXERCISE_HEADER_FONT_SIZE_M};
    @media (min-width: 600px) and (max-width:1024px) {
        font-size: ${EXERCISE_HEADER_FONT_SIZE_T};  
    }
    @media (min-width: 1024px){
        font-size: ${EXERCISE_HEADER_FONT_SIZE};  
    }
`;
