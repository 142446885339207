/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectionBoard from './SelectionBoard';
import DiagramLayout from './DiagramLayout';

// Extremely temporary solution
// Don't commit this file into source control
// This will be the db format later on
import { Paper} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      //color: theme.palette.text.secondary,
      height: "850px"
  },
  question: {
      padding: theme.spacing(2),
      //color: theme.palette.text.secondary
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));


const DragDrop = ({onSubmission, index, content}) => {

  // Component styling and screen resolution
  const classes = useStyles();

  // If styling requires screen width and height use these
  // const { screenWidth, screenHeight } = useWindowDimensions();
  // const [solveTime, setSolveTime] = useState(0);

  const [answers, setAnswers] = useState([])
//   const [connections, setConnections] = useState([]);

  const updateAnswer = (value, index) => {
    let updated = answers.slice();
    updated[index] = value;

    setAnswers(updated);
  }

  useEffect(() => {
    if (answers.length > 0){
      onSubmission(answers, index);
    }
  },[answers])

//   const updateConnections = (connectors, index) => {
//     let updated = connections.slice();
//     updated[index] = connectors;
//     setConnections(updated);
//   }

  // Try to use Material UI (currently v4, upgrading to v5 when have the time) 
  // instead of primitive HTML tags
  // https://v4.mui.com

  return (
    <div className={classes.root}>
        <Paper style={{ padding:"20px" }}>
          <SelectionBoard id="board-1" cards={content.cards}/>
          <DiagramLayout
            root={content.root}
            maxDepth={content.maxDepth}
            updateAnswer={updateAnswer}
          />
        </Paper>             
    </div>
  );
};
  

export default DragDrop;
