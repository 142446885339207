import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  componentsBlock: {
    border: 'solid 5px #FFC84B',
    borderRadius: '3%',
  },
  textFontSize: {
    fontSize: '16px',
  }
}));

const Annotation = ({ character, goals }) => {
  const classes = useStyles();
  const renderGoals = () => {
    const result = [];
    for (let i = 1; i < goals.length; i += 2) {
      if (goals[i].annotation !== 'Unknown')
        result.push(
          <TableRow>
            <TableCell className={classes.textFontSize} component="th" scope="row">
              {goals[i].annotation}
            </TableCell>
            <TableCell>
              <img src={goals[i].img} alt="Character" width={50} height={50} />
            </TableCell>
            {goals[i + 1] && goals[i + 1].annotation !== 'Unknown' && (
              <>
                <TableCell className={classes.textFontSize} component="th" scope="row">
                  {goals[i + 1].annotation}
                </TableCell>
                <TableCell>
                  <img
                    src={goals[i + 1].img}
                    alt="Character"
                    width={50}
                    height={50}
                  />
                </TableCell>
              </>
            )}
          </TableRow>
        );
    }
    return result;
  };

  return (
    <div className={classes.componentsBlock}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {/* First row: Character and First goal */}
            <TableRow>
              <TableCell className={classes.textFontSize} component="th" scope="row" style={{ paddingRight: '5px' }}>
                {`${character.name} (Character)`}
              </TableCell>
              <TableCell style={{ paddingRight: '10px', paddingLeft: '5px' }}>
                <img
                  src={character.right}
                  alt="Character"
                  width={50}
                  height={50}
                />
              </TableCell>
              <TableCell className={classes.textFontSize} component="th" scope="row" style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                {goals[0].annotation}
              </TableCell>
              <TableCell style={{ paddingLeft: '10px' }}>
                <img
                  src={goals[0].img}
                  alt="Character"
                  width={50}
                  height={50}
                />
              </TableCell>
            </TableRow>
            {/* Remaining goals */}
            {renderGoals()}
          </TableBody >
        </Table >
      </TableContainer >
    </div >
  );
};

Annotation.propTypes = {
  character: PropTypes.shape({
    name: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired
  }).isRequired,
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      annotation: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Annotation;
