/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';

import { signIn, setExercises } from '../actions';
import { storeUserCookies } from './utils/utils';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        CSEducation
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const renderField = (field) => {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id={field.type}
        error={field.meta.touched && field.meta.error}
        helperText={field.meta.touched ? field.meta.error : ''}
        label={field.label}
        name={field.type}
        type={field.type}
        {...field.input}
      />
    </>
  );
};

const SignIn = (props) => {
  const classes = useStyles();
  const { handleSubmit, authed, history } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (authed) history.push('/');
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const request1 = await axios.post(
        `${process.env.REACT_APP_LMS_API}/jsoncheckUserlogin`,
        values
      );

      storeUserCookies({
        user: request1.data.username,
        token: request1.data.token,
        expiry: new Date(request1.data.expiry_date)
      });

      const request2 = await axios.post(
        `${process.env.REACT_APP_LMS_API}/validateUser`,
        null,
        {
          headers: { Authorization: `Token ${request1.data.token}` }
        }
      );

      props.signIn();
      props.setExercises('maze', request2.data.exercise_list);
      history.push('/');
    } catch (err) {
      if (err.response && err.response.status === 400)
        setError('Invalid username or password');
      else setError('Failed to fetch. Please check your Internet connection');
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit.bind(this))}
        >
          <Field
            name="username"
            type="username"
            label="Username"
            component={renderField}
          />
          <Field
            name="password"
            type="password"
            label="Password"
            component={renderField}
          />
          <Grid container>
            <Grid item xs>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Sign In
              </Button>
            </Grid>
            {loading && (
              <Grid item>
                <CircularProgress
                  className={classes.submit}
                  style={{ marginLeft: 10 }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs>
              <Link href="https://lms.aubot.com/forgotpassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://lms.aubot.com" variant="body2">
                Don&apos;t have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.username) errors.username = 'Please enter your username';
  if (!values.password) errors.password = 'Please enter your password';
  return errors;
};

SignIn.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  authed: PropTypes.bool.isRequired,
  setExercises: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({ authed: state.auth.isSignedIn });

export default reduxForm({
  validate,
  form: 'SignInForm'
})(connect(mapStateToProps, { signIn, setExercises })(SignIn));
