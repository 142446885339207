import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Box, Button, Tab, Tabs } from '@material-ui/core';
import Card from './Card'

const useStyles = makeStyles((theme) => ({
    board: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
	  border: "1px solid #2196f3",
      width: "90%",
      height:"60px",
      background: "#FFFFFF",
      padding: "5px",
      borderRadius:"8px",
      margin: "5px 5%"
    }
  }));

const SelectionBoard = (props) => {
    const classes = useStyles();

    const drop = (event) => {
        event.preventDefault();
        const cardId = event.dataTransfer.getData('card_id');

        const card = document.getElementById(cardId);
        if (card == null) {
            return;
        }
        card.style.width = "100px";
        
        event.target.appendChild(card);
    }

    const dragOver = (event) => {
        event.preventDefault();
    }

    return (
        <Paper
        id={props.id}
        onDrop={drop}
        onDragOver={dragOver}
        className={classes.board}>
            { props.children }
            {
                props.cards.map((element) => {
                    return <Card
                    key={`card-${element.value}`}
                    id={`card-${element.value}`}
                    draggable="true"
                    value={element.value}
                    >
                        <Typography>
                            {element.value}
                        </Typography>
                    </Card>
                })
            }
        </Paper>
    )
}


export default SelectionBoard;