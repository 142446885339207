import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

// Background images
import modal_bg_0 from '../../img/modal_bg_0.jpg';
import modal_bg_1 from '../../img/modal_bg_1.jpg';
import modal_bg_2 from '../../img/modal_bg_2.jpg';
import modal_bg_3 from '../../img/modal_bg_3.jpg';
import modal_bg_4 from '../../img/modal_bg_4.jpg';
import modal_bg_5 from '../../img/modal_bg_5.jpg';
import modal_bg_6 from '../../img/modal_bg_6.jpg';
import modal_bg_7 from '../../img/modal_bg_7.jpg';
import modal_bg_8 from '../../img/modal_bg_8.jpg';
import modal_bg_9 from '../../img/modal_bg_9.jpg';
import modal_bg_10 from '../../img/modal_bg_10.jpg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: '#cccccc',
    backgroundBlendMode: 'screen',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${getBackgroundImage()})`
  }
}));

const backgroundImages = [
  modal_bg_0, modal_bg_1, modal_bg_2, modal_bg_3, modal_bg_4,
  modal_bg_5, modal_bg_6, modal_bg_7, modal_bg_8, modal_bg_9, modal_bg_10
]

const getBackgroundImage = () => {
  return backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
}

const CongratsModal = ({
  open,
  setOpen,
  lineNum,
  nextExercise,
  history,
  solveTime,
  clockInterval,
  successGIF,
  restartClock,
  previouslyCompleted
}) => {
  const classes = useStyles();

  const onClickNextExercise = () => {
    clearInterval(clockInterval); // stop the clock
    setOpen(false);
    // Route to next exercise if exists, otherwise route to dashboard
    const path = nextExercise
      ? `/${nextExercise.type}/${nextExercise.id}`
      : '/';
    history.push(path);
  };

  const onClickFinish = () => {
    clearInterval(clockInterval); // stop the clock
    setOpen(false);
    history.push('/');
  }

  const time_formatted = String(parseInt(solveTime / 60, 10)).padStart(2, '0') + ':' +
    String(solveTime - parseInt(solveTime / 60, 10) * 60).padStart(2, '0');

  const renderReplayAndFinish = () => {
    if (previouslyCompleted === false) {
      return (
        <React.Fragment>
          <Grid item xs={3}>
            <Button variant="contained" onClick={() => {
              restartClock();
              setOpen(false);
            }}>
              Replay
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onClickFinish}
            >
              Finish
            </Button>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid item xs={6}>
          <Button
            variant="contained"
            onClick={onClickFinish}
          >
            Finish
          </Button>
        </Grid>
      );
    }
  }

  if (nextExercise !== null) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">
                Congratulations! You completed this exercise.
              </h2>
              <Typography id="transition-modal-description" align="center">
                {`You solved the exercise in ${time_formatted} using ${lineNum} block${lineNum > 1 ? 's' : ''
                  } of code`}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img
                  src={successGIF}
                  alt="Success"
                  width={100}
                  height={100}
                />
              </Box>
              <Grid container>
                {renderReplayAndFinish()}
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickNextExercise}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Typography id="transition-modal-title" align="center" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                Congratulations! You completed this exercise.
              </Typography>
              <Typography id="transition-modal-description" align="center">
                {`You solved the exercise in ${time_formatted} using ${lineNum} block${lineNum > 1 ? 's' : ''
                  } of code`}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img
                  src={successGIF}
                  alt="Success"
                  width={100}
                  height={100}
                />
              </Box>
              <Grid container>
                <Grid item xs={3}>
                  {
                    (previouslyCompleted === false) && (
                      <Button variant="contained" onClick={() => {
                        restartClock();
                        setOpen(false);
                      }}>
                        Replay
                      </Button>
                    )
                  }
                </Grid>
                <Grid item xs={6}>
                  <Typography align="center">
                    You've completed all your homework. Ask your teacher for more.
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickFinish}
                  >
                    Finish
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
};

CongratsModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  lineNum: PropTypes.number.isRequired,
  nextExercise: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  clockInterval: PropTypes.number
};

CongratsModal.defaultProps = {
  open: false,
  nextExercise: undefined,
  clockInterval: undefined
};

export default CongratsModal;
