import { Typography } from '@material-ui/core';
import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { GeneralText } from '../../common/CommonStyles';

const ListExplanation = ({ content }) => {
  let key = 0
  return (
    <ul>
      {content.map((line) => (
        <li>
          <GeneralText key={key++} align="left">
            <ReactMarkdown>{line}</ReactMarkdown>
          </GeneralText>
        </li>
      ))}
    </ul>
  );

}

export default ListExplanation