import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import ConnectorDotStart from './connectors/ConnectorDotStart';
// import ConnectorDot from './connectors/ConnectorDot';
// import ConnectorDotEnd from './connectors/ConnectorDotEnd';
import { Typography, Paper, Grid, Box, Button, Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    board: {
        justifyContent: "center",
        width: '80px',
        height: "30px",
        background: "#F0F8FF",
        border: "1px solid #86C5D8",
        position: "relative",
        padding: "5px",
        borderRadius: "8px",
        margin: "2px 2%",
        zIndex: "100",
        overflow: "hidden"
    }
}));

const Board = (props) => {
    const classes = useStyles();

    const drop = (event) => {
        event.preventDefault();

        let fromBoard = document.getElementById(event.dataTransfer.getData("parent_board"));
        let toBoard = document.getElementById(props.id);
        let index = toBoard.id.substring(6);

        if (fromBoard != toBoard) {
            const cardId = event.dataTransfer.getData('card_id');
            const cardValue = event.dataTransfer.getData('value');
            const card = document.getElementById(cardId);

            if (card == null) {
                return;
            } else if (toBoard.children.length == 0) {
                // add card to empty answer board
                card.style.width = "60%";
                event.target.appendChild(card);
                props.updateAnswer(cardValue, index);
            } else if (fromBoard.id == "selection-board") {
                // swaps cards between selection board and answer board
                const existingCard = toBoard.lastChild;
                
                let card1 = fromBoard.removeChild(card);
                let card2 = toBoard.removeChild(existingCard);

                card1.style.width = "60%";
                card2.style.width = "max-content";
                fromBoard.appendChild(card2);
                toBoard.appendChild(card1);
                props.updateAnswer(cardValue, index);
            } else {
                // swaps cards between answer boards
                const existingCard = toBoard.lastChild;
                
                let card1 = fromBoard.removeChild(card);
                let card2 = toBoard.removeChild(existingCard);

                card1.style.width = "60%";
                card2.style.width = "60%";
                fromBoard.appendChild(card2);
                toBoard.appendChild(card1);
                props.swapAnswers(index, fromBoard.id.substring(6));
            }
        }
    }

    const dragOver = (event) => {
        event.preventDefault();
    }

    return (
        <Paper
            id={props.id}
            onDrop={drop}
            onDragOver={dragOver}
            className={classes.board}>
            {props.children}
        </Paper>
    )
}

export default Board;
