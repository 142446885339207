/* eslint-disable */

import Blockly from 'blockly';
import BlocklyConfig from '../blockly/Config';
import randomstring from 'randomstring';

Blockly.Blocks['move_east'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('E')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/E_90_00_sozunf.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_west'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('W')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/W_270_00_l6kdhp.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_north'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('N')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/N_0_00_tkxpmz.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_south'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('S')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/S_180_00_nfs8c3.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_forward'] = {
  init: function () {
    this.appendDummyInput().appendField('move forward');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_backward'] = {
  init: function () {
    this.appendDummyInput().appendField('move backward');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['turn_left'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('turn left')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1597709281/utils/iconfinder_rotate-left_1608422_lxkwue.png',
          15,
          15,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['turn_right'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('turn right')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1597709281/utils/iconfinder_rotate-right_1608421_npqrli.png',
          15,
          15,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['move_east'] = function (block) {
  var code = 'await moveEast();\n';
  return code;
};

Blockly.JavaScript['move_west'] = function (block) {
  var code = 'await moveWest();\n';
  return code;
};

Blockly.JavaScript['move_north'] = function (block) {
  var code = 'await moveNorth();\n';
  return code;
};

Blockly.JavaScript['move_south'] = function (block) {
  var code = 'await moveSouth();\n';
  return code;
};

Blockly.JavaScript['move_forward'] = function (block) {
  var code = 'await moveForward();\n';
  return code;
};

Blockly.JavaScript['move_backward'] = function (block) {
  var code = 'await moveBackward();\n';
  return code;
};

Blockly.JavaScript['turn_left'] = function (block) {
  var code = 'await turnLeft();\n';
  return code;
};

Blockly.JavaScript['turn_right'] = function (block) {
  var code = 'await turnRight();\n';
  return code;
};

const createCollecGoalBlock = (goalImg, goalType, goalAction) => {
  if (!goalAction) {
    Blockly.Blocks['collect_' + goalType] = {
      init: function () {
        this.appendDummyInput()
          .appendField('get')
          .appendField(
            new Blockly.FieldImage(goalImg, 15, 15, {
              alt: '*',
              flipRtl: 'FALSE'
            })
          );
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(210);
        this.setTooltip('');
        this.setHelpUrl('');
      }
    };
  } else {
    Blockly.Blocks['collect_' + goalType] = {
      init: function () {
        this.appendDummyInput().appendField(goalAction);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(210);
        this.setTooltip('');
        this.setHelpUrl('');
      }
    };
  }

  Blockly.JavaScript['collect_' + goalType] = function (block) {
    var code = "await collectGoal('" + goalType + "');\n";
    return code;
  };
};

const createIfAtGoalBlock = (goals) => {
  const optionsIfElse = [];
  const optionsWhile = [];
  const optionsRepeatUntil = [];
  goals.forEach((goal) => {
    if (goal.name !== 'unknown') {
      optionsIfElse.push([goal.annotation, goal.name]);
      optionsWhile.push([goal.annotation, goal.name]);
      optionsRepeatUntil.push([
        { src: goal.img, width: 30, height: 30, alt: goal.annotation },
        goal.name
      ]);
    }
  });

  Blockly.Blocks['if_at_goal'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('if at')
        .appendField(new Blockly.FieldDropdown(optionsIfElse), 'goal');
      this.appendStatementInput('at_goal_statements')
        .setCheck(null)
        .appendField('do');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(180);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['if_at_goal'] = function (block) {
    var dropdown_goal = block.getFieldValue('goal');
    var at_goal_statements = Blockly.JavaScript.statementToCode(
      block,
      'at_goal_statements'
    );

    var code =
      "if (atGoal('" +
      dropdown_goal +
      "') !== -1) {\n" +
      at_goal_statements +
      '}\n';
    return code;
  };

  Blockly.Blocks['if_else_at_goal'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('if at')
        .appendField(new Blockly.FieldDropdown(optionsIfElse), 'goal');
      this.appendStatementInput('at_goal_statements')
        .setCheck(null)
        .appendField('do');
      this.appendStatementInput('else_statements')
        .setCheck(null)
        .appendField('else');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(180);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['if_else_at_goal'] = function (block) {
    var dropdown_goal = block.getFieldValue('goal');
    var at_goal_statements = Blockly.JavaScript.statementToCode(
      block,
      'at_goal_statements'
    );
    var else_statements = Blockly.JavaScript.statementToCode(
      block,
      'else_statements'
    );

    var code =
      "if (atGoal('" +
      dropdown_goal +
      "') !== -1) {\n" +
      at_goal_statements +
      '} else {\n' +
      else_statements +
      '}\n';
    return code;
  };

  Blockly.Blocks['while_at_goal'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('while there is')
        .appendField(new Blockly.FieldDropdown(optionsWhile), 'goal');
      this.appendStatementInput('at_goal_statements')
        .setCheck(null)
        .appendField('do');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(300);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['while_at_goal'] = function (block) {
    var dropdown_goal = block.getFieldValue('goal');
    var at_goal_statements = Blockly.JavaScript.statementToCode(
      block,
      'at_goal_statements'
    );
    var randomid = randomstring.generate({ length: 5, charset: 'alphanumeric' })
    var curr_iter_var_name = 'CURR_ITER_' + randomid;
    var code =
      '// CURR_ITER & MAX_ITER prevent your\n// while loop from running forever\n' +
      'var ' + curr_iter_var_name + ' = 0;\n' +
      "while (atGoal('" +
      dropdown_goal +
      "') !== -1 && " + curr_iter_var_name + " <= MAX_ITER) {\n" +
      at_goal_statements +
      '  ' + curr_iter_var_name + "++;\nlogGameState('" + randomid + "');\n" +
      '}\n';
    return code;
  };

  Blockly.Blocks['repeat_until_goal'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('repeat until')
        .appendField(new Blockly.FieldDropdown(optionsRepeatUntil), 'goals');
      this.appendStatementInput('before_goal').setCheck(null).appendField('do');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(300);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['repeat_until_goal'] = function (block) {
    var dropdown_goals = block.getFieldValue('goals');
    var statements_before_goal = Blockly.JavaScript.statementToCode(
      block,
      'before_goal'
    );
    var randomid = randomstring.generate({ length: 5, charset: 'alphanumeric' })
    var curr_iter_var_name = 'CURR_ITER_' + randomid;
    var code =
      '// CURR_ITER & MAX_ITER prevent your\n// while loop from running forever\n' +
      'var ' + curr_iter_var_name + ' = 0;\n' +
      "while (atGoal('" +
      dropdown_goals +
      "') === -1 && " + curr_iter_var_name + " <= MAX_ITER) {\n" +
      statements_before_goal +
      '  ' + curr_iter_var_name + "++;\nlogGameState('" + randomid + "');\n" +
      '}\n';
    return code;
  };
};

Blockly.Blocks['repeat_easy'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('repeat')
      .appendField(
        new Blockly.FieldDropdown([
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['10', '10']
        ]),
        'times'
      );
    this.appendStatementInput('repeat_statements')
      .setCheck(null)
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1598312077/utils/refresh-512_dougrk.png',
          40,
          40,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(300);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['repeat_easy'] = function (block) {
  var dropdown_times = block.getFieldValue('times');
  var statements = Blockly.JavaScript.statementToCode(
    block,
    'repeat_statements'
  );
  var iter_var =
    'i_' + randomstring.generate({ length: 5, charset: 'alphanumeric' });

  var code =
    'for (var ' +
    iter_var +
    ' = 1; ' +
    iter_var +
    ' <= ' +
    dropdown_times +
    '; ' +
    iter_var +
    '++) {\n' +
    statements +
    '}\n';
  return code;
};

Blockly.Blocks['repeat_hard'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('repeat')
      .appendField(new Blockly.FieldNumber(5, 2), 'times')
      .appendField('times');
    this.appendStatementInput('repeat_statements')
      .setCheck(null)
      .appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(300);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['repeat_hard'] = function (block) {
  var number_times = block.getFieldValue('times');
  var statements = Blockly.JavaScript.statementToCode(
    block,
    'repeat_statements'
  );
  var iter_var =
    'i_' + randomstring.generate({ length: 5, charset: 'alphanumeric' });

  var code =
    'for (var ' +
    iter_var +
    ' = 1; ' +
    iter_var +
    ' <= ' +
    number_times +
    '; ' +
    iter_var +
    '++) {\n' +
    statements +
    '}\n';
  return code;
};

Blockly.Blocks['if_path'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('if path')
      .appendField(
        new Blockly.FieldDropdown([
          ['ahead', 'ahead'],
          ['to the right', 'right'],
          ['to the left', 'left']
        ]),
        'direction'
      );
    this.appendStatementInput('isPath').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['if_path'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction');
  var statements_ispath = Blockly.JavaScript.statementToCode(block, 'isPath');
  var code =
    "if (isPath('" + dropdown_direction + "')) {\n" + statements_ispath + '}\n';
  return code;
};

Blockly.Blocks['while_path'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('while path')
      .appendField(
        new Blockly.FieldDropdown([
          ['ahead', 'ahead'],
          ['to the right', 'right'],
          ['to the left', 'left']
        ]),
        'direction'
      );
    this.appendStatementInput('isPath').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(300);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['while_path'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction');
  var statements_ispath = Blockly.JavaScript.statementToCode(block, 'isPath');
  var randomid = randomstring.generate({ length: 5, charset: 'alphanumeric' });
  var curr_iter_var_name = 'CURR_ITER_' + randomid;
  var code =
    '// CURR_ITER & MAX_ITER prevent your\n// while loop from running forever\n' +
    'var ' + curr_iter_var_name + ' = 0;\n' +
    "while (isPath('" +
    dropdown_direction +
    "') && " + curr_iter_var_name + " <= MAX_ITER) {\n" +
    statements_ispath +
    '  ' + curr_iter_var_name + "++;\nlogGameState('" + randomid + "');\n" +
    '}\n';
  return code;
};

Blockly.Blocks['function'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          ["function1", "function1"],
          ["function2", "function2"],
          ["function3", "function3"]
        ]),
        "NAME");
    this.appendStatementInput('statements').setCheck(null);
    this.setColour(345);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['function'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  var statements_statements = Blockly.JavaScript.statementToCode(
    block,
    'statements'
  );

  if (text_name.length === 0) text_name = 'unnamed';
  if (!Number.isNaN(Number(text_name))) text_name = '_' + text_name;
  if (BlocklyConfig.reservedWords.includes(text_name)) text_name += '2';
  text_name = text_name.replace(BlocklyConfig.specialCharacters, '_');
  var code =
    'async function ' + text_name + '() {\nfunctionCounter += 1;\n' + statements_statements + '}\n';
  return code;
};

Blockly.Blocks['function_call'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          ["function1", "function1"],
          ["function2", "function2"],
          ["function3", "function3"]
        ]),
        "NAME");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(345);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['function_call'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  if (text_name.length === 0) text_name = 'unnamed';
  if (!Number.isNaN(Number(text_name))) text_name = '_' + text_name;
  if (BlocklyConfig.reservedWords.includes(text_name)) text_name += '2';
  text_name = text_name.replace(BlocklyConfig.specialCharacters, '_');
  var code = 'await ' + text_name + '();\n';
  return code;
};

Blockly.Blocks['arithmetic'] = {
  init: function () {
    this.appendValueInput('LeftOperand').setCheck('Number');
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ['+', '+'],
        ['-', '-'],
        ['×', '*'],
        ['÷', '/']
      ]),
      'Operations'
    );
    this.appendValueInput('RightOperand').setCheck('Number');
    this.setOutput(true, 'Number');
    this.setColour(30);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['arithmetic'] = function (block) {
  var value_leftoperand = Blockly.JavaScript.valueToCode(
    block,
    'LeftOperand',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var dropdown_operations = block.getFieldValue('Operations');
  var value_rightoperand = Blockly.JavaScript.valueToCode(
    block,
    'RightOperand',
    Blockly.JavaScript.ORDER_ATOMIC
  );

  if (value_rightoperand === '0' && dropdown_operations === '/')
    alert('Dividing a number by zero will result in infinity!');
  var code = value_leftoperand + dropdown_operations + value_rightoperand;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.Blocks['numeric_input'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldNumber(0, -Infinity, Infinity, 1),
      'value'
    );
    this.setOutput(true, 'Number');
    this.setColour(30);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['numeric_input'] = function (block) {
  var number_value = block.getFieldValue('value');
  var code = number_value;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.Blocks['break'] = {
  init: function () {
    this.appendDummyInput().appendField('break');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['break'] = function (block) {
  var code = 'break;\n';
  return code;
};

const createVariableBlocks = (names) => {
  Blockly.Blocks['set_variable'] = {
    init: function () {
      this.appendValueInput('NAME')
        .setCheck(null)
        .appendField('set')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField('to');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(30);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['set_variable'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_name = Blockly.JavaScript.valueToCode(
      block,
      'NAME',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = dropdown_name + ' = ' + value_name + ';\n';
    return code;
  };

  Blockly.Blocks['if_oddity'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('if ')
        .appendField(new Blockly.FieldDropdown(names), 'var')
        .appendField(new Blockly.FieldDropdown([
          ["is_even", "IS_EVEN"],
          ["is_odd", "IS_ODD"]
        ]),
          "is_even_dropdown"
        );
      this.appendStatementInput("body")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };

  Blockly.JavaScript['if_oddity'] = function (block) {
    var dropdown_var = block.getFieldValue('var');
    var dropdown_iseven = block.getFieldValue('is_even_dropdown');
    // TODO: Assemble JavaScript into code variable.
    var oddity_check = (dropdown_iseven === 'IS_EVEN') ? 0 : 1;
    var statements_body = Blockly.JavaScript.statementToCode(block, 'body');
    var code = 'if (' + dropdown_var + ' % 2 == ' + dropdown_iseven + ') {\n' + statements_body + '}\n';
    return code;
  };

  Blockly.Blocks['if_else_oddity'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('if ')
        .appendField(new Blockly.FieldDropdown(names), 'var')
        .appendField(new Blockly.FieldDropdown([
          ["is_even", "IS_EVEN"],
          ["is_odd", "IS_ODD"]
        ]),
          "is_even_dropdown"
        );
      this.appendStatementInput("true_branch")
        .setCheck(null);
      this.appendDummyInput()
        .appendField('else ');
      this.appendStatementInput("false_branch")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };

  Blockly.JavaScript['if_else_oddity'] = function (block) {
    var dropdown_var = block.getFieldValue('var');
    var dropdown_iseven = block.getFieldValue('is_even_dropdown');
    // TODO: Assemble JavaScript into code variable.
    var oddity_check = (dropdown_iseven === 'IS_EVEN') ? 0 : 1;
    var statements_true_branch = Blockly.JavaScript.statementToCode(block, 'true_branch');
    var statements_false_branch = Blockly.JavaScript.statementToCode(block, 'false_branch');
    var code = 'if (' + dropdown_var + ' % 2 == ' + dropdown_iseven + ') {\n'
      + statements_true_branch + '} else {\n' + statements_false_branch + '}\n';
    return code;
  };

  Blockly.Blocks['get_variable'] = {
    init: function () {
      this.appendDummyInput().appendField(
        new Blockly.FieldDropdown(names),
        'NAME'
      );
      this.setOutput(true, null); // Hmm
      this.setColour(30);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['get_variable'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name;
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.Blocks['loop_var'] = {
    init: function () {
      this.appendValueInput('from')
        .setCheck('Number')
        .appendField('for')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField('from');
      this.appendDummyInput().appendField('to');
      this.appendValueInput('to').setCheck('Number');
      this.appendDummyInput().appendField('count by');
      this.appendValueInput('count_by').setCheck('Number');
      this.appendStatementInput('in_loop').setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(30);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['loop_var'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_from = Blockly.JavaScript.valueToCode(
      block,
      'from',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_to = Blockly.JavaScript.valueToCode(
      block,
      'to',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_count_by = Blockly.JavaScript.valueToCode(
      block,
      'count_by',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var statements_in_loop = Blockly.JavaScript.statementToCode(
      block,
      'in_loop'
    );
    var randomid = randomstring.generate({ length: 5, charset: 'alphanumeric' });
    var curr_iter_var_name = 'CURR_ITER_' + randomid;
    var code =
      '// CURR_ITER & MAX_ITER prevent your while loop from running forever\n' +
      'var ' + curr_iter_var_name + ' = 0;\n' +
      'checkForLoopVariables(' + value_from + ', ' + value_to + ', ' + value_count_by + ')\n' +
      'for (' +
      dropdown_name +
      ' = ' +
      value_from +
      '; ' +
      dropdown_name +
      ' < ' +
      value_to +
      '&& ' + curr_iter_var_name + ' <= MAX_ITER; ' +
      dropdown_name +
      '+= ' +
      value_count_by +
      ') {\n' +
      statements_in_loop +
      '  ' + curr_iter_var_name + "++;\n  logGameState('" + randomid + "');\n" +
      '}\n';
    return code;
  };

  Blockly.Blocks['while_var'] = {
    init: function () {
      this.appendValueInput('rhs')
        .setCheck('Number')
        .appendField('while')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField(
          new Blockly.FieldDropdown([
            ['<', '<'],
            ['>', '>'],
            ['==', '=='],
            ['<=', '<='],
            ['>=', '>='],
          ]),
          'comparison'
        );
      this.appendStatementInput('while_var_body').setCheck(null).appendField('do');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(300);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['while_var'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var operator = block.getFieldValue('comparison')
    var value_compare = Blockly.JavaScript.valueToCode(
      block,
      'rhs',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var statements_in_loop = Blockly.JavaScript.statementToCode(
      block,
      'while_var_body'
    );
    // TODO: Assemble JavaScript into code variable.
    var code =
      '// CURR_ITER & MAX_ITER prevent your\n// while loop from running forever\n' +
      'CURR_ITER = 0;\n' +
      'while (' +
      dropdown_name + ' ' + operator + ' ' + value_compare +
      ' && CURR_ITER <= MAX_ITER) ' +
      '{\n ' +
      statements_in_loop +
      '  CURR_ITER++;\n' +
      '}\n';
    return code;
  }

  Blockly.Blocks['function_arg'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('  ')
        .appendField(new Blockly.FieldTextInput('function name'), 'NAME')
        .appendField(new Blockly.FieldDropdown(names), 'arg');
      this.appendStatementInput('statements').setCheck(null);
      this.setColour(345);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['function_arg'] = function (block) {
    var text_name = block.getFieldValue('NAME');
    var statements_statements = Blockly.JavaScript.statementToCode(
      block,
      'statements'
    );

    if (text_name.length === 0) text_name = 'unnamed';
    if (!Number.isNaN(Number(text_name))) text_name = '_' + text_name;
    if (BlocklyConfig.reservedWords.includes(text_name)) text_name += '2';
    text_name = text_name.replace(BlocklyConfig.specialCharacters, '_');
    var code =
      'async function ' + text_name + '() {\n' + statements_statements + '\n}\n';
    return code;
  };

  Blockly.Blocks['function_call_arg'] = {
    init: function () {
      this.appendValueInput('RightOperand').setCheck('Number');
      this.appendDummyInput().appendField(
        new Blockly.FieldTextInput('function name'),
        'NAME'
      );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(345);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['function_call_arg'] = function (block) {
    var text_name = block.getFieldValue('NAME');
    if (text_name.length === 0) text_name = 'unnamed';
    if (!Number.isNaN(Number(text_name))) text_name = '_' + text_name;
    if (BlocklyConfig.reservedWords.includes(text_name)) text_name += '2';
    text_name = text_name.replace(BlocklyConfig.specialCharacters, '_');
    var code = 'await ' + text_name + '();\n';
    return code;
  };

  Blockly.Blocks['break_if'] = {
    init: function () {
      this.appendValueInput('rhs')
        .setCheck('Number')
        .appendField('break if')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField(
          new Blockly.FieldDropdown([
            ['<', '<'],
            ['>', '>'],
            ['==', '=='],
            ['<=', '<='],
            ['>=', '>='],
          ]),
          'comparison'
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['break_if'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var operator = block.getFieldValue('comparison')
    var value_compare = Blockly.JavaScript.valueToCode(
      block,
      'rhs',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = 'if (' + dropdown_name + ' ' + operator + ' ' + value_compare + ') {break;}\n';
    return code;
  };
};

Blockly.Blocks['repeat_var'] = {
  init: function () {
    this.appendDummyInput().appendField('repeat');
    this.appendValueInput('NAME').setCheck('Number');
    this.appendDummyInput().appendField('times');
    this.appendStatementInput('inloop').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['repeat_var'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var statements_inloop = Blockly.JavaScript.statementToCode(block, 'inloop');
  var iter_var =
    'i_' + randomstring.generate({ length: 5, charset: 'alphanumeric' });
  var code =
    '// CURR_ITER & MAX_ITER prevent your\n// while loop from running forever\n' +
    'CURR_ITER = 0;\n' +
    'for (var ' +
    iter_var +
    ' = 1; ' +
    iter_var +
    ' <= ' +
    value_name +
    '&& CURR_ITER <= MAX_ITER; ' +
    iter_var +
    '++) {\n' +
    statements_inloop +
    '  CURR_ITER++;\n' +
    '}\n';
  return code;
};

const createSwitchGoalBlock = (goals) => {
  const goalTypes = [];
  goals.forEach((goal) => {
    if (goal.name !== 'unknown') {
      goalTypes.push([goal.name, goal.img]);
    }
  });

  Blockly.Blocks['switch_goal'] = {
    init: function () {
      this.appendDummyInput().appendField('switch (goal)');
      goalTypes.forEach((goalType) => {
        this.appendStatementInput(goalType[0])
          .setCheck(null)
          .appendField('case')
          .appendField(
            new Blockly.FieldImage(goalType[1], 30, 30, {
              alt: '*',
              flipRtl: 'FALSE'
            })
          );
      });
      this.appendStatementInput('default')
        .setCheck(null)
        .appendField('default');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(0);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['switch_goal'] = function (block) {
    var statements_case = [];
    goalTypes.forEach((goalType) => {
      statements_case.push([
        goalType[0],
        Blockly.JavaScript.statementToCode(block, goalType[0])
      ]);
    });
    var statements_default = Blockly.JavaScript.statementToCode(
      block,
      'default'
    );

    var code =
      'var goalType = await getGoalTypeAtCurrPos();\n' +
      'switch (goalType) {\n';
    statements_case.forEach((elem) => {
      code += "  case '" + elem[0] + "':\n" + elem[1] + '  break;\n';
    });
    code += '  default:\n' + statements_default + '\n}\n';
    return code;
  };
};

export {
  createCollecGoalBlock,
  createIfAtGoalBlock,
  createVariableBlocks,
  createSwitchGoalBlock
};
