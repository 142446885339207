import * as React from "react";
import { useState } from 'react';
import { Card, Grid, CardActions, CardContent, Button, Typography, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const MultiChoiceButton = (props) => {

    const [colour, setColour] = useState("2px solid transparent");
    const [selected, setSelected] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        card: {
            width: "100%",
            margin: "8px",
            height: "50px",
        },
        cardContent: {
            textAlign: 'left',
            color: theme.palette.text.secondary
        },
        choice: {
            fontSize: "18px",
            margin: "0px 0px"
        },
    }));

    const reset = () => {
        props.next(props.question.n + 1);
        setColour("#ECECEC");
    }

    const changeButton = () => {
        if (!selected) {
            setColour("2px solid #3f51b5");
        } else {
            setColour("2px solid #ECECEC");
        }
        setSelected(!selected);
    }

    const classes = useStyles();

    return (
        <Grid container className={classes.root} >
            <Card className={classes.card} style={{ border: colour, background: "#ECECEC" }}>
                <CardActionArea onClick={changeButton} style={{ height: "100%" }}>
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom className={classes.choice} component="div">
                            {props.answer}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default MultiChoiceButton;
