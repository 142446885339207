import React from 'react'
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-language_tools';
// Supported languages are Python and Java only
const languages = ['python', 'java', 'plain_text'];
languages.forEach((language) => {
    require(`ace-builds/src-noconflict/mode-${language}`);
})

// A light theme that fits in might be github or solarized light
const themes = ['github', 'solarized_light', 'tomorrow_night'];
themes.forEach((theme) => {
    require(`ace-builds/src-noconflict/theme-${theme}`);
});

const ReadonlyDisplay = ({ value, theme, height, mode, minLines, maxLines, placeholderText, showLineNumbers }) => {
    const editorProps = {
        placeholder: placeholderText ? placeholderText : 'None',
        mode: (languages.includes(mode) ? mode : 'plain_text'),
        theme: (themes.includes(theme)) ? theme : 'solarized_light',
        fontSize: 16,
        value: value,
        highlightActiveLine: false,
        width: '100%',
        showPrintMargin: false,

        setOptions: {
            readOnly: true,
            wrap: true,
            autoScrollEditorIntoView: true,
            maxLines: maxLines? maxLines: 40,
            minLines: minLines ? minLines : 2,
            showLineNumbers: showLineNumbers,
        }
    }
    if (height) {
        editorProps.height = height
    }
    return (
        <AceEditor
            {...editorProps}
        />
    )
}

export default ReadonlyDisplay;