/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import Blockly from 'blockly';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Stage, Layer, Image, Group } from 'react-konva';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import axios from 'axios';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Beforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router'
import CustomButton from '../utils/CustomButton';
import { useSpeechSynthesis } from 'react-speech-kit';

import {
  createCollecGoalBlock,
  createIfAtGoalBlock,
  createVariableBlocks,
  createSwitchGoalBlock
} from './BlockDefinition';
import GIF, { getDirectionGif } from '../utils/Gif';
import Annotation from './Annotation';
import Instruction from '../utils/Instruction';
import SpeedSlider from '../utils/SpeedSlider';
import ShowCode from '../utils/ShowCode';
import LoadingPage from '../utils/LoadingPage';
import CongratsModal from '../utils/CongratsModal';
import {
  NumberUrls,
  sleep,
  pathExists,
  getCharGIF,
  getUserCookies
} from '../utils/utils';
import { setCurrentExercise, completeExercise, setAttempt, setSolveTime, setWorkspace } from '../../actions';
import CustomBlocklyWorkspace from '../utils/CustomBlocklyWorkspace';
import useWindowDimensions from '../utils/useWindowDimensions';
import ResetIcon from '../utils/ResetIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '30px',
    paddingBottom: '50px',
    width: 'auto',
    background: 'skyblue',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: 'auto',
    width: 'auto',
  },
  instruction: {
    marginBottom: theme.spacing(3),
    borderRadius: '3%',
  },
  orangeBlock: {
    padding: '20px 15px',
    border: 'solid 5px #FFF',
    borderRadius: '20px',
    background: 'radial-gradient(circle,white,#FFE9D2)',
    boxShadow: '0 0 0 5px #BD8337',
    margin: '0px 10px',
    minWidth: '350px',
    width: 'auto',
    overflowY: 'scroll',
  },
  minWidth: {
    minWidth: '300px',
  },
  containerMinWidth: {
    minWidth: '350px',
  }
}));

const BlocklyMaze = (props) => {
  var {
    match: {
      params: { id: exerciseId }
    },
    exercise, // exercise data
    exerciseIds, // exercise id list used for link to next exercise
    history, // Routing variable,
    // solveTime,
    // setSolveTime,
  } = props;
  const [loading, setLoading] = useState(true);

  // Component styling and screen resolution
  const classes = useStyles();
  const { screenWidth, screenHeight } = useWindowDimensions();
  const [SCALE, setSCALE] = useState(60); // Image scale
  useEffect(() => {
    let mazeLength = (exercise && exercise.maze) ? exercise.maze.length : 8;
    let newScale = 10;
    if (screenWidth > 1200) {
      // Vertical split
      newScale = (screenWidth * 0.8) / (2 * mazeLength);
    } else if (screenWidth > 200) {
      newScale = (screenWidth * 0.8) / (mazeLength);
    } else {
      // In case
      newScale = 10;
    }

    setSCALE(Math.floor(newScale));
  }, [screenWidth, exercise]);

  // Images state
  const [backgroundImg, setBackground] = useState(new window.Image());
  const [goalImgs, setGoalImgs] = useState();
  const [numberImgs, setNumberImgs] = useState(
    NumberUrls.map(() => new window.Image())
  );

  const [blockImg, setBlockImg] = useState(new window.Image());
  const [pathImg, setPathImg] = useState(new window.Image());

  // Game state
  const [charState, setCharState] = useState(); // used to display character gif - left, right, up, down, failure, success
  const [playing, setPlaying] = useState(0); // state 0 for before - 1 for playing - 2 for finished
  const [error, setError] = useState(false); // true or false
  const [direction, setDirection] = useState(); // used to display direction gif - left, right, up, down
  const [program, setProgram] = useState(); // Program string constructed by blockly
  const [codeView, setCodeView] = useState(); // Code displayed to user
  const [workspace, setWorkspace] = useState(); // Blockly workspace
  const [waitTime, setWaitTime] = useState(500); // Time to wait between moves
  const [completed, setCompleted] = useState(false);
  const [nextExercise, setnextExercise] = useState(); // next exercise after exercise completion (used in modal)
  const [solveTime, setSolveTime] = useState(0);
  const [clockInterval, setClockInterval] = useState();
  const [actualBlocks, setActualBlocks] = useState(0);
  const [previouslyCompleted, setPreviouslyCompleted] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const [errMessage, setErrMessage] = useState('');

  // "Hidden" variables to keep track of the program state for loop checking
  var functionCounter = 0;
  var programStates = [];

  // Variable to keep track of avatar's location when speed is set to max
  var avatarX = 0;
  var avatarY = 0;

  const restartClock = () => {
    // Start the clock
    clearInterval(clockInterval);
    const interval = setInterval(() => {
      setSolveTime(time => time + 1);
    }, 1000);
    setClockInterval(interval);
  }

  useEffect(() => {
    props.setSolveTime(solveTime);
  }, [restartClock]);

  // List of goal objects to be modifed as user plays the game
  const goalsReducer = (state, action) => {
    switch (action.type) {
      case 'collect':
        return state.map((goal, i) => {
          const newGoal = _.cloneDeep(goal);
          if (i === action.payload) newGoal.num -= 1;
          return newGoal;
        });
      case 'setType':
        return state.map((goal, i) => {
          const newGoal = _.cloneDeep(goal);
          if (i === action.payload.id) newGoal.type = action.payload.type;
          return newGoal;
        });
      case 'setNum':
        return state.map((goal, i) => {
          const newGoal = _.cloneDeep(goal);
          if (i === action.payload.id) newGoal.num = action.payload.num;
          return newGoal;
        });
      case 'remove':
        return [
          ..._.cloneDeep(state).slice(0, action.payload),
          ..._.cloneDeep(state).slice(action.payload + 1)
        ];
      case 'reset':
        return _.cloneDeep(action.payload);
      default:
        return state;
    }
  };
  const [goalState, dispatch] = useReducer(goalsReducer, null);

  // Local variable for move function to access
  let goals = null;
  const path = [];
  let currDirection = direction;

  // Reference to avatar and goal image on canvas
  const avatarRef = useRef();
  const goalRef = useRef([]);

  const gameStateIsEqual = (state1, state2) => {
    // position are similar to [0, 2]
    const positionEqual =
      state1.position[0] == state2.position[0] &&
      state1.position[1] == state2.position[1];
    const idEqual = (state1.id === state2.id);
    // Small calculation optimization
    if (positionEqual === true && idEqual === true) {
      // goals are similar to [{num: 1, type: 0}]
      return state1.goals
        .map((element, index) => element.num === state2.goals[index].num)
        .every(element => element === true);
    } else {
      // positionEqual !== true so should be false
      return false;
    }
  }

  // Put references to goal images in an array
  const addToGoalRefs = (ref) => {
    if (ref && !goalRef.current.includes(ref)) {
      goalRef.current.push(ref);
    }
  };

  // executed during initialization (for advanced level)
  const randomize = () => {
    goals = exercise.goals.instances.map((goal, i) => {
      const newGoal = _.cloneDeep(goal);

      if (goal.num < 0) {
        // Randomize a goal number - 1 -> 10
        const randomNum = Math.floor(Math.random() * 9) + 1;
        newGoal.num = randomNum;
        dispatch({ type: 'setNum', payload: { id: i, num: randomNum } });
      }

      // Randomize a goal type
      const goalType = exercise.goals.types[goal.type];
      if (goalType.name === 'unknown') {
        const randomType =
          goalType.range[Math.floor(Math.random() * goalType.range.length)];

        // There might be no goal at that position
        if (randomType >= 0) {
          newGoal.type = randomType;
          dispatch({ type: 'setType', payload: { id: i, type: randomType } });
          const newGoalImgs = _.cloneDeep(goalImgs);
          newGoalImgs[i].src = exercise.goals.types[randomType].img;
          setGoalImgs(newGoalImgs);
        } else {
          dispatch({ type: 'setNum', payload: { id: i, num: 0 } });
          newGoal.num = 0;
          newGoal.type = 0;
        }
      }
      return newGoal;
    });
  };

  const resetGame = () => {
    // Reset hidden states
    functionCounter = 0;
    programStates = [];
    goals = _.cloneDeep(exercise.goals.instances);
    setDirection(exercise.character.direction);
    setCharState(exercise.character.direction);
    dispatch({ type: 'reset', payload: exercise.goals.instances });

    const newGoalImgs = _.cloneDeep(goalImgs);
    goals.forEach((goal, i) => {
      newGoalImgs[i].src = exercise.goals.types[goal.type].img;
    });
    setGoalImgs(newGoalImgs);

    // TODO: Maybe teleport to by setting avatarRef.current.attrs.x and avatarRef.current.attrs.y
    avatarRef.current.to({
      x: exercise.character.location[0] * SCALE,
      y: exercise.character.location[1] * SCALE,
      onFinish: () => {
        goalRef.current.forEach((ref) => ref.show());
      }
    });
    setPlaying(0);
  };

  const stopGame = () => {
    // Hacky workaround because setState does not work
    // Teleport character out of grid to crash the eval in the play function
    // Which leads to a failed game state

    // Maybe look at this, seems to suit JS better
    // Can inject code in move and collectGoal JS block
    // https://ckeditor.com/blog/Aborting-a-signal-how-to-cancel-an-asynchronous-task-in-JavaScript/?fbclid=IwAR0B-cJfwaT00CATnaQdk6enZ3KAesvB-4DkJ8AipJHTXAVZU6b1Gm7MXxY
    avatarRef.current.attrs.x = -SCALE;
    avatarRef.current.attrs.y = -SCALE;
  };

  const atGoal = (goalType) => {
    if (waitTime != 0) {
      return goals.findIndex(
        (goal) =>
          avatarRef.current.attrs.x === goal.location[0] * SCALE &&
          avatarRef.current.attrs.y === goal.location[1] * SCALE &&
          (goalType === exercise.goals.types[goal.type].name ||
            exercise.autoCollect) &&
          goal.num > 0
      );
    } else {
      return goals.findIndex(
        (goal) =>
          avatarX === goal.location[0] * SCALE &&
          avatarY === goal.location[1] * SCALE &&
          (goalType === exercise.goals.types[goal.type].name ||
            exercise.autoCollect) &&
          goal.num > 0
      );
    }

  };

  const collectGoal = async (goalType) => {
    const reachedGoalIndex = atGoal(goalType);
    if (reachedGoalIndex !== -1) {
      // console.log(goals);
      goals[reachedGoalIndex].num -= 1;
      dispatch({ type: 'collect', payload: reachedGoalIndex });
      if (waitTime != 0) {
        await sleep((waitTime * 2) / 5);
      }
    }
    // Throw an error when player manually picks up goals when there is no goal
    if (reachedGoalIndex === -1 && !exercise.autoCollect) {
      throw new Error('Collected goal when there is no goal.');
    }
  };

  const checkFunctionCalls = () => {
    if (functionCounter == 0) {
      throw new Error('No function block used.');
    }
  }

  const checkForLoopVariables = (from, to, count) => {
    if (count <= 0) {
      throw new Error('Increment value must be positive.');
    }
    if (from > to) {
      throw new Error("'to' value (" + to + ") should be larger than 'from' value (" + from + ") in a for loop.");
    }
  }

  const logGameState = (id) => {
    // Create current game state
    var currentGameState = {
      "id": id,
      "position": (waitTime != 0) ?
        [_.cloneDeep(avatarRef.current.attrs.x), _.cloneDeep(avatarRef.current.attrs.y)] :
        [_.cloneDeep(avatarX), _.cloneDeep(avatarY)],
      "goals": _.cloneDeep(goals.map((goal) => {
        return { num: goal.num, type: goal.type };
      }))
    }

    const stateIsPreviouslyEncountered = programStates
      .map((element) => gameStateIsEqual(element, currentGameState))
      .some(element => element === true);

    // console.log(currentGameState);

    // If already encountered
    if (stateIsPreviouslyEncountered) {
      // Throw error
      throw new Error("Loop continues forever.");
    } else {
      // Append to game states
      programStates.push(currentGameState);
    }
  }

  const cleanUnusedBlocks = (workspace) => {
    var blocks = workspace.getAllBlocks();
    // For all blocks present in the workspace
    for (var i = 0; i < blocks.length; ++i) {
      // If the root block
      var rootBlock = blocks[i].getRootBlock();
      // Is not when run type, or is deletable
      if (rootBlock.type != "when_run" && rootBlock.type != "function" && rootBlock.isDeletable()) {
        // Dispose of that block
        blocks[i].dispose();
      }
    }
  }

  const verifyMove = async () => {
    if (waitTime != 0) {
      if (
        !path.find(
          (loc) =>
            avatarRef.current.attrs.x === loc[0] &&
            avatarRef.current.attrs.y === loc[1]
        )
      ) {
        return false;
      }
      if (exercise.autoCollect) await collectGoal();
      return true;
    } else {
      if (
        !path.find(
          (loc) =>
            avatarX === loc[0] &&
            avatarY === loc[1]
        )
      ) {
        return false;
      }
      if (exercise.autoCollect) await collectGoal();
      return true;
    }
  };

  const move = async (deltaX, deltaY, direct) => {
    if (waitTime != 0) {
      setDirection(direct);
      setCharState(direct);
      currDirection = direct;
      await new Promise((resolve, reject) => {
        avatarRef.current.to({
          x: avatarRef.current.attrs.x + deltaX,
          y: avatarRef.current.attrs.y + deltaY,
          duration: (waitTime * 4) / 5000,
          onFinish: async () => {
            if (await verifyMove()) resolve();
            else reject(new Error("Character is not following path."));
          }
        });
      });
      await sleep(waitTime);
    } else {
      currDirection = direct;
      avatarX += deltaX;
      avatarY += deltaY;
      if (!await verifyMove()) {
        throw new Error("Character is not following path.");
      }
    }
  };


  /* eslint-disable no-unused-vars */
  // highlight block as it runs
  const highlightBlock = (id) => {
    workspace.highlightBlock(id);
  };

  // game functionalities
  const moveEast = async () => {
    await move(SCALE, 0, 'right');
  };

  const moveWest = async () => {
    await move(-SCALE, 0, 'left');
  };

  const moveNorth = async () => {
    await move(0, -SCALE, 'up');
  };

  const moveSouth = async () => {
    await move(0, SCALE, 'down');
  };

  const moveForward = async () => {
    if (currDirection === 'right') await moveEast();
    else if (currDirection === 'left') await moveWest();
    else if (currDirection === 'up') await moveNorth();
    else await moveSouth();
  };

  const moveBackward = async () => {
    if (currDirection === 'right') await move(-SCALE, 0, currDirection);
    else if (currDirection === 'left') await move(SCALE, 0, currDirection);
    else if (currDirection === 'up') await move(0, SCALE, currDirection);
    else await move(0, -SCALE, currDirection);
  };

  const turnLeft = async () => {
    if (waitTime != 0) {
      let nextDirection;
      if (currDirection === 'right') nextDirection = 'up';
      else if (currDirection === 'left') nextDirection = 'down';
      else if (currDirection === 'up') nextDirection = 'left';
      else nextDirection = 'right';
      setDirection(nextDirection);
      currDirection = nextDirection;
      await sleep((waitTime * 2) / 5);
    } else {
      let nextDirection;
      if (currDirection === 'right') nextDirection = 'up';
      else if (currDirection === 'left') nextDirection = 'down';
      else if (currDirection === 'up') nextDirection = 'left';
      else nextDirection = 'right';
      //setDirection(nextDirection);
      currDirection = nextDirection;
    }
  };

  const turnRight = async () => {
    if (waitTime != 0) {
      let nextDirection;
      if (currDirection === 'right') nextDirection = 'down';
      else if (currDirection === 'left') nextDirection = 'up';
      else if (currDirection === 'up') nextDirection = 'right';
      else nextDirection = 'left';
      setDirection(nextDirection);
      currDirection = nextDirection;
      await sleep((waitTime * 2) / 5);
    } else {
      let nextDirection;
      if (currDirection === 'right') nextDirection = 'down';
      else if (currDirection === 'left') nextDirection = 'up';
      else if (currDirection === 'up') nextDirection = 'right';
      else nextDirection = 'left';
      //setDirection(nextDirection);
      currDirection = nextDirection;
    }
  };

  const getGoalTypeAtCurrPos = async () => {
    if (waitTime != 0) {
      const goalIndex = goals.findIndex(
        (goal) =>
          avatarRef.current.attrs.x === goal.location[0] * SCALE &&
          avatarRef.current.attrs.y === goal.location[1] * SCALE &&
          goal.num > 0
      );

      if (goalIndex === -1) return -1;
      return exercise.goals.types[goals[goalIndex].type].name;
    } else {
      const goalIndex = goals.findIndex(
        (goal) =>
          avatarX === goal.location[0] * SCALE &&
          avatarY === goal.location[1] * SCALE &&
          goal.num > 0
      );

      if (goalIndex === -1) return -1;
      return exercise.goals.types[goals[goalIndex].type].name;
    }
  };

  const isPath = (testDir) => {
    if (waitTime != 0) {
      return pathExists(
        currDirection,
        testDir,
        avatarRef.current.attrs.x,
        avatarRef.current.attrs.y,
        path,
        SCALE
      );
    } else {
      return pathExists(
        currDirection,
        testDir,
        avatarX,
        avatarY,
        path,
        SCALE
      );
    }
  };
  /* eslint-enable no-unused-vars */

  const saveWorkspace = () => {
    props.setSolveTime(solveTime);
    const performanceData = {
      Actual_lines: 'a',
      ExerciseCode:
        exercise.level +
        String(exercise.difficulty) +
        String(exercise.story).padStart(3, '0'),
      Expected_lines: 'a',
      Exp_time: exercise.expectedTime,
      Act_time: props.solveTime,
      Code: String(
        Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace))
      ),
      Status: 'Incomplete'
    };

    // props.setWorkspace(workspace);
    // console.log(String(
    //  Blockly.Xml.domToPrettyText(Blockly.Xml.workspaceToDom(workspace, true))
    //  ));

    props.setAttempt({
      previous_code: performanceData.Code,
      previous_line: 0, // unused
      previous_time: performanceData.Act_time
    });

    axios
      .post(`${process.env.REACT_APP_LMS_API}/save`, performanceData, {
        headers: { Authorization: `Token ${getUserCookies()}` }
      })
      .then((response) => {
        if (response.data.success === 'True') {
          props.enqueueSnackbar('Code Saved', {
            variant: 'success'
          });
        } else {
          props.enqueueSnackbar('Failed to save code', {
            variant: 'error'
          });
        }
      });
  };

  const loadPreviousCode = () => {
    const currentIndex = exerciseIds.findIndex(
      (elem) => elem.id === exerciseId
    );

    if (currentIndex < exerciseIds.length && currentIndex >= 0) {
      console.log(String(
        Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace))
      ),)
      var previousWorkspace = Blockly.Xml.workspaceToDom(workspace);
      try {
        let exerciseXML = Blockly.Xml.textToDom(exerciseIds[currentIndex].previous_code);
        Blockly.Xml.clearWorkspaceAndLoadFromXml(exerciseXML, workspace);
        setSolveTime(exerciseIds[currentIndex].previous_time);
      } catch (err) {
        props.enqueueSnackbar('Previous code outdated or not found', {
          variant: 'error'
        });
        Blockly.Xml.clearWorkspaceAndLoadFromXml(previousWorkspace, workspace);
      }
    } else {
      props.enqueueSnackbar('Exercise not listed in dashboard', {
        variant: 'error'
      });
    }
  };

  const skip = () => {
    const path = nextExercise
      ? `/${nextExercise.type.toLowerCase()}/${nextExercise.id}`
      : '/';
    history.push(path);
  }

  const sendPerformance = () => {
    const performanceData = {
      Actual_lines: workspace.getAllBlocks().length - 1,
      ExerciseCode:
        exercise.level +
        String(exercise.difficulty) +
        String(exercise.story).padStart(3, '0'),
      Expected_lines: exercise.expectedBlocks,
      Exp_time: exercise.expectedTime,
      Act_time: solveTime,
      Code: String(
        Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace))
      ),
      Status: 'Complete'
    };

    axios
      .post(`${process.env.REACT_APP_LMS_API}/save`, performanceData, {
        headers: { Authorization: `Token ${getUserCookies()}` }
      })
      .then((response) => {
        if (response.data.success === 'True') {
          props.enqueueSnackbar('Performance saved', {
            variant: 'success'
          });
        } else {
          props.enqueueSnackbar('Failed to save performance', {
            variant: 'error'
          });
        }
      });
  };

  const checkResult = () => {
    let goalNum = 0;
    goals.forEach((goal) => {
      goalNum += goal.num;
    });

    if (goalNum === 0) {
      setCharState('success');
      setError(false);
      setCompleted(true);

      // Stop the clock when successfuly completed exercises
      clearInterval(clockInterval);
      cleanUnusedBlocks(workspace);
      // Clean workspace and recalculate
      setActualBlocks(workspace ? workspace.getAllBlocks().length - 1 : 0);

      sendPerformance();

      props.setAttempt({
        previous_code: String(Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace))),
        previous_line: workspace ? workspace.getAllBlocks().length - 1 : 0,
        previous_time: solveTime
      });
      props.completeExercise();
    } else {
      // Have not collected all the goals
      setErrMessage(exercise.errMessage + ' Error: Not all goals were collected.');
      setCharState('failure');
      setError(true);
    }
  };

  const play = async () => {
    // Maybe still need to reset goal number
    goals = _.cloneDeep(exercise.goals.instances);
    dispatch({ type: 'reset', payload: exercise.goals.instances });

    setPlaying(1);
    randomize();
    try {
      avatarX = exercise.character.location[0] * SCALE;
      avatarY = exercise.character.location[1] * SCALE;
      const execution = `(async () => { ${program} })();`;
      await eval(execution);
      if (waitTime == 0) {
        avatarRef.current.to({
          x: avatarX,
          y: avatarY,
          duration: 0
        });
        setDirection(currDirection);
      }
    } catch (e) {
      // console.log(e);
      // Manually update the avatar location if the speed is set to max
      if (waitTime == 0) {
        avatarRef.current.to({
          x: avatarX,
          y: avatarY,
          duration: 0
        });
        setDirection(currDirection);
      }
      setErrMessage(exercise.errMessage + '. Error: ' + e.message);
      setError(true);
      setCharState('failure');
      setPlaying(2);
      return;
    }
    checkResult();
    setPlaying(2);
  };

  // Get exercise data from database
  useEffect(() => {
    setLoading(true);
    goalRef.current = []; // Reset the array of references to goal images

    axios
      .get(`${process.env.REACT_APP_EXE_API}/maze/${exerciseId}`)
      .then((response) => {
        props.setCurrentExercise(response.data);
        setLoading(false);
      })
      .catch(() => {
        props.enqueueSnackbar('Failed to fetch exercise', {
          variant: 'error'
        });
        history.push('/');
      });
  }, [exerciseId]);

  // Only run the following once when data is first fetched
  useEffect(() => {
    if (exercise && exercise.exerciseType == 'BlocklyMaze') {
      // Create custom blocks based on data
      if (!exercise.autoCollect) {
        exercise.goals.types.forEach((type) => {
          if (type.name !== 'unknown')
            createCollecGoalBlock(type.img, type.name, type.action);
        });
        createIfAtGoalBlock(exercise.goals.types);
        createSwitchGoalBlock(exercise.goals.types);
      }
      if (exercise.variables) {
        createVariableBlocks(exercise.variables);
      }

      // Set up initial game state
      setDirection(exercise.character.direction);
      setCharState(exercise.character.direction);
      dispatch({ type: 'reset', payload: exercise.goals.instances });

      const newGoalImgs = exercise.goals.instances.map((goal) => {
        const goalImage = new window.Image();
        goalImage.src = exercise.goals.types[goal.type].img;
        return goalImage;
      });
      setGoalImgs(newGoalImgs);

      if (avatarRef.current) {
        avatarRef.current.to({
          x: exercise.character.location[0] * SCALE,
          y: exercise.character.location[1] * SCALE,
          onFinish: () => {
            goalRef.current.forEach((ref) => ref.show());
          }
        });
      }
      setPlaying(0);

      // Check if exercise already completed on this session
      const currentExerciseIndex = exerciseIds.findIndex((element) => element.id === exercise._id);
      if (currentExerciseIndex !== -1) {
        let element = exerciseIds[currentExerciseIndex];
        if (element.completed === true) {
          // In homework list, finished
          setPreviouslyCompleted(true);
          setCompleted(true);
          setActualBlocks(element.previous_line);

          // Do not start clock
          setSolveTime(element.previous_time);
          clearInterval(clockInterval);

          return () => {
            clearInterval(clockInterval);
          };
        } else {
          // In homework list, not recorded as complete
          setPreviouslyCompleted(false);
          setCompleted(false);
          setSolveTime(0);
          restartClock();
          return () => {
            clearInterval(clockInterval);
          };
        }
      } else {
        // Exercise not present in homework list because of custom routing
        // Starts as usual
        setPreviouslyCompleted(false);
        setCompleted(false);
        setSolveTime(0);
        restartClock();
        return () => {
          clearInterval(clockInterval);
        };
      }
    }

    return () => { };
  }, [exercise]);

  // Hook to load code if an attempt is already made
  useEffect(() => {
    if (exercise && workspace) {
      console.log("Checking code availability");
      // Check if exercise is in homework list but not completed
      const currentExerciseIndex = exerciseIds.findIndex((element) => element.id === exercise._id);
      if (currentExerciseIndex !== -1) {
        console.log("Checking exercise completed");
        let element = exerciseIds[currentExerciseIndex];
        if (element.completed === false) {
          console.log("Attempting to load code");
          var previousWorkspace = Blockly.Xml.workspaceToDom(workspace);
          try {
            let exerciseXML = Blockly.Xml.textToDom(element.previous_code);
            Blockly.Xml.clearWorkspaceAndLoadFromXml(exerciseXML, workspace);
            setSolveTime(element.previous_time);
          } catch (err) {
            // TODO: Error?
            Blockly.Xml.clearWorkspaceAndLoadFromXml(previousWorkspace, workspace);
          }
        }
      }
    }

  }, [exercise, workspace])

  useEffect(() => {
    // props.setWorkspace(workspace);
    if (exercise && exerciseIds && exerciseIds.length > 0) {
      // find the current exercise index in exercise id list in redux store
      const currentIndex = exerciseIds.findIndex(
        (elem) => elem.id === exercise._id
      );

      // if found current exercise and index is within bounds
      // set next exercise by incrementing the current index, otherwise set to 0
      if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
        const targetId = exercise._id;
        const targetType = 'maze';

        // Find the index of the target item
        const currentIndex = exerciseIds.findIndex(
          (elem) => elem.id === targetId && elem.type === targetType
        );

        // If the current item is found and it's not the last item in the array
        if (currentIndex !== -1 && currentIndex < exerciseIds.length - 1) {
          // Get the next item in the array
          const nextExercise = exerciseIds[currentIndex + 1];

          // Set the next exercise
          setnextExercise({
            id: nextExercise.id,
            type: nextExercise.type
          });
        } else {
          // Handle the case where no match is found or it's the last item
          console.log('No next exercise found');
          setnextExercise(null);
        }
      } else if (currentIndex === exerciseIds.length - 1) {
        setnextExercise(null);
      } else {
        // exerciseIds.length > 0
        setnextExercise(
          {
            id: exerciseIds[0].id,
            type: exerciseIds[0].type
          }
        );
      }
    } else {
      setnextExercise(null);
    }
  }, [exercise, exerciseIds]);

  // Hide collected goals
  useEffect(() => {
    if (goalState) {
      goalState.forEach((goal, index) => {
        if (goal.num == 0) goalRef.current[index].hide();
      });
    }
  }, [goalState]);

  // Initialize images
  useEffect(() => {
    if (exercise && exercise.exerciseType == 'BlocklyMaze') {
      const img1 = new window.Image();
      img1.src = exercise.background;
      setBackground(img1);

      const newGoalImgs = [];
      exercise.goals.instances.forEach((goal) => {
        const img2 = new window.Image();
        img2.src = exercise.goals.types[goal.type].img;
        newGoalImgs.push(img2);
      });
      setGoalImgs(newGoalImgs);

      const img3 = new window.Image();
      img3.src = exercise.obstacle.img;
      setBlockImg(img3);

      const img4 = new window.Image();
      img4.src = exercise.path.img;
      setPathImg(img4);

      const newNumberImgs = [];
      NumberUrls.forEach((numUrl) => {
        const img5 = new window.Image();
        img5.src = numUrl;
        newNumberImgs.push(img5);
      });
      setNumberImgs(newNumberImgs);

      setErrMessage(exercise.errMessage);
    }
  }, [exercise]);

  const beforeLeave = () => {
    saveWorkspace();
  }

  const renderBlocklyWorkspaceComponent = () => {
    return (
      <CustomBlocklyWorkspace
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        exercise={exercise}
        workspace={workspace}
        setProgram={setProgram}
        setCodeView={setCodeView}
        setWorkspace={setWorkspace}
        isMute={isMute}
      />
    );
  };

  const renderGameAreaComponent = () => {
    return (
      <Box
        className={classes.containerMinWidth}
        display="flex"
        justifyContent="center"
        alignItems="center"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Paper className={classes.paper}>
          <Box
            className={classes.minWidth}
            display="flex"
            justifyContent="center"
            alignItems="center"
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            <Stage
              width={exercise.maze.length * SCALE}
              height={exercise.maze.length * SCALE}
            >
              <Layer>
                {/* Background Image */}
                <Image
                  x={0}
                  y={0}
                  image={backgroundImg}
                  width={exercise.maze.length * SCALE}
                  height={exercise.maze.length * SCALE}
                />
                {/* Path and Block images (if any) */}
                {exercise.maze.map((row, i) => {
                  return row.map((num, j) => {
                    if (num === 0)
                      return (
                        <>
                          {exercise.obstacle.img && (
                            <Image
                              key={`${j}${i}`}
                              x={j * SCALE}
                              y={i * SCALE}
                              width={SCALE}
                              height={SCALE}
                              image={blockImg}
                            />
                          )}
                        </>
                      );
                    path.push([j * SCALE, i * SCALE]);
                    if (exercise.path.img)
                      return (
                        <Image
                          key={`${j}${i}`}
                          x={j * SCALE}
                          y={i * SCALE}
                          height={SCALE}
                          width={SCALE}
                          image={pathImg}
                        />
                      );
                    return null;
                  });
                })}
                {/* Goal Images */}
                {goalState.map((goal, i) => (
                  <Group
                    key={`${goal.location[0]}${goal.location[1]}`}
                    x={goal.location[0] * SCALE}
                    y={goal.location[1] * SCALE}
                    ref={addToGoalRefs}
                  >
                    <Image image={goalImgs[i]} width={SCALE} height={SCALE} />
                    <Image
                      image={
                        !exercise.autoCollect &&
                        (goal.num >= 0 ? numberImgs[goal.num] : numberImgs[0])
                      }
                      width={SCALE / 3}
                      height={SCALE / 3}
                      x={(2 * SCALE) / 3}
                      y={(2 * SCALE) / 3}
                    />
                  </Group>
                ))}
                {/* Character Image (with direction) */}
                <Group
                  ref={avatarRef}
                  x={exercise.character.location[0] * SCALE}
                  y={exercise.character.location[1] * SCALE}
                >
                  <GIF
                    src={getCharGIF(charState, exercise.character)}
                    width={SCALE}
                    height={SCALE}
                  />
                  <GIF
                    src={getDirectionGif(direction)}
                    width={SCALE / 4}
                    height={SCALE / 4}
                  />
                </Group>
              </Layer>
            </Stage>
          </Box>
          {/* Speed slider, play button, Code view */}
          <SpeedSlider setWaitTime={setWaitTime} />
          {playing === 0 && (
            <CustomButton
              onClick={async () => {
                try {
                  await play(); // TODO: FIX
                } catch (e) {

                }
              }}
              content='Play'
              icon={<PlayArrowIcon />}
            />
          )}
          {playing === 1 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={stopGame} // () => window.location.reload(false)
            >
              STOP
            </Button>
          )}
          {playing === 2 && (
            <CustomButton
              onClick={resetGame}
              content='Reset'
              icon={<ResetIcon />}
            />
          )}
          <CustomButton
            // startIcon={<SaveIcon />}
            onClick={saveWorkspace}
            style={{ marginLeft: 15 }}
            content='Save'
            icon={<SaveIcon />}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={loadPreviousCode}
            style={{ marginLeft: 15 }}
          >
            LOAD CODE
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={skip}
            style={{ marginLeft: 15 }}
          >
            SKIP
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsMute(isMute => !isMute)}
            style={{ marginLeft: 15 }}
          >
            {isMute ? 'UNMUTE' : 'MUTE'}
          </Button>
          <ShowCode code={codeView} width={exercise.maze.length * SCALE} />
        </Paper>
      </Box>
    )
  };

  const renderGameAreaAndWorkspace = () => {
    if (screenWidth > 1200) {
      // Vertical split
      return (
        <Grid container spacing={0}>
          <Grid className={classes.minWidth} item xs={6}>
            {renderGameAreaComponent()}
          </Grid>
          {/* Blockly */}
          <Grid className={classes.minWidth} item xs={6}>
            {renderBlocklyWorkspaceComponent()}
          </Grid>
        </Grid>
      );
    } else {
      // No vertical split
      return (
        <div>
          {renderGameAreaComponent()}
          {renderBlocklyWorkspaceComponent()}
        </div>
      );
    }
  }

  if (loading) return <LoadingPage />;

  return (
    <div className={classes.root}>
      {/* Modal to next exercise after completion */}
      <div className={classes.orangeBlock}>
        <CongratsModal
          nextExercise={nextExercise}
          open={completed}
          setOpen={setCompleted}
          lineNum={actualBlocks}
          history={history}
          clockInterval={clockInterval}
          restartClock={restartClock}
          successGIF={exercise.character.success}
          solveTime={solveTime}
          previouslyCompleted={previouslyCompleted}
        />
        {/* Annotation and Instruction */}
        <Box className={classes.minWidth} display="flex" m={1} p={1} bgcolor="background.paper">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Annotation
                character={exercise.character}
                goals={exercise.goals.types}
              />
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.instruction}>
                <Instruction
                  avatar={exercise.character.right}
                  instruction={exercise.instruction}
                  story={exercise.storyText} // exercise.storyText
                  errMessage={errMessage}
                  error={error}
                  resetGame={resetGame}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {/* Clock */}

        {/* Game Area and Blockly */}
        {renderGameAreaAndWorkspace()}

        <Beforeunload onBeforeunload={(event) => {
          event.preventDefault();
          saveWorkspace();
        }}
        />

        <Prompt
          message={() => { !completed && saveWorkspace() }}
        />
      </div>
    </div>
  );
};

BlocklyMaze.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setCurrentExercise: PropTypes.func.isRequired,
  setSolveTime: PropTypes.func.isRequired,
  exercise: PropTypes.object,
  completeExercise: PropTypes.func.isRequired,
  setAttempt: PropTypes.func.isRequired,
  exerciseIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  )
};

BlocklyMaze.defaultProps = {
  exercise: null,
  exerciseIds: null
};

const mapStateToProps = (state) => ({
  exercise: state.exercises.current,
  exerciseIds: state.exercises.ids,
  solveTime: state.exercises.solveTime,
  workspace: state.exercises.workspace,
});

export default connect(mapStateToProps, {
  setCurrentExercise,
  completeExercise,
  setAttempt,
  setSolveTime,
  setWorkspace,
})(withSnackbar(BlocklyMaze));
