import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GeneralText } from '../../common/CommonStyles';

const useStyles = makeStyles((theme) => ({
    code: {
        fontFamily: "Courier New"
    }
}));

const CodeLine = ({ content, alignment }) => {
    // Component styling and screen resolution
    const classes = useStyles();
    const [verified, setVerified] = useState(false);
    const [verifiedMessage, setVerifiedMessage] = useState("");

    const verify = () => {
        if (content === undefined) {
            setVerifiedMessage("CodeLine is undefined");
            setVerified(false);
            return
        }
        else if (content.length == 0) {
            setVerifiedMessage("CodeLine length is 0");
            setVerified(false);
            return
        }
        setVerified(true)
    }

    useEffect(() => {
        verify()
    }, [content])


    if (!verified) {
        return (
            <div>
                {verifiedMessage}
            </div>
        )
    } else {
        return (
            <GeneralText className={classes.code} align={alignment}>
                <ReactMarkdown>{content}</ReactMarkdown>
            </GeneralText>
        );
    }
};

export default CodeLine;