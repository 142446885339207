import React from 'react';
import Box from '@material-ui/core/Box';
import ReadonlyDisplay from '../utils/ReadonlyDisplay';
import { Typography } from '@material-ui/core';

const ExerciseDisplay = ({ exercise }) => {
    return (
        <Box>
            <Typography align='left' fontWeight="fontWeightBold">
                <Box fontWeight="fontWeightBold" m={1}>
                    Instruction: {exercise.instruction}
                </Box>
            </Typography>
            <Typography align='left'>
                <Box fontWeight="fontWeightBold" m={1}>
                    Input:
                </Box>
            </Typography>
            <ReadonlyDisplay
                value={exercise.test.stdin}
                theme={'tomorrow_night'}
                height={120}
            />
            <Typography align='left'>
                <Box fontWeight="fontWeightBold" m={1}>
                    Expected output:
                </Box>
            </Typography>
            <ReadonlyDisplay
                value={exercise.test.expected_output}
                theme={'tomorrow_night'}
            />
        </Box>
    )
}

export default ExerciseDisplay;