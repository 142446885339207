import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GeneralText } from '../../common/CommonStyles';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(() => ({
  explanation: {
    textAlign: 'justify',
  },
  divider: {
    marginTop: '2%',
    marginBottom: '2%',
    height: '4px',
    width: '100%'
  }
}));

export const GeneralExplanation = ({ heading, explanation, payloadType }) => {

  const classes = useStyles();
  return (
    <React.Fragment>
      <Divider className={classes.divider} />
      {payloadType != 'html' && ( // markdown option
        <HintText className={classes.explanation}>
          <ReactMarkdown>{`**` + heading + `** ` + explanation}</ReactMarkdown>
        </HintText>
      )}
      {payloadType == 'html' && (
        <HintText className={classes.explanation} dangerouslySetInnerHTML={{ __html: `<b>` + heading + `</b>` + explanation }} />
      )}
    </React.Fragment>
  );
};

const HintText = styled(GeneralText)`
  margin-bottom:10px;
`;

export const ErrorExplanation = ({ heading, explanation, enablePopup, callback }) => {

  const handleClose = () => callback(false)
  return (
    <Dialog open={enablePopup} onClose={() => handleClose()}>
      <DialogTitle style={{ textAlign: 'center' }}>{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontSize: '1.2em' }} dangerouslySetInnerHTML={{ __html: explanation }}></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const AnswerExplanation = ({ heading, explanation, enablePopup, callback }) => {

  const handleClose = () => callback(false)
  return (
    <Modal
      open={enablePopup}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {heading}
        </Typography>
        <br />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {explanation}
        </Typography>
        <DialogActions>
          <Button style={{ fontSize: '1rem' }} onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};
