/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import MultiChoiceButton from './MultiChoiceButton';


const MultiChoice = ({ onSubmission, content, index, formatType }) => {
    const [selectedChoices, setSelectedChoices] = useState([]);

    const select = (selected, choice) => {
        if (selected) {
            setSelectedChoices((selectedChoices) => [...selectedChoices, choice]);
        } else {
            setSelectedChoices((selectedChoices) => selectedChoices.filter((val) => {
                return val != choice ? true : false;
            }));
        }
    };

    useEffect(() => {
        const sortedChoices = _.sortBy(selectedChoices, (choice) => {
            return content.findIndex((element) => element === choice);
        });
        onSubmission(sortedChoices, index);
    }, [selectedChoices]);

    const renderedChoices = content.map((element, index) => (
        <MultiChoiceButton key={index} answer={element} onSelect={select} formatType={formatType} />
    ));

    return (
        <div>
            {renderedChoices}
        </div>
    );
};

export default MultiChoice;
