import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import styled from '@emotion/styled';

const BackSubmitNextButtons = ({ onBack, onSubmit, onNext }) => {
    return (
        <ButtonGrid container spacing={3}>
            <GridItem item xs={4}>
                <Button variant="contained" color="primary" onClick={onBack}>
                    BACK
                </Button>
            </GridItem>
            <GridItem item xs={4}>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    SUBMIT
                </Button>
            </GridItem>
            <GridItem item xs={4}>
                <Button variant="contained" color="primary" onClick={onNext}>
                    NEXT
                </Button>
            </GridItem>
        </ButtonGrid>
    );
};

export default BackSubmitNextButtons;

const ButtonGrid = styled(Grid)`
 display:flex;
 justify-content: center;
 align-items: center;
`;

const GridItem = styled(Grid)`
@media (min-width:768px){
 max-width:15%;
}
`;