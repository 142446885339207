import React from 'react';
import { Grid, Box } from '@mui/material';

const boxStyles = {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#d6d6d6',
    border: '1px solid #d6d6d6',
    borderRadius: '4px',
    fontFamily: "Courier New",
    fontSize: '16px',
    color: '#ffffff',
    textAlign: 'center',
    wordWrap: 'break-word',
    margin: '2px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
};

const highlightedBoxStyles = {
    ...boxStyles,
    backgroundColor: '#a8d4f5',
    border: '1px solid #a8d4f5',
};

const deepBlueBoxStyles = {
    ...boxStyles,
    backgroundColor: '#1565c0',
    border: '1px solid #1565c0',
};

export const Crosswords = ({ payload }) => {
    const payloadString = Array.isArray(payload) ? payload[0] : payload;

    const rows = typeof payloadString === 'string'
        ? payloadString.split('\\n').map(row => row.trim())
        : [];

    return (
        <Grid container direction="column" spacing={0} style={{ lineHeight: 0 }}>
            {rows.map((row, rowIndex) => (
                <Grid item key={rowIndex}>
                    <Grid container spacing={0} justifyContent="center">
                        {row.split('').map((char, charIndex) => (
                            <Grid item key={charIndex}>
                                <Box
                                    sx={
                                        char === '-'
                                            ? boxStyles
                                            : char === '*'
                                            ? highlightedBoxStyles
                                            : deepBlueBoxStyles
                                    }
                                >
                                    {char === '-' || char === '*' ? '' : char}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};
