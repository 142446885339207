import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (width) => makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(5),
      width: width,
    }
  }
}));

const ShowCode = ({ code, width }) => {
  const classes = useStyles(width);
  
  return (
    <div className={classes.root}>
      <TextField
        id="standard-read-only-input"
        InputProps={{ readOnly: true }}
        multiline
        value={code}
        variant="outlined"
        maxRows={10}
        style = {{width: width}}
      />
    </div>
  );
};

ShowCode.propTypes = {
  code: PropTypes.string
};

ShowCode.defaultProps = {
  code: ''
};

export default ShowCode;
