import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from './authReducer';
import exerciseReducer from './exerciseReducer';

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  exercises: exerciseReducer
});
