import React from 'react';

export default function ResetIcon() {
  return (
    <svg style={{marginRight: '5px'}}xmlns="http://www.w3.org/2000/svg" width="10" height="14.241" viewBox="0 0 13.24 14.241">
      <g id="Group_371" data-name="Group 371" transform="translate(-123.476 -639.378)">
          <path id="Path_9595" data-name="Path 9595" d="M122.741,645.93s-.422-6.544,5.576-6.281,5.078,6.281,5.078,6.281a5.509,5.509,0,0,1-5.078,3.332" transform="translate(2 0.988)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path id="Path_9596" data-name="Path 9596" d="M133.005,651.981l-4.258-1.8,2.646-2.782" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
      </g>
    </svg>
  );
}