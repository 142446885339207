export const SIGN_IN = 'sign_in';
export const SIGN_OUT = 'sign_out';
export const SET_EXERCISES = 'set_exercises';
export const SET_CURR_EXE = 'set_current_exercise';
export const COMPLETE_EXERCISE = 'complete_exercise';
export const SET_ATTEMPT = 'set_attempt';
export const SOUND_ON = 'sound-on';
export const SOUND_OFF = 'sound-off';
export const SET_SOLVE_TIME = 'solve_time';
export const SET_WORKSPACE = 'set_work_space';

export const signIn = () => {
  return {
    type: SIGN_IN
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT
  };
};

export const setExercises = (type, ids) => {
  return {
    type: SET_EXERCISES,
    payload: { type, ids }
  };
};

export const setCurrentExercise = (exercise) => {
  return {
    type: SET_CURR_EXE,
    payload: exercise
  };
};

export const completeExercise = () => {
  return {
    type: COMPLETE_EXERCISE
  };
};

export const setAttempt = (attempt) => {
  return {
    type: SET_ATTEMPT,
    payload: attempt
  };
};

export const soundOn = () => {
  return {
    type: SOUND_ON
  }
}

export const soundOff = () => {
  return {
    type: SOUND_OFF
  }
}

export const setSolveTime = (solveTime) => {
  return {
    type: SET_SOLVE_TIME,
    payload: solveTime,
  }
}

export const setWorkspace = (workspace) => {
  return {
    type: SET_WORKSPACE,
    payload: workspace,
  }
}
