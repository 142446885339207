/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import Timer from '../utils/Timer';

import { setCurrentExercise, completeExercise, setAttempt } from '../../actions';

import useWindowDimensions from '../utils/useWindowDimensions';
import LoadingPage from '../utils/LoadingPage';

// Extremely temporary solution
// Don't commit this file into source control
// This will be the db format later on
import exerciseData from './temporary-exercise-data.json';
import { Typography, Paper, Grid, Box, Button } from '@material-ui/core';
import MultiChoiceButton from './MultiChoiceButton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "850px"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        // color: theme.palette.text.secondary,
        height: "850px"
    },
    question: {
        padding: theme.spacing(2),
        // color: theme.palette.text.secondary
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    code: {
        fontFamily: "Courier New"
    }
}));


const MultiChoice = (props) => {
    var {
        match: {
            params: { id: exerciseId }
        },
        exercise, // exercise data
        exerciseIds, // exercise id list used for link to next exercise
        history // Routing variable
    } = props;
    const [loading, setLoading] = useState(true);

    const [subQuestionNumber, setSubQuestionNumber] = useState(1);

    const handleChange = (number) => {
        if (number < exercise.subquestions.length) {
            setSubQuestionNumber(number);
        } else {
            console.log("FINISHED");
        }
    };

    // Component styling and screen resolution
    const classes = useStyles();
    // If styling requires screen width and height use these
    const [nextExercise, setnextExercise] = useState();
    const [code, setCode] = useState('# Input code here');
    const [solveTime, setSolveTime] = useState(0);

    const skip = () => {
        const path = nextExercise
            ? `/${nextExercise.type}/${nextExercise.id}`
            : '/';
        history.push(path);
    }

    // Get exercise data from database
    useEffect(() => {
        setLoading(true);

        // This part is only for when exercises are not in the database
        // When exercises are put on the database we will need to use axios to fetch exercise data instead
        let indicatedExercise = exerciseData.find((elem) => elem._id === exerciseId)
        if (indicatedExercise !== undefined) {
            props.setCurrentExercise(indicatedExercise);
            setLoading(false);
        } else {
            props.enqueueSnackbar('Failed to fetch exercise', {
                variant: 'error'
            });
            history.push('/');
        }

        // Fetching exercise from database will look like this
        /*
        axios
          .get(`${process.env.REACT_APP_EXE_API}/example/${exerciseId}`) // Change url for exercise type
          .then((response) => {
            props.setCurrentExercise(response.data);
            setLoading(false);
          })
          .catch(() => {
            props.enqueueSnackbar('Failed to fetch exercise', {
              variant: 'error'
            });
            history.push('/');
          });
        */
    }, [exerciseId]);

    // Only run the following once when data is first fetched
    useEffect(() => {
        if (exercise && exercise.exerciseType === 'class') {
            console.log(exercise);
            setCode(exercise.initialCode);
        }

        return () => { };
    }, [exercise]);
    useEffect(() => {
        if (exercise && exerciseIds && exerciseIds.length > 0) {
            // find the current exercise index in exercise id list in redux store
            const currentIndex = exerciseIds.findIndex(
                (elem) => elem.id === exercise._id
            );

            // if found current exercise and index is within bounds
            // set next exercise by incrementing the current index, otherwise set to 0
            if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
                setnextExercise(
                    {
                        id: exerciseIds[currentIndex + 1].id,
                        type: exerciseIds[currentIndex + 1].type
                    }
                );
            } else if (currentIndex === exerciseIds.length - 1) {
                setnextExercise(null);
            } else {
                // exerciseIds.length > 0
                setnextExercise(
                    {
                        id: exerciseIds[0].id,
                        type: exerciseIds[0].type
                    }
                );
            }
        } else {
            setnextExercise(null);
        }
    }, [exercise, exerciseIds]);

    // This function create submission from the input
    // const createSubmission = () => {
    //     // Don't create it now
    //     // This should wait until testing method is determined, more so for not-full exercises
    //     console.log("Created submission")
    // };

    // This should check the academy server for the token status
    const refresh = () => {
        console.log("Checking submission token status");
    }

    // const getLanguage = () => {
    //     if (exercise.language === "java") {
    //         return (
    //             <i>Java</i>
    //         )
    //     }
    // }

    const renderIntroduction = (introduction) => {
        return (
            <div>
                <Typography align="left" style={{ color: "#2196f3" }} >
                    Story: {introduction}
                </Typography>
                <br />
            </div>
        );
    }

    const renderInstructions = (instructions) => {
        const renderedInstructions = instructions.map((element) => (
            <Typography key={element.n} align="left">
                {element.line}
            </Typography>
        ));

        return (
            <div>
                {renderedInstructions}
                <br />
            </div>
        );
    };

    const renderQuestion = (question) => {
        return (
            <div>
                <Typography variant="h6" className={classes.code} align="left">
                    {question}
                </Typography>
                <br />
            </div>
        );
    };

    const renderChoices = (choices) => {
        const renderedChoices = choices.map((element) => (
            <MultiChoiceButton key={element.n} next={handleChange} answer={element.answer} />
        ));

        return (
            <div>
                {renderedChoices}
                <br />
            </div>
        );
    };

    const renderContent = (content) => {
        const contentKeys = Object.keys(content);

        const renderedContent = contentKeys.map((key) => {
            switch(key) {
                case 'introduction':
                    return renderIntroduction(content[key]);
                case 'instructions':
                    return renderInstructions(content[key]);
                case 'question':
                    return renderQuestion(content[key]);
                case 'choices':
                    return renderChoices(content[key]);
                default:
                    console.warn(`WARNING: '${key}' is not a valid Content Type`)
            }
        });

        return renderedContent;
    };

    // Try to use Material UI (currently v4, upgrading to v5 when have the time) 
    // instead of primitive HTML tags
    // https://v4.mui.com
    if (loading) return <LoadingPage />;
    return (
        <div className={classes.root}>
            {/* Clock */}
            <Box display="flex" m={1} p={1} bgcolor="background.paper">
                <Grid item xs={5} style={{ marginLeft: 375 }} >
                    <Timer time={solveTime} />
                </Grid>
                <br />
            </Box>
            {/* Annotation and Instruction */}
            <Box display="flex" m={1} p={1} bgcolor="background.paper">
                <Grid container spacing={3} className={classes.container}>
                    <Grid item style={{ width: "50%", alignItems: "center", justifyContent: "center", display: "flex" }} >
                        <Paper className={classes.question} style={{ height: "95%", width: "80%" }} >
                            <Typography style={{ fontWeight: "bold" }}>
                                Java Exercise {exercise._id}
                            </Typography>
                            <br />

                            {renderContent(exercise.content)}

                            <Grid container >
                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        justifyContent="right"
                                        alignItems="right"
                                        m={1}
                                        p={1}
                                        bgcolor="background.paper"
                                    >
                                        <Button variant="contained" color="primary"
                                            onClick={refresh} style={{ marginLeft: 30 }}
                                        >
                                            BACK
                                        </Button>
                                        <Button variant="contained" color="primary" style={{ marginLeft: 100 }}
                                            onClick={refresh}
                                        >
                                            SUBMIT
                                        </Button>
                                        <Button variant="contained" color="primary" style={{ marginLeft: 100 }}
                                            onClick={refresh}
                                        >
                                            NEXT
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

MultiChoice.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.objectOf(PropTypes.string).isRequired
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    setCurrentExercise: PropTypes.func.isRequired,
    exercise: PropTypes.object,
    completeExercise: PropTypes.func.isRequired,
    setAttempt: PropTypes.func.isRequired,
    exerciseIds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        })
    )
};

MultiChoice.defaultProps = {
    exercise: null,
    exerciseIds: null
};

const mapStateToProps = (state) => ({
    exercise: state.exercises.current,
    exerciseIds: state.exercises.ids
});

export default connect(mapStateToProps, {
    setCurrentExercise,
    completeExercise,
    setAttempt
})(withSnackbar(MultiChoice));
