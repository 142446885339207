/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import SelectionBoard from './SelectionBoard';
import Board from './Board';
import { Typography, Paper, Grid, Box } from '@material-ui/core';
import { GeneralText } from '../../../common/CommonStyles';

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    //color: theme.palette.text.secondary,
    height: "850px"
  },
  question: {
    padding: theme.spacing(2),
    //color: theme.palette.text.secondary
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));


const DragDropText = ({ onSubmission, index, dragDrop, text }) => {
  // TODO: Add more?
  // Component styling and screen resolution
  const classes = useStyles();
  const [answers, setAnswers] = useState([]);
  
  // indexes where type is "input"
  const inputIndex = [];
  let input_count = 0;
  for (let i = 0; i < text[0].length; i++) {
    if (text[0][i].type == "input") {
      inputIndex.push(input_count);
      input_count++;
    }
  }

  const updateAnswer = (value, index) => {
    let updated = answers.slice();
    updated[index] = value;
    setAnswers(updated);
  }

  const swapAnswers = (index1, index2) => {
    var temp1 = answers[index1];
    answers[index1] = answers[index2];
    answers[index2] = temp1;
  }

  const removeAnswer = (index) => {
    answers[index] = null;
  }

  useEffect(() => {
    if (answers.length > 0) {
      onSubmission(answers, index);
    }
  }, [answers])


  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      m={1}
      p={1}
      bgcolor="background.paper"
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Paper style={{ padding: "20px", width: "100%" }}>
          <div className={classes.root}>
            <SelectionBoard id="selection-board" cards={dragDrop.cards} updateAnswer={updateAnswer} swapAnswers={swapAnswers} removeAnswer={removeAnswer}/>
          </div>

          {text.map((group, groupIndex) => {
            return (
              <Box
                key={`group-${groupIndex}`}
                flexDirection="column"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                m={1}
                p={1}
                bgcolor="background.paper"
              >
                {renderComponents(group, inputIndex, updateAnswer, swapAnswers, index)}
              </Box>
            );
          })
          }
        </Paper>
      </Grid >
    </Box >
  );
};


const renderComponents = (group, inputIndex, updateAnswer, swapAnswers, index) => {
  // Ensure position exists
  group.forEach(component => {
    component.position = component.position || 0;
  });

  // Group components by position
  const groupedComponents = group.reduce((acc, component) => {
    if (!acc[component.position]) {
      acc[component.position] = [];
    }
    acc[component.position].push(component);
    return acc;
  }, {});

  return Object.keys(groupedComponents).map((position) => {
    const components = groupedComponents[position];
    return (
      <Box
        key={`row-${position}`}
        display="flex"
        alignItems="center"
        style={{ marginBottom: "10px", whiteSpace: "nowrap",maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis"  }}
      >
        {components.map((component, componentIndex) => {
          if (component.type === "text") {
            return (
              
              <GeneralText
                key={`text-${componentIndex}`}
                id="sample"
                style={{
                  fontFamily: "Courier New",
                  userSelect: "none",
                  margin: "10px",
                  whiteSpace: "nowrap"
                }}
              >
                <ReactMarkdown components={{ p: ({ node, ...props }) => <p style={{ margin: 0, padding: 0 }} {...props} /> }} breaks={true}>
                    {component.value}
                </ReactMarkdown>
              </GeneralText>
            );
          } else if (component.type === "input") {
            let boardIndex = 0;
            boardIndex = inputIndex.shift();

            return (
              <Board
                key={`board-${boardIndex}`}
                id={`board-${boardIndex}`}
                depth={0}
                updateAnswer={updateAnswer}
                swapAnswers={swapAnswers}
                index={index.id}
                style={{ marginRight: "10px" }} 
              />
            );
          } else {
            return (
              <Typography
                key={`invalid-${componentIndex}`}
                variant="h6"
                style={{
                  fontFamily: "Courier New",
                  marginLeft: 5,
                  color: "red"
                }}
              >
                {component.type} is not a valid prop type
              </Typography>
            );
          }
        })}
      </Box>
    );
  });
};


export default DragDropText;
