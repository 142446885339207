import { useRef, useEffect } from 'react';
import Cookies from 'universal-cookie';

export const NumberUrls = [
  'https://res.cloudinary.com/csexercises/image/upload/v1620407281/numbers_white/unknown_ka9zs3.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407278/numbers_white/01_xqyqa8.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407278/numbers_white/02_ymztpy.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407278/numbers_white/03_edqqui.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407278/numbers_white/04_n3rbks.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407278/numbers_white/05_hhxpb7.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407278/numbers_white/06_jk8z7g.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/07_pknwlq.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/08_udflpg.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/09_dcbp2e.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/10_wamerk.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/11_enxarb.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/12_lyq96f.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/13_y7lnou.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/14_crhevb.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/15_ld103n.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407279/numbers_white/16_vjlhv6.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/17_ztqfn3.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/18_y6pt2c.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/19_pcitgf.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/20_rkq9fs.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/21_ogbsje.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/22_c9uci5.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/23_oemyph.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/24_b7b3vd.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407280/numbers_white/25_zvoak9.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407281/numbers_white/26_l9bnrx.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407281/numbers_white/27_kqhx56.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407281/numbers_white/28_sdmzlw.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407281/numbers_white/29_hyadgm.png',
  'https://res.cloudinary.com/csexercises/image/upload/v1620407281/numbers_white/30_zm8jxz.png'
];

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const findPath = (x, y, path) => {
  if (!path.find((loc) => x === loc[0] && y === loc[1])) {
    return false;
  }
  return true;
};

export function getAngle(direction) {
  switch (direction) {
    case 'right':
      return 0;
    case 'up':
      return 90;
    case 'left':
      return 180;
    default:
      return 270;

  }
}

export function getDirection(angle) {
  if (45 <= angle && angle <= 135) {
    return 'up';
  } else if (135 <= angle && angle <= 225) {
    return 'left';
  } else if (225 <= angle && angle <= 315) {
    return 'down';
  } else if ((0 <= angle && angle <= 45) || (315 <= angle && angle <= 360)) {
    return 'right';
  } else {
    throw new Error('invalid angle ' + angle);
  }
}

export function pathExists(currDirection, testDir, x, y, path, SCALE) {
  switch (currDirection) {
    case 'left':
      if (testDir === 'ahead') return findPath(x - SCALE, y, path);
      if (testDir === 'right') return findPath(x, y - SCALE, path);
      return findPath(x, y + SCALE, path);
    case 'right':
      if (testDir === 'ahead') return findPath(x + SCALE, y, path);
      if (testDir === 'right') return findPath(x, y + SCALE, path);
      return findPath(x, y - SCALE, path);
    case 'up':
      if (testDir === 'ahead') return findPath(x, y - SCALE, path);
      if (testDir === 'right') return findPath(x + SCALE, y, path);
      return findPath(x - SCALE, y, path);
    default:
      if (testDir === 'ahead') return findPath(x, y + SCALE, path);
      if (testDir === 'right') return findPath(x - SCALE, y, path);
      return findPath(x + SCALE, y, path);
  }
}

export const getCharGIF = (state, character) => {
  switch (state) {
    case 'left':
      return character.left;
    case 'up':
      return character.up;
    case 'down':
      return character.down;
    case 'success':
      return character.success;
    case 'failure':
      return character.failure;
    case 'right':
      return character.right;
    default:
      throw new Error(`Invalid character state of ${state}`);
  }
};

export const storeUserCookies = ({ user, token, expiry }) => {
  const cookies = new Cookies();
  cookies.set('User', JSON.stringify(user));
  cookies.set('token', token);
  cookies.set('expiry', expiry);
};

export const clearUserCookies = () => {
  const cookies = new Cookies();
  cookies.remove('User');
  cookies.remove('token');
  cookies.remove('expiry');
};

export const checkUserCookies = () => {
  const cookies = new Cookies();
  const isAuthenticated = Boolean(cookies.get('token'));
  const timeNow = new Date();
  let cookieExpiry = Date.parse(cookies.get('expiry')) || false;
  cookieExpiry = cookieExpiry && cookieExpiry.toString();
  const isExpired = cookieExpiry ? timeNow >= cookieExpiry : true;
  if (isAuthenticated && !isExpired) {
    return true;
  }
  clearUserCookies();
  return false;
};

export const getUserCookies = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};
