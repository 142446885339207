import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConnectorDotStart from './ConnectorDotStart';
import ConnectorDot from './ConnectorDot';
import ConnectorDotEnd from './ConnectorDotEnd';
import Xarrow, {useXarrow, Xwrapper} from 'react-xarrows';
import { Typography, Paper, Grid, Box, Button, Tab, Tabs } from '@material-ui/core';
import Card from './Card';


const useStyles = makeStyles((theme) => ({
    board: {
      justifyContent: "center",
      width: "150px",
      height:"60px",
      background: "#F0F8FF",
	  border: "1px solid #86C5D8",
      position: "relative",
      padding: "5px",
      borderRadius:"8px",
      margin: "20px",
      zIndex: "100"
    }
  }));

const Board = (props) => {

    const classes = useStyles();

    const drop = (event) => {
        event.preventDefault();
        const cardId = event.dataTransfer.getData('card_id');
        const cardValue = event.dataTransfer.getData('value');

        const card = document.getElementById(cardId);
        if (card == null) {
            return;
        }
        card.style.width = "87%";
        
        event.target.appendChild(card);

        props.updateAnswer(cardValue, props.index)
    }

    const dragOver = (event) => {
        event.preventDefault();
    }

    const renderDots = () => {
        if (props.depth == 0) {
            return (
                <div>
                    <ConnectorDotStart id={`${props.id}-connector3`} 
                    index={props.index}
                    updateConnections={props.updateConnections}
                    parentId={props.id}
                    />
                </div>
            )
        } else if (props.depth == props.maxDepth) {
            return (
                <div>
                    <ConnectorDot id={`${props.id}-connector1`}/>
                    <ConnectorDotEnd
                    id={`${props.id}-connector2`}
                    draggable="true"
                    index={props.index}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <ConnectorDot
                    id={`${props.id}-connector1`}/>
                    <ConnectorDotEnd
                    id={`${props.id}-connector2`}
                    draggable="true"
                    index={props.index}
                    />
                    <ConnectorDotStart id={`${props.id}-connector3`} 
                    index={props.index}
                    updateConnections={props.updateConnections}
                    parentId={props.id}
                    />
                </div>
            )
        }
    }

    return (
        <Paper
        id={props.id}
        onDrop={drop}
        onDragOver={dragOver}
        className={classes.board}>
            { props.children }
            <Xwrapper>
                <Xarrow
                start={`${props.id}-connector1`}
                end={`${props.id}-connector2`}
                lineColor={"#666666"}
                showHead={false}
                startAnchor={'middle'}
                endAnchor={'middle'}
                path={'straight'}
                zIndex={0}
                />
                {renderDots()}
            </Xwrapper>
        </Paper>
    )
}

export default Board;