import React, { useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-konva';
import 'gifler';

const GIF = ({ src, x, y, height, width }) => {
  const imageRef = useRef(null);
  const canvas = useMemo(() => {
    const node = document.createElement('canvas');
    return node;
  }, []);

  useEffect(() => {
    let animate;
    const setAnimate = (a) => {
      animate = a;
      animate.animateInCanvas(canvas);
      animate.onDrawFrame = (ctx, frame) => {
        ctx.drawImage(frame.buffer, frame.x, frame.y);
        imageRef.current.getLayer().draw();
      };
    };

    window.gifler(src).get(setAnimate);

    //return () => animate.stop();
    return () => animate.stop();
  }, [src, canvas]);

  return (
    <Image
      image={canvas}
      ref={imageRef}
      height={height}
      width={width}
      x={x}
      y={y}
    />
  );
};

GIF.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number,
  y: PropTypes.number
};

GIF.defaultProps = {
  x: 0,
  y: 0
};

export default GIF;

// For small direction arrow on the corner
const LEFT_GIF =
  'https://res.cloudinary.com/csexercises/image/upload/v1595504485/ezgif.com-gif-maker_xxejwl.gif';
const RIGHT_GIF =
  'https://res.cloudinary.com/csexercises/image/upload/v1595506504/right_w20_lwxjnu.gif';
const UP_GIF =
  'https://res.cloudinary.com/csexercises/image/upload/v1595506504/up_w20_dcypf8.gif';
const DOWN_GIF =
  'https://res.cloudinary.com/csexercises/image/upload/v1595546389/down_h20_gumjw3.gif';

export const getDirectionGif = (direct) => {
  switch (direct) {
    case 'left':
      return LEFT_GIF;
    case 'right':
      return RIGHT_GIF;
    case 'up':
      return UP_GIF;
    case 'down':
      return DOWN_GIF;
    default:
      throw new Error('Invalid Direction String');
  }
};
