import React, { useState, useEffect, useReducer } from "react";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Collapse } from "@material-ui/core";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CodeInputArea from '../CodeInputArea'
import ReadonlyDisplay from "../ReadonlyDisplay";


const ScratchPad = ({ minLines, maxLines, theme, language, initiallyCollapsed }) => {

    const [code, setCode] = useState('# Input code here');
    const [previousCode, setPreviousCode] = useState('# Input code here');
    const [stdout, setStdout] = useState();
    const [stderr, setStderr] = useState();
    const [sentToServer, setSentToServer] = useState(false);

    const initialCodeResultState = {
        isLoading: false, token: "",
        error: "", output: "Waiting for input submission...", description: "WAITING_INPUT"
    }
    const codeResultReducer = (state, action) => {
        switch (action.type) {
            case "SET_TOKEN":
                return {
                    ...state,
                    token: action.token,
                }
            case "WAITING_INPUT":
                return {
                    ...state,
                    isLoading: false,
                    error: "",
                    output: "Waiting for input submission...",
                    description: action.type
                };
            case "WAITING_EXECUTION":
                return { ...state, isLoading: true, description: action.type };
            case "SUCCESS":
            case "WRONG_ANSWER":
                return { ...state, isLoading: false, output: action.output, description: action.type };
            case "COMPILE_ERROR":
                setStderr(action.error);
                return { ...state, isLoading: false, error: action.error, description: action.type };
            case "RUNTIME_ERROR":
                setStderr(action.error);
                return { ...state, isLoading: false, error: action.error, description: action.type };
            default:
                return state;
        }
    }



    const [{ isLoading, token, error, output, description }, dispatchCodeResult] = useReducer(
        codeResultReducer, initialCodeResultState);

    const submissions = () => {
        // TODO: Handle batch submission if exercise required multiple tests
        if (code !== '' && code !== previousCode) {

            setPreviousCode(code);
            setSentToServer(true);

            const submissionData = {
                source_code: Buffer.from(code).toString('base64'),
                expected_output: Buffer.from(" ").toString('base64')//, remove later 
                // stdin: Buffer.from(exercise.test.stdin).toString('base64')
            }

            axios.post(
                `${process.env.REACT_APP_EXE_API}/submission/python`, submissionData).
                then((submissionResponse) => {
                    dispatchCodeResult({ type: "SET_TOKEN", token: submissionResponse.data });
                    console.log(submissionResponse);
                    dispatchCodeResult({ type: "WAITING_EXECUTION" });
                }).catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
        }
        else if (code === '') {
            console.log("You must enter your codes before click on run button");
        } else if (code === previousCode) {
            refresh();
        }
    }
    const refresh = () => {
        // TODO: Maybe a bit timing sensitive.
        if (token !== '') {
            console.log(token);
            axios.get(`${process.env.REACT_APP_EXE_API}/submission/python/${token}`)
                .then((codeResultResponse) => {
                    // stdout and stderr from judge0 can only be null or base64 encoded string
                    // This converts the field from either null or base64 encoded string into ASCII string
                    setStdout((codeResultResponse.data.stdout) ?
                        (Buffer.from(codeResultResponse.data.stdout, 'base64').toString()) : '');
                    setStderr((codeResultResponse.data.stderr) ?
                        (Buffer.from(codeResultResponse.data.stderr, 'base64').toString()) : '');
                    if (codeResultResponse.data.status.description === 'Compilation Error') {
                        dispatchCodeResult({
                            type: "COMPILE_ERROR",
                            output: '',
                            error: Buffer.from(codeResultResponse.data.compile_output, 'base64').toString()
                        });
                    }
                    if (codeResultResponse.data.status.description === 'Accepted') {
                        dispatchCodeResult({
                            type: "SUCCESS", output: stdout, error: stderr
                        });
                    }
                    if (codeResultResponse.data.status.description === 'Processing') {
                        console.log('still on loading process, please click on the refresh button again');
                    }
                    if (codeResultResponse.data.status.description === "Runtime Error (NZEC)") {
                        console.log("There is runtime error (NZEC)");
                    }
                    if (codeResultResponse.data.status.description === "Wrong Answer") {
                        dispatchCodeResult({
                            type: "WRONG_ANSWER", output: stdout, error: stderr
                        });
                    }
                    console.log(codeResultResponse);
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
        }

    }

    useEffect(() => {
        refresh();
    }, [token]);

    if (language != 'java' && language != 'python') {
        return (
            <div>
                {`${language} is not a valid language`}
            </div>
        )
    }

    return (

        <Grid item xs={6}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                m={1}
                p={1}
                bgcolor="background.paper"
                height={minLines}
            >
                <CodeInputArea
                    language={language}
                    code={code} setCode={setCode}
                    theme={theme} minLines={minLines} maxLines={maxLines}
                />
            </Box>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        m={1}
                        p={1}
                        bgcolor="background.paper"
                    >
                        <Button variant="contained" color="primary"
                            onClick={submissions}
                        >
                            RUN
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        m={1}
                        p={1}
                        bgcolor="background.paper"
                    >
                        <Button variant="contained" color="primary"
                            onClick={refresh}
                        >
                            REFRESH
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Box
                justifyContent="center"
                alignItems="center"
                m={1}
                p={1}
                bgcolor="background.paper"
            >
                <Collapse in={sentToServer}>
                    Error:
                    <ReadonlyDisplay value={stderr} theme={theme} height={2} mode={language} />
                    Output:
                    <ReadonlyDisplay value={stdout} theme={theme} height={2} mode={language} />
                </Collapse>
            </Box>


        </Grid>
    )
}

export default ScratchPad;