/* eslint-disable no-useless-return */

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactBlockly from 'react-blockly';
import Blockly from 'blockly';
import { makeStyles } from '@material-ui/core/styles';

import BlocklyConfig from './Config';

const useStyles = makeStyles((theme) => ({
  minWidth: {
    minWidth: '600px',
    minHeight: '600px',
  }
}));

const CodeEditor = ({
  width,
  height,
  setProgram, // executable code
  setWorkspace,
  setCodeView, // processed code for view only
  toolCategories,
  toolBlocks,
  initialWorkspace,
  varNames,
  isMute,
}) => {
  const classes = useStyles();
  const [toolboxCategories, setToolboxCategories] = useState(toolCategories);
  const [toolboxBlocks, setToolboxBlocks] = useState(toolBlocks);
  // Whether or not the workspace involves function
  const [functionCallCheckRequired, setFunctionCallCheckRequired] = useState(false);

  useEffect(() => {
    let functionBlockIsPresent = false;
    // Check if function block is present in toolCategories
    // Better syntax?
    for (var i = 0; i < toolCategories.length; ++i) {
      if (toolCategories[i].name === 'Function') {
        if (toolCategories[i].blocks.find((element) => (element.type === 'function'))) {
          functionBlockIsPresent = true;
        }
      }
    }
    // TODO: Check toolBlocks syntax
    console.log(functionBlockIsPresent);
    setFunctionCallCheckRequired(functionBlockIsPresent);
  }, [toolCategories, toolBlocks])

  /*  useEffect(() => {
      console.log('isMute', isMute);
  
      setWorkspaceConfig(
        { ...workSpaceConfig, sounds: !isMute }
      );
  
      console.log(workSpaceConfig);
    }, [isMute]);*/
  // remaining blocks to display above workspace
  const computeBlockRemain = () => {
    if (toolBlocks && toolBlocks.length > 0) {
      return toolBlocks
        .filter((block) => block.limit)
        .map((block) => {
          const newBlock = _.cloneDeep(block);
          newBlock.remain = block.limit;
          return newBlock;
        });
    }
    const remainList = [];
    toolCategories.forEach((toolCat) =>
      toolCat.blocks.forEach((block) => {
        if (block.limit) {
          const newBlock = _.cloneDeep(block);
          newBlock.remain = block.limit;
          remainList.push(newBlock);
        }
      })
    );
    return remainList;
  };

  const [blockRemain, setBlockRemain] = useState(computeBlockRemain());

  let codeView = null;

  // recompute for each block
  const checkBlockRemain = (block, counter) => {
    const remainBlockIndex = blockRemain.findIndex(
      (limBlock) => limBlock.type === block.type
    );
    if (
      remainBlockIndex >= 0 &&
      blockRemain[remainBlockIndex].remain + counter !==
      blockRemain[remainBlockIndex].limit
    ) {
      const newBlockRemain = _.cloneDeep(blockRemain);
      newBlockRemain[remainBlockIndex].remain =
        blockRemain[remainBlockIndex].limit - counter;
      setBlockRemain(newBlockRemain);
    }
  };

  // recompute for all blocks
  const verifyBlockLimit = (workspace) => {
    if (toolboxCategories && toolCategories.length > 0) {
      const newToolbox = _.cloneDeep(toolboxCategories);
      toolboxCategories.forEach((category, i) => {
        category.blocks.forEach((block, j) => {
          // For each category
          let counter = 0;
          // Count number of block used
          workspace.getAllBlocks().forEach((usedBlock) => {
            if (usedBlock.type === block.type) counter += 1;
          });
          checkBlockRemain(block, counter);
          if (block.limit !== undefined) {
            if (counter === block.limit) { //  && !category.blocks[j].shadow)
              // Block is added up to limit
              newToolbox[i].blocks[j].shadow = true;
            } else if (counter < block.limit) { //  && category.blocks[j].shadow
              // Block is removed, under limit now
              // Restore the block by unsetting shadow property
              newToolbox[i].blocks[j].shadow = false;
            } else {
              // Block is over limit, possibly from trash can drop action
              // Remove the earilest block that fits the category
              let blocksToDelete = counter - block.limit;
              let deletedCounter = 0;
              let allBlocks = workspace.getAllBlocks();
              for (var k = 0; k < allBlocks.length && deletedCounter < blocksToDelete; ++k) {
                if (allBlocks[k].type === block.type) {
                  deletedCounter += 1;
                  allBlocks[k].dispose();
                }
              }
              // Now block count should be at limit
              newToolbox[i].blocks[j].shadow = true;
            }
          }
        });
      });
      setToolboxCategories(newToolbox);
    } else {
      toolboxBlocks.forEach((block, i) => {
        let counter = 0;
        workspace.getAllBlocks().forEach((usedBlock) => {
          if (usedBlock.type === block.type) counter += 1;
        });
        checkBlockRemain(block, counter);
        if (
          (counter >= block.limit && !toolboxBlocks[i].shadow) ||
          (counter < block.limit && toolboxBlocks[i].shadow)
        ) {
          const newToolbox = _.cloneDeep(toolboxBlocks);
          newToolbox[i].shadow = !newToolbox[i].shadow;
          setToolboxBlocks(newToolbox);
          return;
        }
      });
    }
  };

  // resolve naming conflicts (functions)
  const checkNaming = (workspace) => {
    const existingNames = [...varNames, 'CURR_ITER', 'MAX_ITER', 'goalType'];
    workspace.getAllBlocks().forEach((usedBlock) => {
      if (usedBlock.type === 'function') {
        let oldFuncName = usedBlock.getFieldValue('NAME');
        let funcName = usedBlock.getFieldValue('NAME');
        if (funcName.length === 0) funcName = 'unnamed';
        if (BlocklyConfig.reservedWords.includes(funcName)) funcName += '2';
        funcName = funcName.replace(BlocklyConfig.specialCharacters, '_');

        if (existingNames.includes(funcName)) {
          let idx = 2;
          if (!Number.isNaN(Number(funcName.charAt(funcName.length - 1)))) {
            idx = parseInt(funcName.charAt(funcName.length - 1), 10);
            funcName = funcName.slice(0, -1);
            oldFuncName = oldFuncName.slice(0, -1);
          }
          while (existingNames.includes(funcName + idx)) idx += 1;
          funcName += idx;
          oldFuncName += idx;
          usedBlock.setFieldValue(oldFuncName, 'NAME');
        }

        existingNames.push(funcName);
      }
    });
  };

  // main functions to execute
  const workspaceDidChange = (workspace) => {
    checkNaming(workspace);
    const workspaceCode = Blockly.JavaScript.workspaceToCode(workspace);
    const actualCode = workspaceCode +
      (functionCallCheckRequired ? 'checkFunctionCalls();\n' : '');
    // After the program runs check function call counts
    // This code should not be visible
    setProgram(actualCode);

    codeView = workspaceCode
      .replace(/.*/, '')
      // .substr(1)
      // .replace(/[\w\W]+?\n+?/, '')
      .replace(/await /g, '') // Remove await from codeview
      .replace(/async /g, '') // Remove async from codeview
      .replace(/functionality./g, '')
      .replace(/.*highlightBlock.*\n/g, '') // Remove default highlight block from codeview
      .replace(/.*functionCounter.*\n/g, '') // Remove function counter helper from codeview
      .replace(/.*logGameState.*\n/g, '') // Remove log utility for infinite loop check from codeview
      .replace(/.*checkForLoopVariables.*\n/g, '') // Remove for loop variable verification
      .replace(/^\s+|\s+$/g, ''); // trim space, including new line at beginning and end

    verifyBlockLimit(workspace);
    setCodeView(codeView);
  };

  useEffect(() => {
    Blockly.WorkspaceAudio.prototype.play = function (name, opt_volume) {
      if (!isMute) {
        console.log('Is making sound?')
        realPlay.call(this, name, 1);
      }
    };
  }, [isMute]);
  const realPlay = Blockly.WorkspaceAudio.prototype.play;

  // set up code editor
  useEffect(() => {
    BlocklyConfig.programSetup(varNames);
    setWorkspace(Blockly.mainWorkspace);
    setToolboxBlocks(toolBlocks);
    setToolboxCategories(toolCategories);
    setBlockRemain(computeBlockRemain());
    Blockly.Flyout.prototype.autoClose = false;
    // setWorkSpaceConfig({ ...workSpaceConfig, sounds: !isMute });
  }, [toolBlocks, toolCategories, isMute]);

  return (
    <div>
      {/* Remaining blocks (if any) */}
      {blockRemain.length > 0 && (
        <h4>
          Blocks remaining:&nbsp;&nbsp;
          {blockRemain.map((block) => (
            <>
              <span>{`${block.remain} `}</span>
              <img src={block.img} alt={block.type} height={30} width={50} />
              &nbsp;
            </>
          ))}
        </h4>
      )}
      {/* Workspace */}
      <div className={classes.minWidth} style={{ height, width, margin: height / 50 }}>
        <ReactBlockly.BlocklyEditor
          toolboxCategories={toolboxCategories}
          toolboxBlocks={toolboxBlocks}
          WorkspaceAudio={{
            play: realPlay,
          }}
          workspaceConfiguration={{
            trashcan: true,
            sounds: realPlay,
            grid: {
              spacing: 20,
              length: 3,
              colour: '#ccc',
              snap: true
            }
          }}
          initialXml={initialWorkspace}
          wrapperDivClassName="fill-height"
          workspaceDidChange={workspaceDidChange}
        />
      </div>
    </div>
  );
};

CodeEditor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  setProgram: PropTypes.func.isRequired,
  setWorkspace: PropTypes.func.isRequired,
  setCodeView: PropTypes.func.isRequired,
  toolCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      blocks: PropTypes.arrayOf(PropTypes.object).isRequired
    })
  ),
  toolBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired
    })
  ),
  initialWorkspace: PropTypes.string.isRequired,
  varNames: PropTypes.arrayOf(PropTypes.string),
  isMute: PropTypes.bool,
};

CodeEditor.defaultProps = {
  width: 600,
  height: 500,
  toolCategories: undefined,
  toolBlocks: undefined,
  varNames: []
};

export default CodeEditor;
