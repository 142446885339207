import React from 'react';
import { Grid, Button } from '@material-ui/core';
import styled from '@emotion/styled';

export const CodeButtons = ({ codeCallback, isCodeRunning, codeReset }) => {
    const handleCodeRun = () => codeCallback(true);
    return (
        <ButtonGrid container spacing={3}>
            <GridItem item xs={12}>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleCodeRun()}
                    disabled={isCodeRunning}
                >
                    Run
                </StyledButton>
            </GridItem>
            <GridItem item xs={12}>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => codeReset()}
                >
                    Reset
                </StyledButton>
            </GridItem>
        </ButtonGrid>
    );
};

const ButtonGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GridItem = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        max-width: 15%;
    }
`;

const StyledButton = styled(Button)`
    width: 120px;
    text-align: center;
    @media (max-width: 768px) {
        width: 100px;
    }
`;
