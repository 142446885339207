import React, { useEffect, useState, useRef } from 'react';
import { Line, Arrow, Image, Group } from 'react-konva';
import { NumberUrls } from '../utils/utils';

const Gridlines = ({ scale, width, height, axisOffset, color }) => {
    /**
     * Numbers URLs. Needed because React Konva does not like text element.
     * Absolute position is obviously out of the question
     */
    const [numberImgs, setNumberImgs] = useState([]);
    const [grid, setGrid] = useState([]);
    const gridLineRef = useRef();
    //const strokeColor = '#080808';
    const strokeWidth = 0.5;
    const axisWidth = 4.0;

    // Initialize number images
    useEffect(() => {
        const newNumberImgs = [];
        NumberUrls.forEach((numUrl) => {
            const img5 = new window.Image();
            img5.src = numUrl;
            newNumberImgs.push(img5);
        });
        setNumberImgs(newNumberImgs);
    }, []);

    useEffect(() => {
        // Array of vertical and horizontal gridlines
        if (scale && width && height) {
            let verticalGrid = Array.from(Array(width).keys()).map((element) => {
                return { x1: scale * element, y1: 0, x2: scale * element, y2: scale * height };
            });
            let horizontalGrid = Array.from(Array(height).keys()).map((element) => {
                return { x1: 0, y1: scale * element, x2: scale * width, y2: scale * element };
            });
            setGrid(verticalGrid.concat(horizontalGrid));
        }
    }, [scale, width, height]);

    return (
        <Group ref={gridLineRef}>
            {grid.map((line) => {
                return (
                    <Line
                        //offsetX={-axisOffset} offsetY={-axisOffset}
                        points={[
                            line.x1 + axisOffset, line.y1 + axisOffset,
                            line.x2 + axisOffset, line.y2 + axisOffset
                        ]}
                        stroke={color}
                        strokeWidth={strokeWidth}
                    />
                );
            })}
            {/* Horizontal axis*/}
            <Arrow
                //offsetX={-axisOffset} offsetY={-axisOffset}
                points={[axisOffset, axisOffset, axisOffset + scale * width, axisOffset]}
                stroke={'black'}
                strokeWidth={axisWidth}
                fill={'black'}
                pointerAtEnding={true}
                pointerLength={8 * axisWidth}
                pointerWidth={2 * axisWidth}
            />
            {/* Horizontal axis labeling */}
            {
                Array.from(Array(width).keys()).map((element) => {
                    return (
                        <Image
                            x={element * scale + axisOffset / 2}
                            y={0}
                            image={numberImgs[1 + element]}
                            width={0.75 * axisOffset}
                            height={0.75 * axisOffset}
                        />
                    );
                })
            }
            {/* Vertical axis*/}
            <Arrow
                //offsetX={-axisOffset} offsetY={-axisOffset}
                points={[axisOffset, axisOffset, axisOffset, axisOffset + scale * height]}
                stroke={'black'}
                strokeWidth={axisWidth}
                fill={'black'}
                pointerAtEnding={true}
                pointerLength={8 * axisWidth}
                pointerWidth={2 * axisWidth}
            />
            {/* Vertical axis labeling */}
            {
                Array.from(Array(height).keys()).map((element) => {
                    return (
                        <Image
                            x={0}
                            y={element * scale + axisOffset / 2}
                            image={numberImgs[1 + element]}
                            width={0.75 * axisOffset}
                            height={0.75 * axisOffset}
                        />
                    );
                })
            }
        </Group>
    );
}
export default Gridlines;