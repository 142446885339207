import React from 'react';
import { Grid, Box } from '@mui/material';

const boxStyles = {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #2196f3',
    borderRadius: '4px',
    fontFamily: "Courier New",
    fontSize: '16px',
    color: '#000000',
    textAlign: 'center',
    wordWrap: 'break-word',
    margin: '2px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
};

export const GridBoard = ({ payload }) => {
    const payloadString = Array.isArray(payload) ? payload[0] : payload;

    const rows = typeof payloadString === 'string'
        ? payloadString.split('\\n').map(row => row.split('~').map(item => item.trim()))
        : [];

    return (
        <Grid container direction="column" spacing={0} style={{ lineHeight: 0 }}>
            {rows.map((row, rowIndex) => (
                <Grid item key={rowIndex}>
                    <Grid container spacing={0} justifyContent="center">
                        {row.map((char, charIndex) => (
                            <Grid item key={charIndex}>
                                <Box sx={boxStyles}>
                                    {char}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};
