import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GeneralText } from '../../common/CommonStyles';

const StoryIntro = ({ content, color }) => {
    let key = 0;

    const renderedExplanation = content.map((line) => (
        <GeneralText key={key++} align="justify" style={{ color: color }}>
            <ReactMarkdown>{line}</ReactMarkdown>
        </GeneralText>
    ));

    return (
        <div>
            {renderedExplanation}
        </div>
    );
};

export default StoryIntro;