import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const gridStyles = {
    display: 'grid',
    gap: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
};

const defaultBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #2196f3',
    borderRadius: '4px',
    fontFamily: 'Courier New',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'center',
    wordWrap: 'break-word',
    padding: '8px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
};

export const FlexGridBoard = ({ payload }) => {
    const payloadString = Array.isArray(payload) ? payload[0] : payload;

    const rows = typeof payloadString === 'string'
        ? payloadString.split('\\n').map(row => row.split('~').map(item => item.trim()))
        : [];

    const [maxContentLength, setMaxContentLength] = useState(0);

    useEffect(() => {
        // Calculate the maximum content length for grid sizing
        const maxLength = rows.flat().reduce((max, cell) => Math.max(max, cell.length), 0);
        setMaxContentLength(maxLength);
    }, [rows]);

    const dynamicGridStyles = {
        ...gridStyles,
        gridTemplateColumns: `repeat(${rows[0]?.length || 1}, minmax(${Math.max(40, maxContentLength * 8)}px, 1fr))`,
        width: '70%',
    };

    return (
        <Box sx={dynamicGridStyles}>
            {rows.flat().map((char, index) => (
                <Box key={index} sx={defaultBoxStyles}>
                    {char}
                </Box>
            ))}
        </Box>
    );
};
