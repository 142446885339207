/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect, useRef, useReducer } from 'react';
import _ from 'lodash';
import { Stage, Layer, Image, Group } from 'react-konva';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CustomButton from '../CustomButton';
import LoadingPage from '../LoadingPage';

import {
  createCollecGoalBlock,
  createIfAtGoalBlock,
  createVariableBlocks,
  createSwitchGoalBlock
} from '../../blocklyMaze/BlockDefinition';
import GIF, { getDirectionGif } from '../Gif';
import Annotation from '../../blocklyMaze/Annotation';
import SpeedSlider from '../SpeedSlider';
//import ShowCode from '../ShowCode';
import {
  NumberUrls,
  sleep,
  pathExists,
  getCharGIF
} from '../utils';
import useWindowDimensions from '../useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '30px',
    paddingBottom: '50px',
    width: 'auto',
    background: 'skyblue',
  },
  paper: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.85),
    paddingRight: theme.spacing(1.85),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    width: '100%',
  },
  orangeBlock: {
    padding: '20px 15px',
    border: 'solid 5px #FFF',
    borderRadius: '20px',
    background: 'radial-gradient(circle,white,#FFE9D2)',
    boxShadow: '0 0 0 5px #BD8337',
    margin: '0px 10px',
    minWidth: '350px',
    width: 'auto',
    overflowY: 'auto',
  },
  minWidth: {
    minWidth: '300px',
  },
  containerMinWidth: {
    minWidth: '350px',
  }
}));

const Maze = ({ content, code, status }) => {
  // Component styling and screen resolution
  const classes = useStyles();
  const { screenWidth } = useWindowDimensions();
  const [SCALE, setSCALE] = useState(60); // Image scale
  useEffect(() => {
    let mazeLength = (content && content.maze) ? content.maze.length : 8;
    let newScale = 10;
    newScale = (screenWidth * 0.8) / (2.25 * mazeLength);
    // if (screenWidth > 1200) {
    //   // Vertical split
    //   newScale = (screenWidth * 0.8) / (2.25 * mazeLength);
    // } else if (screenWidth > 200) {
    //   newScale = (screenWidth * 0.8) / (mazeLength);
    // } else {
    //   // In case
    //   newScale = 10;
    // }

    setSCALE(Math.floor(newScale));
  }, [screenWidth, content]);

  // Images state
  const [backgroundImg, setBackground] = useState(new window.Image());
  const [goalImgs, setGoalImgs] = useState();
  const [numberImgs, setNumberImgs] = useState(
    NumberUrls.map(() => new window.Image())
  );

  const [blockImg, setBlockImg] = useState(new window.Image());
  const [pathImg, setPathImg] = useState(new window.Image());

  // Game state
  const [charState, setCharState] = useState(); // used to display character gif - left, right, up, down, failure, success
  const [playing, setPlaying] = useState(0); // state 0 for before - 1 for playing - 2 for finished
  const [direction, setDirection] = useState(); // used to display direction gif - left, right, up, down
  const [program, setProgram] = useState(); // Program string
  const [waitTime, setWaitTime] = useState(500); // Time to wait between moves
  const [isMute, setIsMute] = useState(true);
  const [loading, setLoading] = useState(true);

  // List of goal objects to be modifed as user plays the game
  const goalsReducer = (state, action) => {
    switch (action.type) {
      case 'collect':
        return state.map((goal, i) => {
          const newGoal = _.cloneDeep(goal);
          if (i === action.payload) newGoal.num -= 1;
          return newGoal;
        });
      case 'setType':
        return state.map((goal, i) => {
          const newGoal = _.cloneDeep(goal);
          if (i === action.payload.id) newGoal.type = action.payload.type;
          return newGoal;
        });
      case 'setNum':
        return state.map((goal, i) => {
          const newGoal = _.cloneDeep(goal);
          if (i === action.payload.id) newGoal.num = action.payload.num;
          return newGoal;
        });
      case 'remove':
        return [
          ..._.cloneDeep(state).slice(0, action.payload),
          ..._.cloneDeep(state).slice(action.payload + 1)
        ];
      case 'reset':
        return _.cloneDeep(action.payload);
      default:
        return state;
    }
  };
  const [goalState, dispatch] = useReducer(goalsReducer, null);

  // Local variable for move function to access
  let goals = null;
  const path = [];
  let currDirection = direction;

  // Reference to avatar and goal image on canvas
  const avatarRef = useRef();
  const goalRef = useRef([]);

  const gameStateIsEqual = (state1, state2) => {
    // position are similar to [0, 2]
    const positionEqual =
      state1.position[0] == state2.position[0] &&
      state1.position[1] == state2.position[1];
    const idEqual = (state1.id === state2.id);
    // Small calculation optimization
    if (positionEqual === true && idEqual === true) {
      // goals are similar to [{num: 1, type: 0}]
      return state1.goals
        .map((element, index) => element.num === state2.goals[index].num)
        .every(element => element === true);
    } else {
      // positionEqual !== true so should be false
      return false;
    }
  }

  // Put references to goal images in an array
  const addToGoalRefs = (ref) => {
    if (ref && !goalRef.current.includes(ref)) {
      goalRef.current.push(ref);
    }
  };

  // executed during initialization (for advanced level)
  const randomize = () => {
    goals = content.goals.instances.map((goal, i) => {
      const newGoal = _.cloneDeep(goal);

      if (goal.num < 0) {
        // Randomize a goal number - 1 -> 10
        const randomNum = Math.floor(Math.random() * 9) + 1;
        newGoal.num = randomNum;
        dispatch({ type: 'setNum', payload: { id: i, num: randomNum } });
      }

      // Randomize a goal type
      const goalType = content.goals.types[goal.type];
      if (goalType.name === 'unknown') {
        const randomType =
          goalType.range[Math.floor(Math.random() * goalType.range.length)];

        // There might be no goal at that position
        if (randomType >= 0) {
          newGoal.type = randomType;
          dispatch({ type: 'setType', payload: { id: i, type: randomType } });
          const newGoalImgs = _.cloneDeep(goalImgs);
          newGoalImgs[i].src = content.goals.types[randomType].img;
          setGoalImgs(newGoalImgs);
        } else {
          dispatch({ type: 'setNum', payload: { id: i, num: 0 } });
          newGoal.num = 0;
          newGoal.type = 0;
        }
      }
      return newGoal;
    });
  };

  const resetGame = () => {
    goals = _.cloneDeep(content.goals.instances);
    setDirection(content.character.direction);
    setCharState(content.character.direction);
    dispatch({ type: 'reset', payload: content.goals.instances });

    const newGoalImgs = _.cloneDeep(goalImgs);
    goals.forEach((goal, i) => {
      newGoalImgs[i].src = content.goals.types[goal.type].img;
    });
    setGoalImgs(newGoalImgs);

    // TODO: Maybe teleport to by setting avatarRef.current.attrs.x and avatarRef.current.attrs.y
    avatarRef.current.to({
      x: content.character.location[0] * SCALE,
      y: content.character.location[1] * SCALE,
      onFinish: () => {
        goalRef.current.forEach((ref) => ref.show());
      }
    });
    setPlaying(0);
  };

  const stopGame = () => {
    // Hacky workaround because setState does not work
    // Teleport character out of grid to crash the eval in the play function
    // Which leads to a failed game state

    // Maybe look at this, seems to suit JS better
    // Can inject code in move and collectGoal JS block
    // https://ckeditor.com/blog/Aborting-a-signal-how-to-cancel-an-asynchronous-task-in-JavaScript/?fbclid=IwAR0B-cJfwaT00CATnaQdk6enZ3KAesvB-4DkJ8AipJHTXAVZU6b1Gm7MXxY
    avatarRef.current.attrs.x = -SCALE;
    avatarRef.current.attrs.y = -SCALE;
  };

  const atGoal = (goalType) => {
    return goals.findIndex(
      (goal) =>
        avatarRef.current.attrs.x === goal.location[0] * SCALE &&
        avatarRef.current.attrs.y === goal.location[1] * SCALE &&
        (goalType === content.goals.types[goal.type].name ||
          content.autoCollect) &&
        goal.num > 0
    );
  };

  const collectGoal = async (goalType) => {
    console.log('Maze Goals: ');
    console.log(goals);
    const reachedGoalIndex = atGoal(goalType);
    if (reachedGoalIndex !== -1) {
      console.log(goals);
      goals[reachedGoalIndex].num -= 1;
      dispatch({ type: 'collect', payload: reachedGoalIndex });
      await sleep((waitTime * 2) / 5);
    }
    console.log(goals);
    // Throw an error when player manually picks up goals when there is no goal
    if (reachedGoalIndex === -1 && !content.autoCollect) {
      throw new Error('Collected goal when there is no goal.');
    }
  };

  const verifyMove = async () => {
    if (
      !path.find(
        (loc) =>
          avatarRef.current.attrs.x === loc[0] &&
          avatarRef.current.attrs.y === loc[1]
      )
    ) {
      return false;
    }
    if (content.autoCollect) await collectGoal();
    return true;
  };

  const move = async (deltaX, deltaY, direct) => {
    setDirection(direct);
    setCharState(direct);
    currDirection = direct;
    await new Promise((resolve, reject) => {
      avatarRef.current.to({
        x: avatarRef.current.attrs.x + deltaX,
        y: avatarRef.current.attrs.y + deltaY,
        duration: (waitTime * 4) / 5000,
        onFinish: async () => {
          if (await verifyMove()) resolve();
          else reject(new Error("Character is not following path."));
        }
      });
    });
    await sleep(waitTime);
  };

  // game functionalities
  const moveEast = async () => {
    await move(SCALE, 0, 'right');
  };

  const moveWest = async () => {
    await move(-SCALE, 0, 'left');
  };

  const moveNorth = async () => {
    await move(0, -SCALE, 'up');
  };

  const moveSouth = async () => {
    await move(0, SCALE, 'down');
  };

  const moveForward = async () => {
    if (currDirection === 'right') await moveEast();
    else if (currDirection === 'left') await moveWest();
    else if (currDirection === 'up') await moveNorth();
    else await moveSouth();
  };

  const moveBackward = async () => {
    if (currDirection === 'right') await move(-SCALE, 0, currDirection);
    else if (currDirection === 'left') await move(SCALE, 0, currDirection);
    else if (currDirection === 'up') await move(0, SCALE, currDirection);
    else await move(0, -SCALE, currDirection);
  };

  const turnLeft = async () => {
    let nextDirection;
    if (currDirection === 'right') nextDirection = 'up';
    else if (currDirection === 'left') nextDirection = 'down';
    else if (currDirection === 'up') nextDirection = 'left';
    else nextDirection = 'right';
    setDirection(nextDirection);
    currDirection = nextDirection;
    await sleep((waitTime * 2) / 5);
  };

  const turnRight = async () => {
    let nextDirection;
    if (currDirection === 'right') nextDirection = 'down';
    else if (currDirection === 'left') nextDirection = 'up';
    else if (currDirection === 'up') nextDirection = 'right';
    else nextDirection = 'left';
    setDirection(nextDirection);
    currDirection = nextDirection;
    await sleep((waitTime * 2) / 5);
  };

  const getGoalTypeAtCurrPos = async () => {
    const goalIndex = goals.findIndex(
      (goal) =>
        avatarRef.current.attrs.x === goal.location[0] * SCALE &&
        avatarRef.current.attrs.y === goal.location[1] * SCALE &&
        goal.num > 0
    );

    if (goalIndex === -1) return -1;
    return content.goals.types[goals[goalIndex].type].name;
  };

  const isPath = (testDir) => {
    return pathExists(
      currDirection,
      testDir,
      avatarRef.current.attrs.x,
      avatarRef.current.attrs.y,
      path,
      SCALE
    );
  };
  /* eslint-enable no-unused-vars */

  const checkResult = () => {
    let goalNum = 0;
    goals.forEach((goal) => {
      goalNum += goal.num;
    });

    if (goalNum === 0) {
      setCharState('success');

      content.completeExercise();
    } else {
      // Have not collected all the goals
      setCharState('failure');
    }
  };

  const play = async () => {
    // Maybe still need to reset goal number
    goals = _.cloneDeep(content.goals.instances);
    dispatch({ type: 'reset', payload: content.goals.instances });

    setPlaying(1);
    randomize();
    try {
      const execution = `(async () => { ${program} })();`;
      await eval(execution);
    } catch (e) {
      console.log(e);
      setCharState('failure');
      setPlaying(2);
      return;
    }
    checkResult();
    setPlaying(2);
  };

  // Only run the following once when data is first fetched
  useEffect(() => {
    if (content) {
      // Create custom blocks based on data
      if (!content.autoCollect) {
        content.goals.types.forEach((type) => {
          if (type.name !== 'unknown')
            createCollecGoalBlock(type.img, type.name, type.action);
        });
        createIfAtGoalBlock(content.goals.types);
        createSwitchGoalBlock(content.goals.types);
      }
      if (content.variables) {
        createVariableBlocks(content.variables);
      }

      // Set up initial game state
      setDirection(content.character.direction);
      setCharState(content.character.direction);
      dispatch({ type: 'reset', payload: content.goals.instances });

      const newGoalImgs = content.goals.instances.map((goal) => {
        const goalImage = new window.Image();
        goalImage.src = content.goals.types[goal.type].img;
        return goalImage;
      });
      setGoalImgs(newGoalImgs);

      if (avatarRef.current)
        avatarRef.current.to({
          x: content.character.location[0] * SCALE,
          y: content.character.location[1] * SCALE,
          onFinish: () => {
            goalRef.current.forEach((ref) => ref.show());
          }
        });

      setPlaying(0);
      setLoading(false);
    }

    return () => { };
  }, [content]);

  // Hide collected goals
  useEffect(() => {
    if (goalState) {
      goalState.forEach((goal, index) => {
        if (goal.num == 0) goalRef.current[index].hide();
      });
    }
  }, [goalState]);

  // Initialize images
  useEffect(() => {
    if (content) {
      const img1 = new window.Image();
      img1.src = content.background;
      setBackground(img1);

      const newGoalImgs = [];
      content.goals.instances.forEach((goal) => {
        const img2 = new window.Image();
        img2.src = content.goals.types[goal.type].img;
        newGoalImgs.push(img2);
      });
      setGoalImgs(newGoalImgs);

      const img3 = new window.Image();
      img3.src = content.obstacle.img;
      setBlockImg(img3);

      const img4 = new window.Image();
      img4.src = content.path.img;
      setPathImg(img4);

      const newNumberImgs = [];
      NumberUrls.forEach((numUrl) => {
        const img5 = new window.Image();
        img5.src = numUrl;
        newNumberImgs.push(img5);
      });
      setNumberImgs(newNumberImgs);
    }
  }, [content]);

  // Reset the Maze Animation
  useEffect(() => {
    if (status == 'Grading') {
      resetGame();
    }
  }, [status]);

  // Setup the Maze Animation
  useEffect(() => {
    if (code) {
      const codeArray = code.split('\n');
    
      let program = '';
      codeArray.forEach((action) => {
        switch (action) {
          case 'F':
            program += 'await moveForward();\n';
            break;
          case 'B':
            program += 'await moveBackward();\n';
            break;
          case 'R':
            program += 'await turnRight();\n';
            break;
          case 'L':
            program += 'await turnLeft();\n';
            break;
          case 'G':
            program += 'await collectGoal();\n';
            break;
          default:
            break;
        }
      });

      setProgram(program);
    }
  }, [code]);

  // Play the Maze Animation
  useEffect(() => {
    if (program) {
      play();
    }
  }, [program]);

  const renderGameAreaComponent = () => {
    return (
      <Box
        className={classes.containerMinWidth}
        display="flex"
        justifyContent="center"
        alignItems="center"
        m={0}
        p={0}
        bgcolor="background.paper"
      >
        <Paper className={classes.paper}>
          <Box
            className={classes.minWidth}
            display="flex"
            justifyContent="center"
            alignItems="center"
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            <Stage
              width={content.maze.length * SCALE}
              height={content.maze.length * SCALE}
            >
              <Layer>
                {/* Background Image */}
                <Image
                  x={0}
                  y={0}
                  image={backgroundImg}
                  width={content.maze.length * SCALE}
                  height={content.maze.length * SCALE}
                />
                {/* Path and Block images (if any) */}
                {content.maze.map((row, i) => {
                  return row.map((num, j) => {
                    if (num === 0)
                      return (
                        <>
                          {content.obstacle.img && (
                            <Image
                              key={`${j}${i}`}
                              x={j * SCALE}
                              y={i * SCALE}
                              width={SCALE}
                              height={SCALE}
                              image={blockImg}
                            />
                          )}
                        </>
                      );
                    path.push([j * SCALE, i * SCALE]);
                    if (content.path.img)
                      return (
                        <Image
                          key={`${j}${i}`}
                          x={j * SCALE}
                          y={i * SCALE}
                          height={SCALE}
                          width={SCALE}
                          image={pathImg}
                        />
                      );
                    return null;
                  });
                })}
                {/* Goal Images */}
                {content.goals.instances.map((goal, i) => (
                  <Group
                    key={`${goal.location[0]}${goal.location[1]}`}
                    x={goal.location[0] * SCALE}
                    y={goal.location[1] * SCALE}
                    ref={addToGoalRefs}
                  >
                    <Image image={goalImgs[i]} width={SCALE} height={SCALE} />
                    <Image
                      image={
                        goal.num >= 0 ? numberImgs[goal.num] : numberImgs[0]
                      }
                      width={SCALE / 3}
                      height={SCALE / 3}
                      x={(2 * SCALE) / 3}
                      y={(2 * SCALE) / 3}
                    />
                  </Group>
                ))}
                {/* Character Image (with direction) */}
                <Group
                  ref={avatarRef}
                  x={content.character.location[0] * SCALE}
                  y={content.character.location[1] * SCALE}
                >
                  <GIF
                    src={getCharGIF(charState, content.character)}
                    width={SCALE}
                    height={SCALE}
                  />
                  <GIF
                    src={getDirectionGif(direction)}
                    width={SCALE / 4}
                    height={SCALE / 4}
                  />
                </Group>
              </Layer>
            </Stage>
          </Box>
          {/* Speed slider, play button, Code view */}
          <SpeedSlider setWaitTime={setWaitTime} />
          {playing === 0 && (
            <CustomButton
              onClick={async () => {
                try {
                  await play(); // TODO: FIX
                } catch (e) {

                }
              }}
              content='Play'
              icon={<PlayArrowIcon />}
            />
          )}
          {playing === 1 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={stopGame} // () => window.location.reload(false)
            >
              STOP
            </Button>
          )}
          {playing === 2 && (
            <CustomButton
              onClick={resetGame}
              content='Reset'
              icon={<ReplayIcon />}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsMute(isMute => !isMute)}
            style={{ marginLeft: 15 }}
          >
            {isMute ? 'UNMUTE' : 'MUTE'}
          </Button>
          {/* <ShowCode code={codeView} width={content.maze.length * SCALE} /> */}
        </Paper>
      </Box>
    )
  };

  if (loading) return <LoadingPage />

  return (
      <div className={classes.orangeBlock}>
        {/* Annotation */}
        <Box className={classes.minWidth} display="flex" m={2.5} p={0.5} bgcolor="background.paper">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Annotation
                character={content.character}
                goals={content.goals.types}
              />
            </Grid>
          </Grid>
        </Box>

        {renderGameAreaComponent()}

      </div>
  );
};

export default Maze;
