import React from 'react';
import styled from '@emotion/styled';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { GENERAL_FONT_SIZE, GENERAL_FONT_SIZE_M, GENERAL_FONT_SIZE_T } from '../../common/CommonStyles';

export const TableContent = ({ payload }) => {
    const tableData = payload?.data;
    const tableHeaders = payload?.headers;

    return (
        <TableContainer component={Paper}>
            <CustomTable aria-label="lesson table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => (
                            <CustomTableCell key={index}>{header}</CustomTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <CustomTableCell
                                    key={cellIndex}
                                    dangerouslySetInnerHTML={{ __html: cell }}
                                ></CustomTableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        </TableContainer>
    );
};

const CustomTable = styled(Table)`
    border-collapse: separate;
    border-spacing: 0;
    font-size: ${GENERAL_FONT_SIZE_M};
    @media (min-width: 600px) and (max-width:1024px) {
        font-size: ${GENERAL_FONT_SIZE_T};  
    }
    @media (min-width: 1024px){
        font-size: ${GENERAL_FONT_SIZE};  
    }
`;

const CustomTableCell = styled(TableCell)`
    border-right: 1px solid #ccc;
    padding: 8px;
    font-size: ${GENERAL_FONT_SIZE_M};
    @media (min-width: 600px) and (max-width:1024px) {
        font-size: ${GENERAL_FONT_SIZE_T};  
    }
    @media (min-width: 1024px){
        font-size: ${GENERAL_FONT_SIZE};  
    }
`;