/* eslint-disable */

import Blockly from 'blockly';

// Highlight block as it runs
Blockly.JavaScript.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
Blockly.JavaScript.addReservedWords('highlightBlock');

// Disable blockly workspace context menu
Blockly.WorkspaceSvg.prototype.showContextMenu = undefined;

// Disable blockly block context menu
Blockly.BlockSvg.prototype.showContextMenu = undefined;

// Starting block
Blockly.Blocks['when_run'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('when run')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1595504911/play_n9zica.png',
          15,
          15,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setNextStatement(true, null);
    this.setColour(45);
    this.setTooltip('');
    this.setHelpUrl('');
    this.contextMenu = false;
  }
};

const programSetup = (varNames) => {
  // Define the code mapped to the start block
  Blockly.JavaScript['when_run'] = function (block) {
    var code = 'var MAX_ITER = 30;\nvar CURR_ITER = 0;\n';
    if (varNames && varNames.length > 0) {
      varNames.forEach((varName) => (code += 'var ' + varName + ' = 0;\n'));
    }
    return code + '\n';
  };

  // Disable blocks not connected to start block
  Blockly.mainWorkspace.addChangeListener(Blockly.Events.disableOrphans);
};

const reservedWords = [
  'break',
  'case',
  'catch',
  'class',
  'const',
  'continue',
  'debugger',
  'default',
  'delete',
  'do',
  'else',
  'export',
  'extends',
  'finally',
  'for',
  'function',
  'if',
  'import',
  'in',
  'instanceof',
  'new',
  'return',
  'super',
  'switch',
  'this',
  'thow',
  'try',
  'typeof',
  'var',
  'void',
  'while',
  'with',
  'yield',
  'enum'
];

const specialCharacters = /[^A-Z0-9]/gi;

const BlocklyConfig = {
  reservedWords,
  specialCharacters,
  programSetup
};

export default BlocklyConfig;
