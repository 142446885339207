/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import axios from 'axios';

import './App.css';
import SignIn from './SignIn';
import Dashboard from './Dashboard';
import Direct from './Direct';

// Hmm
import BlocklyMaze from './blocklyMaze/BlocklyMaze';
import Artist from './artist/Artist';
import Pattern from './patterns/Pattern';
import JavaArray from './java-array/java-array';
import BlocklyMazeSolution from './blocklyMaze/BlocklyMazeSolution'

import NavBar from './NavBar';
import PrivateRoute from './PrivateRoute';
import LoadingPage from './utils/LoadingPage';
import { signOut, signIn, setExercises } from '../actions';
import { checkUserCookies, getUserCookies } from './utils/utils';
import MultiChoice from './multichoice/MultiChoice';
import Exercise from './utils/Exercise';
import Lesson from './lesson/Lesson';
import DragDrop from './utils/solution-input-types/dragdrop/DragDrop'

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: 'center',
    boxShadow: 'none',
    width: 'auto',
    //color: theme.palette.text.secondary
  }
});

const App = (props) => {
  const { classes, authed } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (checkUserCookies()) {
      axios
        .post(`${process.env.REACT_APP_LMS_API}/validateUser`, null, {
          headers: { Authorization: `Token ${getUserCookies()}` }
        })
        .then((response) => {
          if (response.data.success === 'True') {
            // LMS sends an exercise list
            props.setExercises('artist', response.data.exercise_list);
            props.signIn();
          } else {
            props.signOut();
          }
          setLoading(false);
        });
    } else {
      props.signOut();
      setLoading(false);
    }
  }, []);

  console.log('props', props);

  if (loading) return <LoadingPage />;
  return (
    <BrowserRouter>
      <div className={classes.root}>
        <NavBar />
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Switch>
                <Route
                  path="/signin"
                  component={(propsChild) => <SignIn {...propsChild} />}
                />
                <Route
                  path="/direct"
                  component={(propsChild) => <Direct {...propsChild} />}
                />
                <PrivateRoute
                  path="/maze/solution/:id"
                  component={(propsChild) => <BlocklyMazeSolution {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/maze/:id"
                  component={(propsChild) => <BlocklyMaze {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/pattern/:id"
                  component={(propsChild) => <Pattern {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/artist/:id"
                  component={(propsChild) => <Artist {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/java/:id"
                  component={(propsChild) => <JavaArray {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/example/:id"
                  component={(propsChild) => <MultiChoice {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/exercise/:id"
                  component={(propsChild) => <Exercise {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/lesson/:id"
                  component={(propsChild) => <Lesson {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/dragdrop/:id"
                  component={(propsChild) => <DragDrop {...propsChild} />}
                  authed={authed}
                />
                <PrivateRoute
                  path="/"
                  component={(propsChild) => <Dashboard {...propsChild} />}
                  authed={authed}
                />
              </Switch>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired
  }).isRequired,
  authed: PropTypes.bool.isRequired,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  setExercises: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({ authed: state.auth.isSignedIn });

export default withStyles(styles)(
  connect(mapStateToProps, { signOut, signIn, setExercises })(App)
);
