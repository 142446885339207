import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
      padding: "5px 5px",
      background: "#2196f3",
      width: "100px",
      height: "70%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      position:"relative",    
      cursor:"pointer",
      margin: "5px 5px",
      borderRadius:"15px",
	  color:"#FFFFFF",
      zIndex:"101"
    }
  }));

const Card = (props) => {

    const classes = useStyles();

    const dragStart = (event) => {
        const target = event.target;

        event.dataTransfer.setData("card_id", target.id);

        event.dataTransfer.setData("value", props.value);
    }

    const dragOver = (event) => {
        event.stopPropagation();

    }

    return (
        <div
            id={props.id}
            className={classes.card}
            draggable={props.draggable}
            onDragStart={dragStart}
            onDragOver={dragOver}
            value={props.value}
        >
            { props.children }
        </div>
    )
}

export default Card;