/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, authed, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authed ? <Component {...props} /> : <Redirect to="/signin" />
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authed: PropTypes.bool.isRequired
};

export default PrivateRoute;
