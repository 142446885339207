import React from 'react';

export default function Logo() {
  return (
    <div style={{height: '100%', margin: 'auto'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="157.81" height="43.445" viewBox="0 0 157.81 43.445">
        <g id="Group_432" data-name="Group 432" transform="translate(-24.574 -22.777)">
          <g id="Group_424" data-name="Group 424" transform="translate(-18 -880)">
            <path id="Path_7032" data-name="Path 7032" d="M117.166,913.077V902.814h-7.333v1.522q0,10.681,0,21.363a14.519,14.519,0,0,0,9.36,13.779,14.591,14.591,0,1,0-.334-27.1C118.355,912.593,117.846,912.8,117.166,913.077Zm15.14,12.8a7.581,7.581,0,1,1-7.511-7.595A7.559,7.559,0,0,1,132.306,925.878Z" fill="#393939" fill-rule="evenodd"/>
            <path id="Path_7033" data-name="Path 7033" d="M65,938.273a5.068,5.068,0,0,0,.132,1.367c.126.3.481.717.746.726,1.9.063,3.806.034,5.769.034a7.788,7.788,0,0,0,.153-1.046c.008-4.606.071-9.213-.01-13.819A14.594,14.594,0,1,0,63.4,939.053C63.894,938.823,64.381,938.575,65,938.273Zm.087-12.4a7.582,7.582,0,1,1-7.518-7.591A7.552,7.552,0,0,1,65.088,925.874Z" fill="#393939" fillRule="evenodd"/>
            <path id="Path_7034" data-name="Path 7034" d="M105.276,912.02H98.76c0,1.495,0,2.961,0,4.428-.009,3.168.033,6.337-.049,9.505a7.486,7.486,0,0,1-5.576,7.2,7.335,7.335,0,0,1-8.446-3.384,9.631,9.631,0,0,1-1.118-4.236c-.149-3.955-.052-7.918-.053-11.88v-1.6h-7.2a8.6,8.6,0,0,0-.121,1.032c-.007,4.286-.047,8.573.007,12.859a14.6,14.6,0,0,0,10.683,13.973,14.438,14.438,0,0,0,16.551-6.853,12.373,12.373,0,0,0,1.757-5.236C105.426,922.626,105.276,917.41,105.276,912.02Z" fill="#393939" fillRule="evenodd"/>
            <path id="Path_7035" data-name="Path 7035" d="M200.384,911.28h-7.346v-8.5h-7.212c-.039.434-.1.793-.1,1.153-.005,7.407-.044,14.816.008,22.223a14.394,14.394,0,0,0,13.617,14.2c.274.01.55,0,.927,0V933.55c-.337-.075-.685-.172-1.04-.228a6.84,6.84,0,0,1-6.044-5.924c-.289-2.942-.062-5.936-.062-9.119,1.591,0,3.163-.041,4.734.015.862.03,1.256-.26,1.4-1.131C199.569,915.287,199.969,913.428,200.384,911.28Z" fill="#393939" fillRule="evenodd"/>
            <path id="Path_7036" data-name="Path 7036" d="M181.429,925.727c-.115-2.555-.075-5.126-.387-7.658a7.431,7.431,0,0,0-3.761-5.472c-3.55-2.148-7.123-4.267-10.778-6.228a8.419,8.419,0,0,0-8.331.1c-3.356,1.829-6.63,3.808-9.944,5.715a8.424,8.424,0,0,0-4.6,7.22c-.152,4.415-.125,8.847.08,13.259a7.876,7.876,0,0,0,4.127,6.466c3.4,2.043,6.822,4.042,10.3,5.944a8.72,8.72,0,0,0,8.835-.1c3.181-1.764,6.3-3.631,9.458-5.443a9.059,9.059,0,0,0,4.9-8.625c-.04-1.723-.007-3.447-.007-5.173Zm-2.664.115c0,1.907.053,3.817-.014,5.723a6.211,6.211,0,0,1-3.188,5.4c-3.207,1.894-6.454,3.723-9.674,5.6a6.377,6.377,0,0,1-6.883,0c-3.217-1.878-6.468-3.7-9.678-5.591a6.25,6.25,0,0,1-3.223-5.537q-.1-5.651,0-11.306a6.291,6.291,0,0,1,3.2-5.554c3.084-1.83,6.236-3.545,9.311-5.39a6.851,6.851,0,0,1,7.635-.012c3.074,1.845,6.22,3.571,9.308,5.4a6.239,6.239,0,0,1,3.191,5.4C178.825,921.931,178.766,923.888,178.765,925.842Z" fill="#393939" fillRule="evenodd"/>
            <path id="Path_7037" data-name="Path 7037" d="M177.866,921.514c.025-1.911-.922-3.338-2.215-3.334s-2.216,1.408-2.182,3.348c.033,1.894.935,3.2,2.2,3.2C176.914,924.727,177.841,923.368,177.866,921.514Z" fill="#393939" fill-rule="evenodd"/>
            <path id="Path_7038" data-name="Path 7038" d="M170.278,925.065c-.019-1.951-.871-3.217-2.169-3.222-1.268-.005-2.172,1.325-2.188,3.222-.017,1.923.824,3.216,2.118,3.258C169.426,928.368,170.3,927.1,170.278,925.065Z" fill="#393939" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
    </div>
  );
}
