/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { setCurrentExercise, completeExercise, setAttempt } from '../../actions';
import LoadingPage from '../utils/LoadingPage';
import ReadonlyDisplay from '../utils/ReadonlyDisplay';
import ContentDisplay from '../utils/content-types/ContentDisplay';
import { getUserCookies } from '../utils/utils';
import { Typography, Paper, Grid, Box, Button, Snackbar } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import styled from '@emotion/styled';
import { HEADER_FONT_SIZE, SUBHEADER_FONT_SIZE } from '../common/CommonStyles';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    width: "100%",
    alignItems: "left",
    justifyContent: "center",
    display: "flex",
    padding: "40px 0px!important"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  question: {
    padding: theme.spacing(2),
    // color: theme.palette.text.secondary,
    height: "100%",
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    },
  },
  instructionActive: {
    justifyItems: "center",
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    margin: "20px 15px",
    opacity: "100%",
    padding: "1% 2%"
    // color: theme.palette.text.secondary

  },
  instructionInactive: {
    justifyItems: "center",
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    margin: "10px 15px",
    opacity: "70%",
    padding: "1% 2%"
    //color: theme.palette.text.secondary
  },
  buttonGrid: {
    display: "flex",
    alignItems: "center",
  },
  snackbar: {
    marginTop: "50px",
  },
  stepper: {
      backgroundColor: "#deeff5",
      padding: "0px"
  },
  stepperContainer: {
    backgroundColor: "#deeff5",
    border: "3px solid #c9e5ee",
    borderRadius: "10px",
    padding: "15px",
    display: "flex",
    alignItems: "center"
  }
}));


const Lesson = (props) => {
  var {
    match: {
      params: { id: exerciseId }
    },
    exercise, // exercise data
    exerciseIds, // exercise id list used for link to next exercise
    history // Routing variable
  } = props;
  const [loading, setLoading] = useState(true);
  // Component styling and screen resolution
  const classes = useStyles();
  // If styling requires screen width and height use these
  const [nextExercise, setnextExercise] = useState();
  const [instructionNumber, setInstructionNumber] = useState(2);
  const [step, setStep] = useState(0);
  const activeLessonRef = useRef(null);
  const [stepperInstructions, setStepperInstructions] = useState([]);
  const [stepperPage, setStepperPage] = useState(0);

  // Constantly monitor screen size
  useEffect(() => {
    const handleResize = () => {
      if (exercise && exercise.exerciseType === 'lesson') {
        setStepperInstructions(stepperList(exercise.instructions));
      }
    };
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, [exercise]);

  useEffect(() => {
    if (exercise && exercise.exerciseType === 'lesson') {
      setStepperInstructions(stepperList(exercise.instructions));
      setStep(0); // Reset the step to 0 after the lesson is fully loaded
      setInstructionNumber(2); // Reset the instruction number
    }
  }, [exercise]);

  useEffect(() => {
    // Send notification to backend upon loading the lesson
    const requestData = { exerciseCode: exerciseId, exerciseType: "Lesson" };
    axios
      .post(`${process.env.REACT_APP_LMS_API}/trackTime`, requestData, {
        headers: { Authorization: `Token ${getUserCookies()}` },
      });
  }, [exercise]);

  useEffect(() => {
    if (exercise && stepperInstructions.length > 0) {
      setStepperPage(0);  // Reset to the first page when a new lesson is loaded
    }
  }, [stepperInstructions]);

  // Get exercise data from database
  useEffect(() => {
    setLoading(true);

    // This part is only for when exercises are not in the database
    // When exercises are put on the database we will need to use axios to fetch exercise data instead
    
    // let indicatedExercise = exerciseData.find((elem) => elem._id === exerciseId)
    // if (indicatedExercise !== undefined) {
    //   props.setCurrentExercise(indicatedExercise);
    //   setLoading(false);
    // } else {
    //   props.enqueueSnackbar('Failed to fetch exercise', {
    //     variant: 'error' 
    //   });
    //   history.push('/');
    // }
    

    // Fetching exercise from database will look like this
    axios
      .get(`${process.env.REACT_APP_EXE_API}/lesson/${exerciseId}`) // Change url for exercise type
      .then((response) => {
        props.setCurrentExercise(response.data);
        setLoading(false);
      })
      .catch(() => {
        props.enqueueSnackbar('Failed to fetch exercise', {
          variant: 'error'
        });
        history.push('/');
      });
  }, [exerciseId]);

  // Only run the following once when data is first fetched
  useEffect(() => {
    if (exercise && exercise.exerciseType === 'class') {
      console.log(exercise);
    }

    return () => { };
  }, [exercise]);

  //Stepper set up
  useEffect(() => {
      if (exercise && exercise.exerciseType === 'lesson') {
        setStepperInstructions(stepperList(exercise.instructions));
      }
  }, [exercise]);

  //Stepper active page
  useEffect(() => {
    if (stepperInstructions.length > 0) {
      let i = 0;
      while (!stepperInstructions[i].some(instruction => (instruction['line'] - 1) === step) && i < stepperInstructions.length - 1) {
        i = i + 1;
      }
      setStepperPage(i);
    }
  }, [step]);

  useEffect(() => {
    if (exercise && exerciseIds && exerciseIds.length > 0) {
      // find the current exercise index in exercise id list in redux store
      const currentIndex = exerciseIds.findIndex(
        (elem) => elem.id === exercise._id
      );

      // if found current exercise and index is within bounds
      // set next exercise by incrementing the current index, otherwise set to 0
      if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
        const targetId = exercise._id;
        const targetType = 'lesson';

        // Find the index of the target item
        const currentIndex = exerciseIds.findIndex(
          (elem) => elem.id === targetId && elem.type === targetType
        );

        // If the current item is found and it's not the last item in the array
        if (currentIndex !== -1 && currentIndex < exerciseIds.length - 1) {
          // Get the next item in the array
          const nextExercise = exerciseIds[currentIndex + 1];
          const type = nextExercise.type === 'python' ? 'exercise' : (nextExercise.type === 'lesson' ? 'lesson' : '');
          // Set the next exercise
          setnextExercise({
            id: nextExercise.id,
            type: type
          });
        } else {
          // Handle the case where no match is found or it's the last item
          console.log('No next exercise found');
          setnextExercise(null);
        }
      } else if (currentIndex === exerciseIds.length - 1) {
        setnextExercise(null);
      } else {
        // exerciseIds.length > 0
        setnextExercise(
          {
            id: exerciseIds[0].id,
            type: exerciseIds[0].type
          }
        );
      }
    } else {
      setnextExercise(null);
    }
  }, [exercise, exerciseIds]);

  useLayoutEffect(() => {
    if (instructionNumber && activeLessonRef.current) {
      activeLessonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [instructionNumber]);


  const display = (element) => {
    if (instructionNumber <= element.line) {
      return;
    }
    if (instructionNumber === element.line + 1) {
      return (
        <Paper
          key={element.line}
          className={classes.instructionActive}
          ref={activeLessonRef}
        >
          <br />
          <ContentDisplay content={element.content} />
          <br />
        </Paper>
      )
    } else {
      return (
        <Paper
          key={element.line}
          className={classes.instructionInactive}
        >
          <br />
          <ContentDisplay content={element.content} />
          <br />
        </Paper>
      )
    }
  }

  const displayCode = (element) => {
    if (element.code === "") {
      return;
    } else {
      return (
        <ReadonlyDisplay
          value={element.code}
          theme={'github'}
        />
      );
    }
  }

  const next = () => {
    if (instructionNumber < exercise.instructions.length + 1) {
      setInstructionNumber(instructionNumber + 1);
      setStep((step) => step + 1);
    } else {
      // After finishing all lessons, find the next lesson 
      if (exercise && exerciseIds && exerciseIds.length > 0) {

        const currentIndex = exerciseIds.findIndex(
          (elem) => elem.id === exercise._id
        );

        if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
          const nextExercise = exerciseIds[currentIndex + 1];
          const type = nextExercise.type === 'python' ? 'exercise' : (nextExercise.type === 'lesson' ? 'lesson' : '');

          setnextExercise({
            id: nextExercise.id,
            type: type
          });

          const path = `/${type}/${nextExercise.id}`;
          history.push(path);

        } else if (currentIndex === exerciseIds.length - 1) {
          // If the current lesson is the last assigned item
          console.log('No next exercise found');
          setnextExercise(null);
          props.enqueueSnackbar('This is the last exercise.', { variant: 'info' });
        } else {
          setnextExercise({
            id: exerciseIds[0].id,
            type: exerciseIds[0].type
          });

          const path = `/${exerciseIds[0].type}/${exerciseIds[0].id}`;
          history.push(path);
        }
      } else {
        // Handle the error if no next exercise found
        setnextExercise(null);
        props.enqueueSnackbar('No exercises available.', { variant: 'error' });
      }
    }
  }

  const back = () => {
    if (instructionNumber > 2) {
      // If the instruction number is greater than 2, go back to the previous instruction
      setInstructionNumber(instructionNumber - 1);
      setStep((step) => step - 1);
    } else {
      // If at the first instruction, navigate to the previous exercise or lesson
      if (exercise && exerciseIds && exerciseIds.length > 0) {
        // Find the current exercise index in the exercise ID list
        const currentIndex = exerciseIds.findIndex(
          (elem) => elem.id === exercise._id
        );

        if (currentIndex > 0) {
          // If not the first exercise, go to the previous exercise
          const prevExercise = exerciseIds[currentIndex - 1];
          const type = prevExercise.type === 'python' ? 'exercise' : (prevExercise.type === 'lesson' ? 'lesson' : '');

          // Set the previous exercise
          setnextExercise({
            id: prevExercise.id,
            type: type
          });

          // Navigate to the previous exercise or lesson
          const path = `/${type}/${prevExercise.id}`;
          history.push(path);
        } else if (currentIndex === 0) {
          // If the current exercise is the first one, handle no previous exercise
          console.log('No previous exercise found');
          setnextExercise(null);
          props.enqueueSnackbar('This is the first exercise.', { variant: 'info' });
        } else {
          // If the current exercise is not found or it's the first in the list, navigate to the first exercise or lesson
          setnextExercise({
            id: exerciseIds[0].id,
            type: exerciseIds[0].type
          });

          const path = `/${exerciseIds[0].type}/${exerciseIds[0].id}`;
          history.push(path);
        }
      } else {
        // Handle case where no previous exercise is available
        setnextExercise(null);
        props.enqueueSnackbar('No exercises available.', { variant: 'error' });
      }
    }
  }

  const backStepperPage = () => {
    if (stepperPage > 0) {
        setStepperPage(stepperPage - 1);
    }
  };

  const nextStepperPage = () => {
      if (stepperPage < stepperInstructions.length -1) {
            setStepperPage(stepperPage + 1);
      }
  };

  // function to change current selected lesson line
  const handleSelect = (selectIndex) => {
    setInstructionNumber(selectIndex + 1);
    setStep(selectIndex - 1);
  }

  const getLanguage = () => {
    if (exercise.language === "java") {
      return (
        <i>Java</i>
      )
    }
  }

  let stepperBackButton, stepperNextButton;
  if (stepperInstructions.length > 1) {
    stepperBackButton =
        <Button size="small" onClick={backStepperPage} disabled={stepperPage === 0} >
            <NavigateBeforeIcon />
        </Button>;

    stepperNextButton = 
        <Button size="small" onClick={nextStepperPage} disabled={stepperPage === (stepperInstructions.length - 1)}>
            <NavigateNextIcon />
        </Button>;
  } else {
    stepperBackButton = <></>;
    stepperNextButton = <></>;
  }

  // Try to use Material UI (currently v4, upgrading to v5 when have the time) 
  // instead of primitive HTML tags
  // https://v4.mui.com
  if (loading) return <LoadingPage />;
  return (
    <div className={classes.root}>
      <Snackbar open={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} className={classes.snackbar}>

          <div className={classes.stepperContainer}>
                {stepperBackButton}
                <Stepper activeStep={step} className={classes.stepper} >
                  {stepperInstructions[stepperPage] && stepperInstructions[stepperPage].map((label) => (
                    <Step key={label?.line} completed={label?.line < instructionNumber} active={label?.line === step} >
                      <StepLabel
                        onClick={() => handleSelect(label.line)}
                        style={{ cursor: "pointer" }} 
                        StepIconProps={{ icon: label?.line}}
                      ></StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {stepperNextButton}
          </div>
      </Snackbar>
      {/* Annotation and Instruction */}
      <Box display="flex" m={1} p={3} bgcolor="background.paper" textAlign="left">
        <Grid container spacing={10} style={{ marginTop: 30, marginBottom: 30 }}>
          <Grid item className={classes.gridItem}>
            <Paper className={classes.question}>
              <Typography style={{ fontWeight: "bold", textAlign: 'center', fontSize: HEADER_FONT_SIZE }}>
                {exercise.language.charAt(0).toUpperCase() + exercise.language.slice(1)}
              </Typography>
              <Typography as="h1" style={{ fontWeight: "bold", textAlign: 'center', fontSize: HEADER_FONT_SIZE }}>
                {exercise.chapter} - {exercise.lesson}
              </Typography>
              {exercise?.sublesson &&
                <Typography as="h2" style={{ fontWeight: "bold", textAlign: 'center', fontSize: SUBHEADER_FONT_SIZE }}>
                  {exercise.sublesson}
                </Typography>
              }
              {
                exercise.instructions.map((element) => {
                  return display(element);
                })
              }
              <Grid container style={{ padding: "0% 3%", rowGap: "10px" }} >
                <BackButtonGrid item xs={12} sm={6}>
                  <Button variant="contained" color="primary"
                    onClick={back}
                  >
                    BACK
                  </Button>
                </BackButtonGrid>
                <NextButtonGrid item xs={12} sm={6} >
                  <Button variant="contained" color="primary"
                    onClick={next}>
                    NEXT
                  </Button>
                </NextButtonGrid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

Lesson.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setCurrentExercise: PropTypes.func.isRequired,
  exercise: PropTypes.object,
  completeExercise: PropTypes.func.isRequired,
  setAttempt: PropTypes.func.isRequired,
  exerciseIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  )
};

Lesson.defaultProps = {
  exercise: null,
  exerciseIds: null
};

const mapStateToProps = (state) => ({
  exercise: state.exercises.current,
  exerciseIds: state.exercises.ids
});

const stepperList = (instructions) => {

    //const breakpoints = [450, 900, 1200];

    let listLength = 6;
    const windowWidth = window.innerWidth;

    if (windowWidth >= 450) {
        listLength = 6;
    }
    if (windowWidth >= 900) {
        listLength = 8;
    }
    if (windowWidth >= 1200) {
        listLength = 12;
    }

    const separatedList = [];
    if (instructions.length < listLength) {
        separatedList.push(instructions);
        return separatedList;
    }
    else {
        //Testing
        //separatedList.push(execiseIds);
        //return separatedList;
        var i = 0;
        while (instructions.length - i > listLength) {
            separatedList.push(instructions.slice(i, i + listLength));
            i = i + listLength;
        }

        separatedList.push(instructions.slice(i, instructions.length));

        return separatedList;
    }
};

export default connect(mapStateToProps, {
  setCurrentExercise,
  completeExercise,
  setAttempt
})(withSnackbar(Lesson));

const BackButtonGrid = styled(Grid)`
  display: flex;
  alignItems: center;
  justify-content: center;
  @media (min-width: 600px){
    justify-content: flex-start;
  }
`

const NextButtonGrid = styled(Grid)`
  display: flex;
  alignItems: center;
  justify-content: center;
  @media (min-width: 600px){
    justify-content: flex-end;
  }
`