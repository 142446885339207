import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import textToSpeechIcon from '../../img/audio_icon2.png'
import { useSpeechSynthesis } from 'react-speech-kit';

import ReplayIcon from '@material-ui/icons/Replay';

import CustomButton from './CustomButton';
import { Box } from '@material-ui/core';
import WarningIcon from './WarningIcon';
import ResetIcon from './ResetIcon';

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    cursor: 'pointer',
    borderRadius: 4,
    overflow: 'hidden',
    height: '100%',
  },
  instructionTab: {
    backgroundColor: '#C4E8FA',
    border: '5px solid #00BDEA',
    borderRadius: '3%',
  },
  storyTab: {
    backgroundColor: '#EAFCCB',
    border: '5px solid #92C92B',
    borderRadius: '3%',
  },
  instruction: {
    background: '#00BDEA',
    borderRadius: '3% 0 0 0',
    height: '100%',
  },
  story: {
    background: '#92C92B',
    borderRadius: '0 3% 0 0',
    height: '100%',
  },
  header: {
    fontSize: '1.25rem',
  },
  infoMessage: {
    color: 'black',
    background: 'white',
    borderRadius: '20px',
    padding: '20px',
    margin: '15px 10px',
    fontSize: '16px',
  },
  errorMessage: {
    color: 'red',
    fontWeight: '1000',
    border: 'solid 3px #F80000',
    boxShadow: '0 0 0 3px #FFBABA',
    background: '#FFE2E2',
    borderRadius: '20px',
    padding: '15px',

    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  textToSpeechIcon: {
    width: 50,
    height: 50,
    '&:hover': {
      cursor: 'pointer'
    }
  },

}));

const Instruction = ({ solveTime, avatar, instruction, story, errMessage, error, resetGame, hints }) => {
  const [isStory, setIsStory] = useState(false);
  const { speak, cancel, speaking } = useSpeechSynthesis();
  const classes = useStyles();

  const [shownInstruction, setShownInstruction] = useState('');
  useEffect(() => {
    let instructionBuffer = isStory ? story : instruction;
    if (typeof hints === 'object' && !isStory) {
      if (typeof hints.angles == 'object') {
        let angles = hints.angles;
        if (angles.initialAngle !== undefined) {
          instructionBuffer += '\nInitial angle of the character is ' + angles.initialAngle + ' degrees.';
        }
        if (angles.left.length > 0 && angles.right.length > 0) {
          instructionBuffer += '\nConsider turning left ' + angles.left.join(', ') + ' degrees and right ' +
            angles.right.join(', ') + ' degree.';
        } else if (angles.left.length > 0 && angles.right.length <= 0) {
          instructionBuffer += '\nConsider turning left ' + angles.left.join(', ') + ' degrees.';
        } else if (angles.left.length <= 0 && angles.right.length > 0) {
          instructionBuffer += '\nConsider turning right ' + angles.right.join(', ') + ' degrees.';
        }
      }
      if (hints.lineWidth.length != 0 || (hints.lineWidth.length == 1 && hints.lineWidth[0] != 1)) {
        instructionBuffer += '\nConsider using ' + hints.lineWidth.join(', ') + ' as line width values';
      }
    }
    setShownInstruction(instructionBuffer);
  }, [hints, isStory]);

  return (
    <Grid className={isStory ? classes.storyTab : classes.instructionTab} container spacing={0}>
      <Grid container className={classes.headingContainer}>
        <Grid xs={6} onClick={() => setIsStory(false)} className={classes.instruction}>
          <h6 className={classes.header}>Instruction</h6>
        </Grid>
        <Grid xs={6} onClick={() => setIsStory(true)} className={classes.story}>
          <h6 className={classes.header}>Story</h6>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <img src={avatar} alt="Character Avatar" width={100} height={100} />
      </Grid>
      <Grid item xs={9}>
        <div className={classes.infoMessage}>
          {shownInstruction}
        </div>
      </Grid>
      <Grid item xs={1}>
        {speaking ? (
          <img
            src={textToSpeechIcon}
            alt="Text to speech button"
            className={classes.textToSpeechIcon}
            style={{}}
            onClick={cancel}
          />
        ) : (
          <img
            src={textToSpeechIcon}
            alt="Text to speech button"
            className={classes.textToSpeechIcon}
            style={{}}
            onClick={() => speak({ text: shownInstruction })}
          />
        )
        }

      </Grid>
      <Grid item xs={2}>
        {error && <WarningIcon />}
      </Grid>
      <Grid item xs={10}>
        {error && (
          <div className={classes.errorMessage}>
            {errMessage}{' '}

            <CustomButton
              content='Reset'
              onClick={resetGame}
              icon={<ResetIcon />}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

Instruction.propTypes = {
  avatar: PropTypes.string.isRequired,
  instruction: PropTypes.string.isRequired,
  errMessage: PropTypes.string,
  error: PropTypes.bool.isRequired
};

Instruction.defaultProps = {
  errMessage: "Keep coding! Something's not quite right yet."
};

export default Instruction;
