import React, { useEffect, useState } from 'react';
import ReadonlyDisplay from '../ReadonlyDisplay';
import { GeneralText } from '../../common/CommonStyles';

const CodeArea = ({ index, title, code, mode, isEmpty, minLines, maxLines, placeholderText, showLineNumbers }) => {
    const [verified, setVerified] = useState(false);
    const [verifiedMessage, setVerifiedMessage] = useState("");

    const verify = () => {
        if (code === undefined) {
            setVerifiedMessage("CodeArea code is undefined");
            setVerified(false);
            return
        }
        else if (!code && !isEmpty) {
            setVerifiedMessage("CodeArea length is 0");
            setVerified(false);
            return
        }
        else if (!mode) {
            setVerifiedMessage("CodeArea Mode is unspecified");
            setVerified(false);
            return
        }
        setVerified(true)
    }

    useEffect(() => {
        verify()
    }, [code, mode])

    if (!verified) {
        return (
            <div>
                {verifiedMessage}
            </div>
        )
    } else {
        return (
            <div>
                {title && (
                    (index === 0) ? <GeneralText style={{ fontWeight: "bold", textAlign: "left", marginBottom: "10px" }}>{title}</GeneralText> :
                    <GeneralText style={{ fontWeight: "bold", textAlign: "left", marginBottom: "10px" }}>{title}</GeneralText>
                )}
                <div style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}>
                    <ReadonlyDisplay value={code} theme={'github'} mode={mode} minLines={minLines} maxLines={maxLines} placeholderText={placeholderText} showLineNumbers={showLineNumbers} />
                </div>
            </div>
        );
    }
};

export default CodeArea;