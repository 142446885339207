import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GeneralText } from '../../common/CommonStyles';

const Instructions = ({ content, payloadType }) => {
    let key = 0;

    const renderedInstructions = content.map((line) => (
        <React.Fragment>
            {payloadType !== 'html' && ( // markdown option
                <GeneralText key={key++} align="left">
                    <ReactMarkdown>{line}</ReactMarkdown>
                </GeneralText>
            )}
            {payloadType === 'html' && (
                <GeneralText key={key++} align="left" dangerouslySetInnerHTML={{ __html: line }} />
            )}
        </React.Fragment >
    ));

    return (
        <div style={{margin:"15px 0px"}}>
            {renderedInstructions}
        </div>
    );
};

export default Instructions;