/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DragDropArrow from "./DragDropArrow";
import { Typography, Paper, Grid, Box } from '@material-ui/core';
import { GeneralText } from '../../../common/CommonStyles';

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    //color: theme.palette.text.secondary,
    height: "850px"
  },
  question: {
    padding: theme.spacing(2),
    //color: theme.palette.text.secondary
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const DragDropText = ({ onSubmission, index, dragDrop }) => {
  const [answers, setAnswers] = useState([]);
  
  function captureArrow(ans) {
    setAnswers(ans);
  }
  
  useEffect(() => {
    if (answers.length > 0) {
      onSubmission(answers, index);
    }
  }, [answers]);
  
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      m={1}
      p={1}
      bgcolor="background.paper"
    >
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <div style={{ padding: "20px", width: "100%" }}>
          <DragDropArrow
            cards={dragDrop.cards} captureArrow={captureArrow}
          />
        </div>
      </Grid>
    </Box>
  );
};

export default DragDropText;
