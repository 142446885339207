import React from 'react'
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-language_tools';
// Supported languages are Python and Java only
const languages = ['python', 'java'];
languages.forEach((language) => {
  require(`ace-builds/src-noconflict/mode-${language}`);
})

// A light theme that fits in might be github or solarized light
const themes = ['github', 'solarized_light', 'tomorrow_night'];
themes.forEach((theme) => {
  require(`ace-builds/src-noconflict/theme-${theme}`);
});

const CodeInputArea = ({ language, code, setCode, theme, minLines, maxLines }) => {
  return (
    <AceEditor
      placeholder="Type your code here"
      mode={languages.includes(language) ? language : 'python'}
      theme={(themes.includes(theme)) ? theme : 'solarized_light'}
      onChange={newValue => setCode(newValue)}
      name={`${language}-editor`}
      fontSize={16}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      value={code}
      width='100%'
      setOptions={{
        wrap: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        showLineNumbers: true,
        tabSize: 4,
        useSoftTabs: true,
        minLines: minLines,
        maxLines: maxLines
      }}
    />
  )
}

export default CodeInputArea;