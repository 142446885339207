/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { css } from '@emotion/react';

const WhiteTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);

const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem'
  }
};

const Timer = ({ time }) => {

  return (
    <Grid container spacing={0} alignItems="center" justifyContent='center'>
      <ThemeProvider theme={theme}>
        <Button color='primary' style ={{'pointerEvents': 'none', 'borderRadius': '25px'}} variant="contained">
          <WhiteTypography variant="h3">
            {String(parseInt(time / 60, 10)).padStart(2, '0')}:
            {String(time - parseInt(time / 60, 10) * 60).padStart(2, '0')}
          </WhiteTypography>
        </Button>
      </ThemeProvider>
    </Grid >
  );
};

Timer.propTypes = {
  time: PropTypes.number.isRequired
};

export default Timer;
