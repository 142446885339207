/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, useRef } from 'react';
import Blockly from 'blockly';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Stage, Layer, Image, Group, Text } from 'react-konva';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';

import LoadingPage from '../utils/LoadingPage';
import { createVariableBlocks } from './BlockDefinition';
import GIF, { getDirectionGif } from '../utils/Gif';
import Instruction from '../utils/Instruction';
import SpeedSlider from '../utils/SpeedSlider';
import ShowCode from '../utils/ShowCode';
import { sleep, getCharGIF, getAngle, getDirection } from '../utils/utils';
//import exercises from './Data';
import Paths from './Paths';
import Gridlines from './Gridlines';
import useWindowDimensions from '../utils/useWindowDimensions';
import axios from 'axios';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { setCurrentExercise, completeExercise, setAttempt } from '../../actions';
import CustomBlocklyWorkspace from '../utils/CustomBlocklyWorkspace';
import CongratsModal from '../utils/CongratsModal';
import Timer from '../utils/Timer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 1000,
  },
  instruction: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

var currStickers = [];
var bpStickers = [];

const COLOR_LIST = ['#000000', '#ffffff']

const Artist = (props) => {
  var {
    match: {
      params: { id: exerciseId }
    },
    exercise, // exercise data
    exerciseIds, // exercise id list used for link to next exercise
    history // Routing variable
  } = props;
  const [loading, setLoading] = useState(true);

  // Component styling and screen resolution
  const classes = useStyles();
  const { screenWidth, screenHeight } = useWindowDimensions();
  const [SCALE, setSCALE] = useState(150); // Image scale
  const [AXIS_OFFSET, setAXISOFFSET] = useState(75); // Gridline offset
  useEffect(() => {
    let mazeLength = (exercise && exercise.size) ? exercise.size : 8;
    let newScale = 10;
    let newAxisOffset = 5;

    if (screenWidth > 1200) {
      // Vertical split
      newScale = (screenWidth * 0.75) / (2 * mazeLength);
      newAxisOffset = (screenWidth * 0.75) / (4 * mazeLength);
    } else if (screenWidth > 200) {
      newScale = (screenWidth * 0.75) / (mazeLength);
      newAxisOffset = (screenWidth * 0.75) / (4 * mazeLength);
    } else {
      // In case
      newScale = 10;
      newAxisOffset = 5;
    }

    setSCALE(Math.floor(newScale));
    setAXISOFFSET(Math.floor(newAxisOffset));
  }, [screenWidth, exercise]);

  // Images state
  const [backgroundImg, setBackground] = useState(new window.Image());
  const [paths, setPaths] = useState([]); //contains an array of xy pairs, tracking each move of the avatar
  const [blueprintPaths, setBlueprintPaths] = useState([]);
  const [stickers, setStickers] = useState([]);
  const [blueprintStickers, setBlueprintStickers] = useState([]);


  // Game state
  const [angle, setAngle] = useState();
  const [charState, setCharState] = useState();
  const [playing, setPlaying] = useState(0); // state 0 for before - 1 for playing - 2 for finished
  const [error, setError] = useState(false);
  const [direction, setDirection] = useState();
  const [program, setProgram] = useState(); // Program string constructed by blockly
  const [codeView, setCodeView] = useState(); // Code displayed to user
  const [workspace, setWorkspace] = useState(); // Blockly workspace
  const [waitTime, setWaitTime] = useState(50); // Time to wait between moves
  const [gridlinesActive, setGridlinesActive] = useState(false);
  const [nextExercise, setnextExercise] = useState(); // next exercise after exercise completion (used in modal)
  const [solveTime, setSolveTime] = useState();
  const [clockInterval, setClockInterval] = useState();
  const [completed, setCompleted] = useState(false);
  const [actualBlocks, setActualBlocks] = useState(0);
  const [previouslyCompleted, setPreviouslyCompleted] = useState(false);
  const [gridColor, setGridColor] = useState('#000');
  const [errMessage, setErrMessage] = useState('');

  const restartClock = () => {
    // Start the clock
    clearInterval(clockInterval);
    const interval = setInterval(() => {
      setSolveTime((time) => time + 1);
    }, 1000);
    setClockInterval(interval);
  }

  const gridlinesChange = (event) => {
    setGridlinesActive(event.target.checked);
  };

  // Local variable for move/draw function to access
  var penDown = true;
  let currPaths = [];
  let currAngle = angle;
  var currColour = 'black';
  var currAlpha = 100;
  var currWidth = 1;
  var currLoc = [0, 0];
  var stopped = false;
  var functionCounter = 0;
  var programStates = [];

  // Reference to avatar on canvas
  const avatarRef = useRef();
  const pathRef = useRef();
  const bluePrintRef = useRef();

  const resetGame = () => {
    if (exercise.character.angle !== undefined) {
      setAngle(exercise.character.angle);
      setDirection(getDirection(exercise.character.angle));
      setCharState(getDirection(exercise.character.angle));
    } else {
      setAngle(getAngle(exercise.character.direction));
      setDirection(exercise.character.direction);
      setCharState(exercise.character.direction);
    }
    functionCounter = 0;
    programStates = [];
    //currPaths = [];
    setPaths([]);
    setStickers([]);
    stopped = false;

    avatarRef.current.to({
      x: exercise.character.location[0] * SCALE,
      y: exercise.character.location[1] * SCALE,
      duration: (waitTime * 4) / 5000
    });
    setPlaying(0);
  };

  const blueprintGen = () => {
    var blueprint = exercise.blueprint;
    setBlueprintPaths(blueprint);
    var j;
    for (j in exercise.stickers) {
      const sticker = exercise.stickers[j];
      const stickerImg = new window.Image(SCALE, SCALE);
      stickerImg.onload = function () {
        addStickerBlueprint(stickerImg, sticker.x, sticker.y);
      }
      stickerImg.src = sticker.img;
      // TODO: turn into bpStickers += sticker;
      bpStickers = [...bpStickers, { img: sticker.img, x: sticker.x, y: sticker.y }];
    }
  };

  const addStickerBlueprint = (img, x, y) => {
    setBlueprintStickers(blueprintStickers => [...blueprintStickers, { img: img, x: x, y: y }]);
  }

  //Make sure resulting move doesn't push the avatar out of bounds
  const verifyMove = async () => {
    let x = currLoc[0] + 1;
    let y = currLoc[1] + 1;
    return x >= 0 && x <= exercise.size && y >= 0 && y <= exercise.size && !stopped;
  }

  const move = async (modifier = 1) => {
    let x1 = currLoc[0];
    let y1 = currLoc[1];
    const x2 = parseFloat((x1 + modifier * Math.cos(currAngle / 180 * Math.PI)).toFixed(7));
    const y2 = parseFloat((y1 + -1 * modifier * Math.sin(currAngle / 180 * Math.PI)).toFixed(7));
    currLoc = [x2, y2];

    //Set gif direction
    let direct = '';
    if (currAngle > 45 && currAngle < 135) { direct = 'up'; }
    else if (currAngle >= 135 && currAngle <= 225) { direct = 'left'; }
    else if (currAngle > 225 && currAngle < 315) { direct = 'down'; }
    else { direct = 'right'; }

    if (waitTime != 0) {
      setCharState(direct);
      setDirection(direct);
      //Add new path to paths
      if (penDown) {
        const newPath = {
          path: [
            x1, y1, x2, y2
          ],
          colour: currColour,
          alpha: currAlpha,
          width: currWidth,
        };
        setPaths([...currPaths, newPath]);
        currPaths = [...currPaths, newPath];
      }
      await new Promise((resolve, reject) => {
        avatarRef.current.to({
          x: x2 * SCALE,
          y: y2 * SCALE,
          duration: 0,
          onFinish: async () => {
            if (await verifyMove()) resolve();
            else reject(new Error("Character moved out of bounds."));
          }
        });
      });
      await sleep(waitTime);
    } else {
      //Add new path to paths
      if (penDown) {
        const newPath = {
          path: [
            x1, y1, x2, y2
          ],
          colour: currColour,
          alpha: currAlpha,
          width: currWidth,
        };

        currPaths = [...currPaths, newPath];
      }
      if (!await verifyMove()) {
        throw new Error("Character moved out of bounds.");
      }
    }
  }

  const moveCoords = async (x2, y2) => {
    let x1 = currLoc[0];
    let y1 = currLoc[1];
    //Set gif direction
    let direct = '';
    if (currAngle > 45 && currAngle < 135) { direct = 'up'; }
    else if (currAngle >= 135 && currAngle <= 225) { direct = 'left'; }
    else if (currAngle > 225 && currAngle < 315) { direct = 'down'; }
    else { direct = 'right'; }
    currLoc = [x2, y2];

    if (waitTime != 0) {

      setCharState(direct);
      setDirection(direct);
      //Add new path to paths
      if (penDown) {
        const newPath = {
          path: [
            x1, y1, x2, y2
          ],
          colour: currColour,
          alpha: currAlpha,
          width: currWidth,
        };
        setPaths([...currPaths, newPath]);
        currPaths = [...currPaths, newPath];
      }


      await new Promise((resolve, reject) => {
        avatarRef.current.to({
          x: x2 * SCALE,
          y: y2 * SCALE,
          duration: 0,
          onFinish: async () => {
            if (await verifyMove()) resolve();
            else reject(new Error("Character moved out of bounds."));
          }
        });
      });

      await sleep(waitTime);
    } else {
      //Add new path to paths
      if (penDown) {
        const newPath = {
          path: [
            x1, y1, x2, y2
          ],
          colour: currColour,
          alpha: currAlpha,
          width: currWidth,
        };
        currPaths = [...currPaths, newPath];
      }
      if (!await verifyMove()) {
        throw new Error("Character moved out of bounds.");
      }
    };
  }

  //turnDirection is 'left' or 'right', turn degree is 0-360.
  const turnAngle = async (turnDirection, turnDegrees) => {
    if (waitTime != 0) {
      let nextDirection = turnDirection === 'left' ? currAngle + turnDegrees : currAngle - turnDegrees;
      //Ensure angle is within 0-360.
      if (nextDirection < 0) { nextDirection = nextDirection + 360; }
      if (nextDirection >= 360) { nextDirection = nextDirection - 360; }
      setAngle(nextDirection);

      currAngle = nextDirection;
      await sleep((waitTime * 2) / 5);
    } else {
      let nextDirection = turnDirection === 'left' ? currAngle + turnDegrees : currAngle - turnDegrees;
      //Ensure angle is within 0-360.
      if (nextDirection < 0) { nextDirection = nextDirection + 360; }
      if (nextDirection >= 360) { nextDirection = nextDirection - 360; }

      currAngle = nextDirection;
    }
  };

  /* eslint-disable no-unused-vars */
  // highlight block as it runs
  const highlightBlock = (id) => {
    workspace.highlightBlock(id);
  };

  // game functionalities
  const moveEast = async () => {
    if (waitTime != 0) {
      setAngle(0);
    }
    currAngle = 0;
    // setCharState('right');
    await move();
  };

  const moveWest = async () => {
    if (waitTime != 0) {
      setAngle(180);
    }
    currAngle = 180;
    // setCharState('left');
    await move();
  };

  const moveNorth = async () => {
    if (waitTime != 0) {
      setAngle(90);
    }
    currAngle = 90;
    // setCharState('up');
    await move();
  };

  const moveSouth = async () => {
    if (waitTime != 0) {
      setAngle(270);
    }
    currAngle = 270;
    // setCharState('down');
    await move();
  };

  const moveNorthEast = async () => {
    if (waitTime != 0) {
      setAngle(45);
    }

    currAngle = 45;

    // setCharState('right');
    await move();
  };

  const moveSouthWest = async () => {
    if (waitTime != 0) {
      setAngle(225);
    }
    currAngle = 225;
    // setCharState('left');
    await move();
  };

  const moveNorthWest = async () => {
    if (waitTime != 0) {
      setAngle(135);
    }

    currAngle = 135;
    // setCharState('left');
    await move();
  };

  const moveSouthEast = async () => {
    if (waitTime != 0) {
      setAngle(315);
    }
    currAngle = 315;
    // setCharState('right');
    await move();
  };

  const jumpEast = async () => {
    if (waitTime != 0) {
      setAngle(0);
    }
    currAngle = 0;
    // setCharState('right');
    penDown = false;
    await move();
    penDown = true;
  };

  const jumpWest = async () => {
    if (waitTime != 0) {
      setAngle(180);
    }
    currAngle = 180;
    // setCharState('left');
    penDown = false;
    await move();
    penDown = true;
  };

  const jumpNorth = async () => {
    if (waitTime != 0) {
      setAngle(90);
    }
    currAngle = 90;
    // setCharState('up');
    penDown = false;
    await move();
    penDown = true;
  };

  const jumpSouth = async () => {
    if (waitTime != 0) {
      setAngle(270);
    }
    currAngle = 270;
    // setCharState('down');
    penDown = false;
    await move();
    penDown = true;
  };

  const jumpNorthEast = async () => {
    if (waitTime != 0) {
      setAngle(45);
    }
    currAngle = 45;
    // setCharState('right');
    penDown = false;
    await move();
    penDown = true;
  };

  const jumpSouthWest = async () => {
    if (waitTime != 0) {
      setAngle(225);
    }
    currAngle = 225;
    // setCharState('left');
    penDown = false;
    await move();
    penDown = true;
  };

  const jumpNorthWest = async () => {
    if (waitTime != 0) {
      setAngle(135);
    }
    currAngle = 135;
    // setCharState('left');
    penDown = false;
    await move();
    penDown = true;
  };

  const jumpSouthEast = async () => {
    if (waitTime != 0) {
      setAngle(315);
    }
    currAngle = 315;
    // setCharState('right');
    penDown = false;
    await move();
    penDown = true;
  };

  const movePoints = async (points) => {
    await move(points / 100);
  }

  const jumpForward = async () => {
    penDown = false;
    await move();
    penDown = true;
  }

  const jumpCoords = async (x, y) => {
    penDown = false;
    await moveCoords(x - 1, y - 1);
    penDown = true;
  }

  const jumpTo = async (loc) => {
    penDown = false;
    switch (loc) {
      case 'tl':
        await moveCoords(2, 2);
        break;
      case 'tc':
        await moveCoords(5, 2);
        break;
      case 'tr':
        await moveCoords(8, 2);
        break;
      case 'ml':
        await moveCoords(2, 5);
        break;
      case 'c':
        await moveCoords(5, 5);
        break;
      case 'mr':
        await moveCoords(8, 5);
        break;
      case 'bl':
        await moveCoords(2, 8);
        break;
      case 'bc':
        await moveCoords(5, 8);
        break;
      default:
        await moveCoords(8, 8);
        break;
    }

    penDown = true;

  }

  const setColour = async (colour) => {
    if (colour === '#000000') {
      colour = 'black'
    }
    currColour = colour;
  }

  const setAlpha = async (alpha) => {
    currAlpha = alpha;
  }

  const setWidth = async (width) => {
    currWidth = width;
  }

  const drawSticker = async (src) => {
    var stickerImg = new window.Image(SCALE, SCALE);
    stickerImg.onload = function () {
      setStickers(stickers => [...stickers, { img: stickerImg, x: currLoc[0], y: currLoc[1] }]);
      currStickers = [...currStickers, { img: src, x: currLoc[0], y: currLoc[1] }];
    }
    stickerImg.src = src;
    if (waitTime != 0) {
      await sleep(waitTime);
    }
  }

  const distancetoCentre = () => {
    return parseFloat(Math.sqrt(Math.pow(currLoc[0] - 5, 2) + Math.pow(currLoc[1] - 5, 2)).toFixed(2));
  }

  const atSticker = (src) => {
    console.log(bpStickers);
    return bpStickers.some(x => _.isEqual(x, { img: src, x: currLoc[0], y: currLoc[1] }));
  }

  /* eslint-enable no-unused-vars */

  const pathsIncluded = (paths1, paths2, attrCheck) => {
    let i, j;
    for (i in paths1) {
      let found = false;
      let [x1, y1, x2, y2] = paths1[i].path;
      for (j in paths2) {
        if ((_.isEqual([x1, y1, x2, y2], paths2[j].path) || _.isEqual([x2, y2, x1, y1], paths2[j].path))) {
          if (attrCheck) {
            //This checks that the path attribute matches the blueprint attribute, but if the blueprint attribute is the default, anything is fine
            if ((_.isEqual(paths1[i].colour, paths2[j].colour) || paths2[j].colour === 'black')
              && (_.isEqual(paths1[i].alpha, paths2[j].alpha) || paths2[j].alpha === 100)
              && (_.isEqual(paths1[i].width, paths2[j].width) || paths2[j].width === 1)) {
              found = true;
              break;
            }
          }
          else {
            found = true;
            break;
          }
        }
      }
      if (!found) { return false; }
    }
    return true;
  }

  const stickersIncluded = () => {

    let i, j;
    for (i in currStickers) {
      if (!bpStickers.some(x => _.isEqual(x, currStickers[i]))) {
        return false;
      }
    }
    for (j in bpStickers) {
      if (!currStickers.some(x => _.isEqual(x, bpStickers[j]))) {
        return false;
      }
    }
    return true;
  }

  const cleanUnusedBlocks = (workspace) => {
    var blocks = workspace.getAllBlocks();
    // For all blocks present in the workspace
    for (var i = 0; i < blocks.length; ++i) {
      // If the root block
      var rootBlock = blocks[i].getRootBlock();
      // Is not when run type, or is deletable
      if (rootBlock.type != "when_run" && rootBlock.type != "function" && rootBlock.isDeletable()) {
        // Dispose of that block
        blocks[i].dispose();
      }
    }
  }

  const checkResult = () => {
    // Assume blueprints does not contain duplicated paths
    // Check if every path in currPaths belongs to the blueprints
    // For every path in currPaths
    for (let i = 0; i < currPaths.length; ++i) {
      // Check if blueprint moves does not contain currPath at index i
      let [x1, y1, x2, y2] = currPaths[i].path;
      // Conditions: check path is equal, colour is equal, line width is equal and alpha is equal
      let matchingBlueprintPathIndex = blueprintPaths.findIndex((element) => (
        (_.isEqual([x1, y1, x2, y2], element.path) || _.isEqual([x2, y2, x1, y1], element.path))
      ));
      if (matchingBlueprintPathIndex === -1) {
        // The tested path in the drawn path has coordinates
        setErrMessage(exercise.errMessage + ". Error: Path coordinates don't match.");
        setCharState('failure');
        setError(true);
        return;
      } else {
        let matchingPath = blueprintPaths[matchingBlueprintPathIndex];
        if (!_.isEqual(currPaths[i].colour, matchingPath.colour)) {
          // Matched path has wrong colour
          setErrMessage(exercise.errMessage + ". Error: Path color doesn't match.");
          setCharState('failure');
          setError(true);
          return;
        } else if (!_.isEqual(currPaths[i].width, matchingPath.width)) {
          // Matched path has wrong width
          setErrMessage(exercise.errMessage + ". Error: Line width doesn't match.");
          setCharState('failure');
          setError(true);
          return;
        } else if (!_.isEqual(currPaths[i].alpha, matchingPath.alpha)) {
          // Matched path has wrong alpha
          // For now not implemeneted in any Artist exercises
          setErrMessage(exercise.errMessage + ". Error: Line transparency (alpha) doesn't match.");
          setCharState('failure');
          setError(true);
          return;
        }
      }
    }
    // Check if blueprint contain path that is not drawn
    for (let i = 0; i < blueprintPaths.length; ++i) {
      // Check if blueprint moves does not contain currPath at index i
      let [x1, y1, x2, y2] = blueprintPaths[i].path;
      // Conditions: check path is equal, colour is equal, line width is equal and alpha is equal
      let matchingBlueprintPathIndex = currPaths.findIndex((element) => (
        (_.isEqual([x1, y1, x2, y2], element.path) || _.isEqual([x2, y2, x1, y1], element.path))
        && _.isEqual(blueprintPaths[i].colour, element.colour)
        && _.isEqual(blueprintPaths[i].width, element.width)
        && _.isEqual(blueprintPaths[i].alpha, element.alpha)
      ));
      if (matchingBlueprintPathIndex === -1) {
        // The tested path in the drawn path has coordinates
        setErrMessage(exercise.errMessage + ". Error: Blueprint not completed.");
        setCharState('failure');
        setError(true);
        return;
      }
    }
    // Blueprint not completed.
    setCharState('success');
    setError(false);
    setCompleted(true);
    clearInterval(clockInterval);
    // Remove unused blocks
    cleanUnusedBlocks(workspace);
    // And recalculate number of blocks
    setActualBlocks(workspace ? workspace.getAllBlocks().length - 1 : 0)
    props.completeExercise();

    // //Checks that the path covers the blueprint, but also that it doesn't go somewhere other than the blueprint. 
    // if (pathsIncluded(blueprintMoves, currPaths, false) && pathsIncluded(currPaths, blueprintMoves, true) && stickersIncluded()) {
    //   //alert('YOU WIN');
    //   setCharState('success');
    //   setError(false);
    //   setCompleted(true);
    //   clearInterval(clockInterval);
    //   // Remove unused blocks
    //   cleanUnusedBlocks(workspace);
    //   // And recalculate number of blocks
    //   setActualBlocks(workspace ? workspace.getAllBlocks().length - 1 : 0)
    //   props.completeExercise();
    // } else {
    //   setCharState('failure');
    //   // Have not collected all the goals
    //   setErrMessage(exercise.errMessage + " Error: Drawn blueprint doesn't match");
    //   setError(true);
    // }
  };

  const stopGame = () => {
    // All these seem to not work.
    stopped = true;
    currLoc = [-2, -2];
    avatarRef.current.attrs.x = -SCALE;
    avatarRef.current.attrs.y = -SCALE;
  };

  const pathIsEqual = (path1, path2) => {
    return path1.colour == path2.colour && path1.alpha == path2.alpha && path1.width == path2.width &&
      path1.path.length == 4 && path2.path.length == 4 &&
      path1.path.every((element, index) => element == path2.path[index])
  }

  const gameStateIsEqual = (state1, state2) => {
    // position are similar to [0, 2]
    const positionEqual =
      state1.position[0] == state2.position[0] &&
      state1.position[1] == state2.position[1];
    const idEqual = (state1.id === state2.id);
    // Small calculation optimization
    if (positionEqual === true && idEqual === true) {
      // Check if blueprints are matching
      return state1.blueprint.every((element, index) => pathIsEqual(element, state2.blueprint[index]))

      // return state1.goals
      //   .map((element, index) => element.num === state2.goals[index].num)
      //   .every(element => element === true);
    } else {
      // positionEqual !== true so should be false
      return false;
    }
  }

  const logGameState = (id) => {
    // Create current game state
    var currentGameState = {
      "id": id,
      "position": (waitTime != 0) ?
        [_.cloneDeep(avatarRef.current.attrs.x), _.cloneDeep(avatarRef.current.attrs.y)] :
        [_.cloneDeep(currLoc[0]), _.cloneDeep(currLoc[1])],
      "blueprint": _.cloneDeep(currPaths)
    }

    const stateIsPreviouslyEncountered = programStates
      .some(element => gameStateIsEqual(element, currentGameState));

    console.log(currentGameState);

    // If already encountered
    if (stateIsPreviouslyEncountered) {
      // Throw error
      throw new Error("Loop continues forever.");
    } else {
      // Append to game states
      programStates.push(currentGameState);
    }
  }

  const play = async () => {
    console.log(String(
      Blockly.Xml.domToPrettyText(Blockly.Xml.workspaceToDom(workspace, true))
    ));
    setPlaying(1);
    await sleep(waitTime);

    currLoc = exercise.character.location;
    try {
      const execution = `(async () => { ${program} })();`;
      await eval(execution);
      if (waitTime == 0) {
        avatarRef.current.to({
          x: currLoc[0] * SCALE,
          y: currLoc[1] * SCALE,
          duration: 0
        });
        setAngle(currAngle);
        setDirection(getDirection(currAngle))
      }
    } catch (e) {
      if (waitTime == 0) {
        avatarRef.current.to({
          x: currLoc[0] * SCALE,
          y: currLoc[1] * SCALE,
          duration: 0
        });
        setAngle(currAngle);
        setDirection(getDirection(currAngle))
      }
      setErrMessage(exercise.errMessage + '. Error: ' + e.message);
      setError(true);
      setCharState('failure');
      setPlaying(2);
      return;
    }
    checkResult();
    setPlaying(2);
  };

  const checkFunctionCalls = () => {
    if (functionCounter == 0) {
      throw new Error('No function block used.');
    }
  }

  const checkForLoopVariables = (from, to, count) => {
    if (count <= 0) {
      throw new Error('Increment value must be positive.');
    }
    if (from > to) {
      throw new Error("'to' value (" + to + ") should be larger than 'from' value (" + from + ") in a for loop.");
    }
  }

  // Initialize images
  useEffect(() => {
    if (exercise && exercise.exerciseType == 'BlocklyArtist') {
      const img1 = new window.Image();
      img1.src = exercise.background;
      setBackground(img1);
      blueprintGen();
      if (exercise.gridlines) {
        setGridlinesActive(true);
      }
      // Initial angle for exercise would prioritize character.angle
      // If angle is not present, use character.direction
      if (exercise.character.angle !== undefined) {
        setAngle(exercise.character.angle);
        setCharState(getDirection(exercise.character.angle));
        setDirection(getDirection(exercise.character.angle));
      } else {
        setAngle(getAngle(exercise.character.direction));
        setCharState(exercise.character.direction);
        setDirection(exercise.character.direction);
      }
      if (exercise.variables) {
        createVariableBlocks(exercise.variables);
      }
      currLoc = exercise.character.location;

      // Check if exercise already completed on this session
      const completionIndex = exerciseIds.findIndex((element) => element.id === exercise._id);
      if (completionIndex !== -1) {
        let element = exerciseIds[completionIndex];
        if (element.completed === true) {
          // In homework list, finished
          setPreviouslyCompleted(true);
          setCompleted(true);
          setActualBlocks(element.previous_line);

          // Do not start clock
          setSolveTime(element.previous_time);
          clearInterval(clockInterval);

          return () => {
            clearInterval(clockInterval);
          };
        } else {
          // In homework list, not recorded
          setPreviouslyCompleted(false);
          setCompleted(false);
          setSolveTime(0);
          restartClock();
          return () => {
            clearInterval(clockInterval);
          };
        }
      } else {
        // Exercise not present in homework list because of custom routing
        // Starts as usual
        setPreviouslyCompleted(false);
        setCompleted(false);
        setSolveTime(0);
        restartClock();
        return () => {
          clearInterval(clockInterval);
        };
      }
    }

  }, [exercise]);

  //Recache the blueprint whenever the scale changes.
  useEffect(() => {
    if (bluePrintRef.current) {
      bluePrintRef.current.opacity(0.4);
      bluePrintRef.current.cache();
    }
  }, [SCALE, bluePrintRef.current]);

  // Get exercise data from database
  useEffect(() => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_EXE_API}/artist/${exerciseId}`)
      .then((response) => {
        console.log(response);
        props.setCurrentExercise(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        props.enqueueSnackbar('Failed to fetch exercise', {
          variant: 'error'
        });
        history.push('/');
      });
  }, [exerciseId]);

  const skip = () => {
    const path = nextExercise
      ? `/${nextExercise.type.toLowerCase()}/${nextExercise.id}`
      : '/';
    history.push(path);
  }

useEffect(() => {
  if (exercise && exerciseIds && exerciseIds.length > 0) {
    // find the current exercise index in exercise id list in redux store
    const currentIndex = exerciseIds.findIndex(
      (elem) => elem.id === exercise._id
    );

    // if found current exercise and index is within bounds
    // set next exercise by incrementing the current index, otherwise set to 0
    if (currentIndex < exerciseIds.length - 1 && currentIndex >= 0) {
      const targetId = exercise._id;
      const targetType = 'artist';

      // Find the index of the target item
      const currentIndex = exerciseIds.findIndex(
        (elem) => elem.id === targetId && elem.type === targetType
      );

      // If the current item is found and it's not the last item in the array
      if (currentIndex !== -1 && currentIndex < exerciseIds.length - 1) {
        // Get the next item in the array
        const nextExercise = exerciseIds[currentIndex + 1];

        // Set the next exercise
        setnextExercise({
          id: nextExercise.id,
          type: nextExercise.type
        });
      } else {
        // Handle the case where no match is found or it's the last item
        console.log('No next exercise found');
        setnextExercise(null);
      }
    } else if (currentIndex === exerciseIds.length - 1) {
      setnextExercise(null);
    } else {
      // exerciseIds.length > 0
      setnextExercise(
        {
          id: exerciseIds[0].id,
          type: exerciseIds[0].type
        }
      );
    }
  } else {
    setnextExercise(null);
  }
}, [exercise, exerciseIds]);

  const changeColor = (e) => {
    if (e.target.checked) {
      setGridColor('#ffffff');
    } else {
      setGridColor('#000000')
    }
  }

  const renderGameAreaComponent = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        m={1}
        p={1}
        bgcolor="background.paper"
        style={{ overflow: 'hidden' }}
      >
        <Paper className={classes.paper}>
          <Box
            display="flex"
            justifyContent="center"
            m={1}
            p={1}
            bgcolor="background.paper"
            style={{ overflowY: 'hidden', overflowX: 'hidden' }}
          >
            <Stage
              width={exercise.size * SCALE + AXIS_OFFSET}
              height={exercise.size * SCALE + AXIS_OFFSET}
            >
              <Layer>
                <Image
                  x={AXIS_OFFSET}
                  y={AXIS_OFFSET}
                  image={backgroundImg}
                  width={exercise.size * SCALE}
                  height={exercise.size * SCALE}
                />
                {gridlinesActive &&
                  <Gridlines
                    scale={SCALE}
                    axisOffset={AXIS_OFFSET}
                    width={exercise.size}
                    height={exercise.size}
                    color={gridColor} />}

                <Group
                  ref={avatarRef}
                  x={exercise.character.location[0] * SCALE - 0.75 * SCALE + AXIS_OFFSET}
                  y={exercise.character.location[1] * SCALE - 0.75 * SCALE + AXIS_OFFSET}
                >
                  <GIF
                    src={getCharGIF(charState, exercise.character)}
                    width={SCALE * 3 / 2}
                    height={SCALE * 3 / 2}
                  />
                  <GIF
                    src={getDirectionGif(direction)}
                    width={SCALE / 4}
                    height={SCALE / 4}
                  />
                </Group>

                <Group
                  y={AXIS_OFFSET} x={AXIS_OFFSET}
                >
                  <Group
                    ref={bluePrintRef}
                  >
                    <Paths
                      paths={blueprintPaths} scale={SCALE} width={SCALE * 1.3} colour={'white'}
                    />
                    <Paths
                      paths={blueprintPaths} scale={SCALE}
                    />

                  </Group>
                  <Group
                    y={0.5 * SCALE} x={0.5 * SCALE}
                  >
                    {blueprintStickers.map((sticker) => {
                      return (
                        <Image
                          image={sticker.img}
                          x={sticker.x * SCALE}
                          y={sticker.y * SCALE}
                          width={SCALE}
                          height={SCALE}
                          opacity={0.4}
                        />
                      );
                    })}
                  </Group>
                  <Group
                    ref={pathRef}>
                    <Paths
                      paths={paths} scale={SCALE} width={SCALE * 1.2} colour={'#ececec'}
                    />
                    <Paths
                      paths={paths} scale={SCALE}
                    />
                  </Group>
                </Group>
                <Group
                  y={AXIS_OFFSET + 0.5 * SCALE} x={AXIS_OFFSET + 0.5 * SCALE}
                >
                  {stickers.map((sticker) => {
                    return (
                      <Image
                        image={sticker.img}
                        x={sticker.x * SCALE}
                        y={sticker.y * SCALE}
                        width={SCALE}
                        height={SCALE}
                      />
                    );
                  })}
                </Group>

              </Layer>
            </Stage>
          </Box>
          {exercise.gridlines &&
            <FormControlLabel
              control={
                <Switch
                  checked={gridlinesActive}
                  onChange={gridlinesChange}
                  name="gridlineSwitch"
                  color="primary"
                />
              }
              label="Gridlines"
            />
          }
          {gridlinesActive &&
            <FormControlLabel
              control={
                <Switch
                  checked={gridColor === '#ffffff'}
                  onChange={changeColor}
                  name="gridlineColorSwitch"
                  color="primary"
                />
              }
              label="Color"
            />
          }
          <SpeedSlider setWaitTime={setWaitTime} />
          {playing === 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await play();
              }}
            >
              PLAY
            </Button>
          )}
          {playing === 1 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={stopGame} //() => window.location.reload(false)
            >
              STOP
            </Button>
          )}
          {playing === 2 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={resetGame}
            >
              RESET
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={skip}
            style={{ marginLeft: 15 }}
          >
            SKIP
          </Button>

          <ShowCode code={codeView} width={exercise.size * SCALE + AXIS_OFFSET} />
        </Paper>
      </Box>
    )
  }

  // console.log(gridColor);

  if (loading) return <LoadingPage />;
  return (
    <div className={classes.root}>
      {/* Modal to next exercise after completion */}
      <CongratsModal
        nextExercise={nextExercise}
        open={completed}
        setOpen={setCompleted}
        lineNum={actualBlocks}
        history={history}
        clockInterval={clockInterval}
        restartClock={restartClock}
        successGIF={exercise.character.success}
        solveTime={solveTime}
        previouslyCompleted={previouslyCompleted}
      />
      <Box display="flex" m={1} p={1} bgcolor="background.paper">
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Paper className={classes.instruction}>
              <Instruction
                avatar={exercise.character.right}
                instruction={exercise.instruction}
                story={exercise.storyText}
                hints={exercise.hints}
                errMessage={errMessage}
                error={error}
                resetGame={resetGame}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* Clock */}
      <Box display="flex" m={1} p={1} bgcolor="background.paper">
        <Timer time={solveTime} />
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          {renderGameAreaComponent()}
        </Grid>
        <Grid item xs={6}>
          <CustomBlocklyWorkspace
            screenWidth={screenWidth}
            screenHeight={screenHeight}
            exercise={exercise}
            workspace={workspace}
            setProgram={setProgram}
            setCodeView={setCodeView}
            setWorkspace={setWorkspace}
          />
          {/* <Box
            display="flex"
            justifyContent="center"
            m={1}
            p={1}
            bgcolor="background.paper"
            overflowy="scroll"
          >
            <Paper className={classes.paper}>
              
              <h4>
                Blocks used:{' '}
                {workspace ? workspace.getAllBlocks().length - 1 : 0}{' '}
                &nbsp;&nbsp; Expected blocks:&nbsp;
                {exercise.expectedBlocks}
              </h4>
              <BlocklyComponent
                width={SCALE * 13}
                height={SCALE * 10}
                setProgram={setProgram}
                initialWorkspace={exercise.initialWorkspace}
                toolCategories={
                  exercise.toolCategories ? exercise.toolCategories : undefined
                }
                toolBlocks={
                  exercise.toolBlocks ? exercise.toolBlocks : undefined
                }
                setWorkspace={setWorkspace}
                setCodeView={setCodeView}
              />
            </Paper>
          </Box> */}
        </Grid>
      </Grid>
    </div>
  );
};

Artist.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  exercise: state.exercises.current,
  exerciseIds: state.exercises.ids
});

export default connect(mapStateToProps, {
  setCurrentExercise,
  completeExercise,
  setAttempt
})(withSnackbar(Artist));
