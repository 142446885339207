/* eslint-disable */

import Blockly from 'blockly';
import BlocklyConfig from '../blockly/Config';
import randomstring from 'randomstring';

Blockly.Blocks['pen_up'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("pen up");
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
  }
};

Blockly.JavaScript['pen_up'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'penDown = false;\n';
  return code;
};

Blockly.Blocks['pen_down'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("pen down");
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
  }
};

Blockly.JavaScript['pen_down'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'penDown = true;\n';
  return code;
};

Blockly.Blocks['while_dist'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("while distance from centre <")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "x");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['while_dist'] = function (block) {
  var x = block.getFieldValue('x');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');

  var code =
    "while (distancetoCentre() < " + x + ") {\n" +
    statements_isTrue +
    '} ';

  return code;
};

Blockly.Blocks['while_x'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("while")
      .appendField(new Blockly.FieldDropdown([["to the left of", "left"], ["to the right of", "right"]]), "direction")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "x");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['while_x'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction');
  var x = block.getFieldValue('x');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');
  var direction;
  if (dropdown_direction === "left") {
    direction = '< ';
  }
  else {
    direction = '> ';
  }

  var code =
    "while (currLoc[0]+1 " + direction + x + ") {\n" +
    statements_isTrue +
    '}\n';
  return code;
};

Blockly.Blocks['while_y'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("while")
      .appendField(new Blockly.FieldDropdown([["above", "above"], ["below", "below"]]), "direction")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "y");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['while_y'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction');
  var y = block.getFieldValue('y');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');
  var direction;
  if (dropdown_direction === "above") {
    direction = '< ';
  }
  else {
    direction = '> ';
  }

  var code =
    "while (currLoc[1]+1 " + direction + y + ") {\n" +
    statements_isTrue +
    '}\n';

  return code;
};


Blockly.Blocks['while_not_sticker'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("while not at")
      .appendField(new Blockly.FieldDropdown([[{ "src": "https://res.cloudinary.com/csexercises/image/upload/v1601940059/Cute-Cartoon-Panda.svg_fr9oez.gif", "width": 15, "height": 15, "alt": "*" }, "https://res.cloudinary.com/csexercises/image/upload/v1601940059/Cute-Cartoon-Panda.svg_fr9oez.gif"], [{ "src": "https://res.cloudinary.com/csexercises/image/upload/v1601937018/Red-Crab.svg_gj2ptz.gif", "width": 15, "height": 15, "alt": "*" }, "https://res.cloudinary.com/csexercises/image/upload/v1601937018/Red-Crab.svg_gj2ptz.gif"], [{ "src": "https://www.gstatic.com/codesite/ph/images/star_on.gif", "width": 15, "height": 15, "alt": "*" }, "https://www.gstatic.com/codesite/ph/images/star_on.gif"]]), "stickers");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['while_not_sticker'] = function (block) {
  var sticker = block.getFieldValue('stickers');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');

  var code =
    "while (!atSticker('" + sticker + "')) {\n" +
    statements_isTrue +
    '} ';

  return code;
};

Blockly.Blocks['if_sticker'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("if at")
      .appendField(new Blockly.FieldDropdown([[{ "src": "https://res.cloudinary.com/csexercises/image/upload/v1601940059/Cute-Cartoon-Panda.svg_fr9oez.gif", "width": 15, "height": 15, "alt": "*" }, "https://res.cloudinary.com/csexercises/image/upload/v1601940059/Cute-Cartoon-Panda.svg_fr9oez.gif"], [{ "src": "https://res.cloudinary.com/csexercises/image/upload/v1601937018/Red-Crab.svg_gj2ptz.gif", "width": 15, "height": 15, "alt": "*" }, "https://res.cloudinary.com/csexercises/image/upload/v1601937018/Red-Crab.svg_gj2ptz.gif"], [{ "src": "https://www.gstatic.com/codesite/ph/images/star_on.gif", "width": 15, "height": 15, "alt": "*" }, "https://www.gstatic.com/codesite/ph/images/star_on.gif"]]), "stickers");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['if_sticker'] = function (block) {
  var sticker = block.getFieldValue('stickers');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');

  var code =
    "if (atSticker('" + sticker + "')) {\n" +
    statements_isTrue +
    '} ';

  return code;
};

Blockly.Blocks['if_dist'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("if distance from centre >")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "x");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['if_dist'] = function (block) {
  var x = block.getFieldValue('x');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');

  var code =
    "if (distancetoCentre() > " + x + ") {\n" +
    statements_isTrue +
    '} ';

  return code;
};

Blockly.Blocks['if_at'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("if at")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "x")
      .appendField("over,")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "y")
      .appendField("down")
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['if_at'] = function (block) {
  var x = block.getFieldValue('x');
  var y = block.getFieldValue('y');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');

  var code =
    "if (currLoc[0]+1 ===" + x + "&& currLoc[1]+1 ===" + y + ") {\n" +
    statements_isTrue +
    '} ';

  return code;
};

Blockly.Blocks['ifelse_y'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("if position")
      .appendField(new Blockly.FieldDropdown([["above", "above"], ["below", "below"]]), "direction")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "y");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.appendStatementInput('isFalse')
      .setCheck(null)
      .appendField('else');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['ifelse_y'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction');
  var y = block.getFieldValue('y');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');
  var statements_isFalse = Blockly.JavaScript.statementToCode(block, 'isFalse');
  var direction;
  if (dropdown_direction === "above") {
    direction = '< ';
  }
  else {
    direction = '> ';
  }


  var code =
    "if (currLoc[1]+1 " + direction + y + ") {\n" +
    statements_isTrue +
    '} else {\n' +
    statements_isFalse +
    '}\n';

  return code;
};

Blockly.Blocks['ifelse_x'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("if position")
      .appendField(new Blockly.FieldDropdown([["to the left of", "left"], ["to the right of", "right"]]), "direction")
      .appendField(new Blockly.FieldNumber(0, 0, 12, 0), "x");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.appendStatementInput('isFalse')
      .setCheck(null)
      .appendField('else');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['ifelse_x'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction');
  var x = block.getFieldValue('x');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');
  var statements_isFalse = Blockly.JavaScript.statementToCode(block, 'isFalse');
  var direction;
  if (dropdown_direction === "left") {
    direction = '< ';
  }
  else {
    direction = '> ';
  }


  var code =
    "if (currLoc[0]+1 " + direction + x + ") {\n" +
    statements_isTrue +
    '} else {\n' +
    statements_isFalse +
    '}\n';

  return code;
};

Blockly.Blocks['ifelse_angle'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("if angle")
      .appendField(new Blockly.FieldDropdown([["equals", "equals"], ["less than", "lessthan"], ["greater than", "greaterthan"]]), "operator")
      .appendField(new Blockly.FieldAngle(90), "angle");
    this.appendStatementInput('isTrue').setCheck(null).appendField('do');
    this.appendStatementInput('isFalse')
      .setCheck(null)
      .appendField('else');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['ifelse_angle'] = function (block) {
  var dropdown_operator = block.getFieldValue('operator');
  var angle = block.getFieldValue('angle');
  var statements_isTrue = Blockly.JavaScript.statementToCode(block, 'isTrue');
  var statements_isFalse = Blockly.JavaScript.statementToCode(block, 'isFalse');
  var operator;
  if (dropdown_operator === "equals") {
    operator = '=== ';
  }
  else if (dropdown_operator === 'lessthan') {
    operator = '< ';
  }
  else {
    operator = '> ';
  }

  var code =
    "if (currAngle " + operator + angle + ") {\n" +
    statements_isTrue +
    '} else {\n' +
    statements_isFalse +
    '}\n';

  return code;
};

Blockly.Blocks['jump_forward'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("jump forward");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['jump_forward'] = function (block) {
  var code = 'await jumpForward();\n';
  return code;
};

Blockly.Blocks['move_points'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable("draw forward"), "NAME")
      .appendField(new Blockly.FieldNumber(1, 0, 200, 1), "points")
      .appendField("points");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['move_points'] = function (block) {
  var number_points = block.getFieldValue('points');
  // TODO: Assemble JavaScript into code variable.
  var code = 'await movePoints(' + number_points + ');\n';
  return code;
};

//Use on lessons with gridlines
Blockly.Blocks['jump_coords'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("jump to")
      .appendField(new Blockly.FieldNumber(1, 1, 20), "x")
      .appendField("over, ")
      .appendField(new Blockly.FieldNumber(1, 1, 20), "y")
      .appendField("down");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['jump_coords'] = function (block) {
  var number_x = block.getFieldValue('x');
  var number_y = block.getFieldValue('y');
  var code = 'await jumpCoords(' + number_x + ',' + number_y + ');\n';
  return code;
};

Blockly.Blocks['jump_to'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("jump to")
      .appendField(new Blockly.FieldDropdown([["top left", "tl"], ["top centre", "tc"], ["top right", "tr"], ["middle left", "ml"], ["centre", "c"], ["middle right", "mr"], ["bottom left", "bl"], ["bottom centre", "bc"], ["bottom right", "br"]]), "NAME");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['jump_to'] = function (block) {
  var dropdown_name = block.getFieldValue('NAME');
  var code = 'await jumpTo("' + dropdown_name + '");\n';
  return code;
};

Blockly.Blocks['draw_sticker'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("draw sticker:")
      .appendField(new Blockly.FieldDropdown(
        [
          [
            {
              "src": "https://res.cloudinary.com/csexercises/image/upload/v1601940059/Cute-Cartoon-Panda.svg_fr9oez.gif",
              "width": 15,
              "height": 15,
              "alt": "*"
            },
            "https://res.cloudinary.com/csexercises/image/upload/v1601940059/Cute-Cartoon-Panda.svg_fr9oez.gif"
          ],
          [
            {
              "src": "https://res.cloudinary.com/csexercises/image/upload/v1601937018/Red-Crab.svg_gj2ptz.gif",
              "width": 15,
              "height": 15,
              "alt": "*"
            }, "https://res.cloudinary.com/csexercises/image/upload/v1601937018/Red-Crab.svg_gj2ptz.gif"
          ],
          [
            {
              "src": "https://www.gstatic.com/codesite/ph/images/star_on.gif",
              "width": 15,
              "height": 15,
              "alt": "*"
            }, "https://www.gstatic.com/codesite/ph/images/star_on.gif"
          ]
        ]
      ), "stickers");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['draw_sticker'] = function (block) {
  var dropdown_stickers = block.getFieldValue('stickers');
  var code = 'await drawSticker("' + dropdown_stickers + '");\n';
  return code;
};

Blockly.Blocks['set_colour'] = {
  init: function () {
    var field = new Blockly.FieldColour('#ff0000');
    field.setColours(
      ['#FF0000', '#0000FF', '#00FF00', '#FFFF00', '#FF00FF', '#000000'],
      ['red', 'blue', 'green', 'yellow', 'purple', 'black']);
    this.appendDummyInput()
      .appendField("set colour:")
      .appendField(field, "colour");

    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(65);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['set_colour'] = function (block) {
  var colour_colour = block.getFieldValue('colour');
  var code = 'await setColour("' + colour_colour + '");\n';
  return code;
};



Blockly.Blocks['ifelse_colour'] = {
  init: function () {
    var field = new Blockly.FieldColour('#ff0000');
    field.setColours(
      ['#FF0000', '#0000FF', '#00FF00', '#FFFF00', '#FF00FF', '#000000'],
      ['red', 'blue', 'green', 'yellow', 'purple', 'black']);
    this.appendDummyInput()
      .appendField('if colour')
      .appendField(field, 'colour');
    this.appendStatementInput('isColour').setCheck(null).appendField('do');
    this.appendStatementInput('else_statements')
      .setCheck(null)
      .appendField('else');

    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['ifelse_colour'] = function (block) {
  var dropdown_colour = block.getFieldValue('colour');
  var statements_isColour = Blockly.JavaScript.statementToCode(block, 'isColour');
  var else_statements = Blockly.JavaScript.statementToCode(
    block,
    'else_statements'
  );

  if (dropdown_colour === '#000000') {
    dropdown_colour = 'black';
  }

  var code =
    "if (currColour === '" + dropdown_colour + "') {\n" +
    statements_isColour +
    '} else {\n' +
    else_statements +
    '}\n';

  return code;
};


Blockly.Blocks['set_colour_random'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("set colour to random");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(65);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['set_colour_random'] = function (block) {
  const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
  var code = 'await setColour("' + randomColor + '");\n';
  return code;
};

Blockly.Blocks['set_alpha'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("set opacity:")
      .appendField(new Blockly.FieldNumber(100, 0, 100), "alpha");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(65);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['set_alpha'] = function (block) {
  var number_alpha = block.getFieldValue('alpha');
  var code = 'await setAlpha(' + number_alpha + ');\n';
  return code;
};

Blockly.Blocks['set_width'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("set line width:")
      .appendField(new Blockly.FieldNumber(1, 1, 10), "width");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(65);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['set_width'] = function (block) {
  var number_width = block.getFieldValue('width');
  var code = 'await setWidth(' + number_width + ');\n';
  return code;
};

Blockly.Blocks['turnleft'] = {
  init: function () {
    this.appendDummyInput("turnLeft")
      .appendField("turn left")
      .appendField(new Blockly.FieldAngle(45), "leftAngle");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['turnleft'] = function (block) {
  var angle_leftangle = block.getFieldValue('leftAngle');
  var value_turnleft = Blockly.JavaScript.valueToCode(block, 'turnLeft', Blockly.JavaScript.ORDER_ATOMIC);
  var code = 'await turnAngle("left",' + angle_leftangle + ');\n';
  return code;
};

Blockly.Blocks['turnright'] = {
  init: function () {
    this.appendDummyInput("turnright")
      .appendField("turn right")
      .appendField(new Blockly.FieldAngle(45), "rightAngle");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['turnright'] = function (block) {
  var angle_rightangle = block.getFieldValue('rightAngle');
  var value_turnright = Blockly.JavaScript.valueToCode(block, 'turnRight', Blockly.JavaScript.ORDER_ATOMIC);
  var code = 'await turnAngle("right",' + angle_rightangle + ');\n';
  return code;
};

Blockly.Blocks['draw_straight'] = {
  init: function () {
    this.appendDummyInput().appendField('draw straight');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['draw_straight'] = function (block) {
  var code = 'await move();\n';
  return code;
};

const createVariableBlocks = (names) => {
  Blockly.Blocks['set_variable'] = {
    init: function () {
      this.appendValueInput('NAME')
        .setCheck(null)
        .appendField('set')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField('to');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(30);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['set_variable'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_name = Blockly.JavaScript.valueToCode(
      block,
      'NAME',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = 'var ' + dropdown_name + ' = ' + value_name + ';\n';
    return code;
  };

  Blockly.Blocks['if_oddity'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('if ')
        .appendField(new Blockly.FieldDropdown(names), 'var')
        .appendField(new Blockly.FieldDropdown([
          ["is_even", "IS_EVEN"],
          ["is_odd", "IS_ODD"]
        ]),
          "is_even_dropdown"
        );
      this.appendStatementInput("body")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };

  Blockly.JavaScript['if_oddity'] = function (block) {
    var dropdown_var = block.getFieldValue('var');
    var dropdown_iseven = block.getFieldValue('is_even_dropdown');
    // TODO: Assemble JavaScript into code variable.
    var oddity_check = (dropdown_iseven === 'IS_EVEN') ? 0 : 1;
    var statements_body = Blockly.JavaScript.statementToCode(block, 'body');
    var code = 'if (' + dropdown_var + ' % 2 == ' + oddity_check + ') {\n' + statements_body + '}\n';
    return code;
  };

  Blockly.Blocks['if_else_oddity'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('if ')
        .appendField(new Blockly.FieldDropdown(names), 'var')
        .appendField(new Blockly.FieldDropdown([
          ["is_even", "IS_EVEN"],
          ["is_odd", "IS_ODD"]
        ]),
          "is_even_dropdown"
        );
      this.appendStatementInput("true_branch")
        .setCheck(null);
      this.appendDummyInput()
        .appendField('else ');
      this.appendStatementInput("false_branch")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };

  Blockly.JavaScript['if_else_oddity'] = function (block) {
    var dropdown_var = block.getFieldValue('var');
    var dropdown_iseven = block.getFieldValue('is_even_dropdown');
    // TODO: Assemble JavaScript into code variable.
    var oddity_check = (dropdown_iseven === 'IS_EVEN') ? 0 : 1;
    var statements_true_branch = Blockly.JavaScript.statementToCode(block, 'true_branch');
    var statements_false_branch = Blockly.JavaScript.statementToCode(block, 'false_branch');
    var code = 'if (' + dropdown_var + ' % 2 == ' + dropdown_iseven + ') {\n'
      + statements_true_branch + '} else {\n' + statements_false_branch + '}\n';
    return code;
  };

  Blockly.Blocks['increment_variable'] = {
    init: function () {
      this.appendValueInput('NAME')
        .setCheck(null)
        .appendField('increment')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField('by');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(30);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['increment_variable'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_name = Blockly.JavaScript.valueToCode(
      block,
      'NAME',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = dropdown_name + ' = ' + dropdown_name + ' + ' + value_name + ';\n';
    return code;
  };

  Blockly.Blocks['get_variable'] = {
    init: function () {
      this.appendDummyInput().appendField(
        new Blockly.FieldDropdown(names),
        'NAME'
      );
      this.setOutput(true, null);
      this.setColour(30);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['get_variable'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var code = dropdown_name;
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.Blocks['loop_var'] = {
    init: function () {
      this.appendValueInput('from')
        .setCheck('Number')
        .appendField('for')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField('from');
      this.appendDummyInput().appendField('to');
      this.appendValueInput('to').setCheck('Number');
      this.appendDummyInput().appendField('count by');
      this.appendValueInput('count_by').setCheck('Number');
      this.appendStatementInput('in_loop').setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(30);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['loop_var'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var value_from = Blockly.JavaScript.valueToCode(
      block,
      'from',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_to = Blockly.JavaScript.valueToCode(
      block,
      'to',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_count_by = Blockly.JavaScript.valueToCode(
      block,
      'count_by',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var statements_in_loop = Blockly.JavaScript.statementToCode(
      block,
      'in_loop'
    );
    // TODO: Assemble JavaScript into code variable.
    var code =
      '// CURR_ITER & MAX_ITER prevent your\n// while loop from running forever\n' +
      'var CURR_ITER = 0;\n' +
      'var MAX_ITER = 30;\n' +

      'for (var ' +
      dropdown_name +
      ' = ' +
      value_from +
      '; ' +
      dropdown_name +
      ' <= ' +
      value_to +
      '&& CURR_ITER <= MAX_ITER; ' +
      dropdown_name +
      '+= ' +
      value_count_by +
      ') {\n' +
      statements_in_loop +
      '  CURR_ITER++;\n' +
      '}\n';
    return code;
  };

  Blockly.Blocks['while_var'] = {
    init: function () {
      this.appendValueInput('RHS')
        .setCheck('Number')
        .appendField('while')
        .appendField(new Blockly.FieldDropdown(names), 'LHS')
        .appendField(
          new Blockly.FieldDropdown([
            ['<', '<'],
            ['>', '>'],
            ['==', '=='],
            ['<=', '<='],
            ['>=', '>='],
          ]),
          'comparison'
        );
      this.appendStatementInput('while_var_body').setCheck(null).appendField('do');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(300);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['while_var'] = function (block) {
    var dropdown_name = block.getFieldValue('LHS');
    var operator = block.getFieldValue('comparison')
    var value_compare = Blockly.JavaScript.valueToCode(
      block,
      'RHS',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var statements_in_loop = Blockly.JavaScript.statementToCode(
      block,
      'while_var_body'
    );
    // TODO: Assemble JavaScript into code variable.
    var code =
      '// CURR_ITER & MAX_ITER prevent your\n// while loop from running forever\n' +
      'CURR_ITER = 0;\n' +
      'while (' +
      dropdown_name + ' ' + operator + ' ' + value_compare +
      ' && CURR_ITER <= MAX_ITER) ' +
      '{\n ' +
      statements_in_loop +
      '  CURR_ITER++;\n' +
      '}\n';
    return code;
  }

  Blockly.Blocks['function_arg'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('  ')
        .appendField(new Blockly.FieldTextInput('function name'), 'NAME')
        .appendField(new Blockly.FieldDropdown(names), 'parameter');
      this.appendStatementInput('statements').setCheck(null);
      this.setColour(345);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['function_arg'] = function (block) {
    var text_name = block.getFieldValue('NAME');
    var statements_statements = Blockly.JavaScript.statementToCode(
      block,
      'statements'
    );

    var arg_name = block.getFieldValue('parameter');

    if (text_name.length === 0) text_name = 'unnamed';
    if (!Number.isNaN(Number(text_name))) text_name = '_' + text_name;
    if (BlocklyConfig.reservedWords.includes(text_name)) text_name += '2';
    text_name = text_name.replace(BlocklyConfig.specialCharacters, '_');
    var code =
      'async function ' + text_name + '(' + arg_name + ') {\n' + statements_statements + '\n}\n';
    return code;
  };

  Blockly.Blocks['function_call_arg'] = {
    init: function () {
      this.appendValueInput('argument').setCheck('Number').appendField(
        new Blockly.FieldTextInput('function name'),
        'NAME'
      );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(345);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['function_call_arg'] = function (block) {
    var text_name = block.getFieldValue('NAME');
    if (text_name.length === 0) text_name = 'unnamed';
    if (!Number.isNaN(Number(text_name))) text_name = '_' + text_name;
    if (BlocklyConfig.reservedWords.includes(text_name)) text_name += '2';
    text_name = text_name.replace(BlocklyConfig.specialCharacters, '_');

    var value_arg = Blockly.JavaScript.valueToCode(
      block,
      'argument',
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = 'await ' + text_name + '(' + value_arg + ');\n';
    return code;
  };

  Blockly.Blocks['break_if'] = {
    init: function () {
      this.appendValueInput('rhs')
        .setCheck('Number')
        .appendField('break if')
        .appendField(new Blockly.FieldDropdown(names), 'NAME')
        .appendField(
          new Blockly.FieldDropdown([
            ['<', '<'],
            ['>', '>'],
            ['==', '=='],
            ['<=', '<='],
            ['>=', '>='],
          ]),
          'comparison'
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip('');
      this.setHelpUrl('');
    }
  };

  Blockly.JavaScript['break_if'] = function (block) {
    var dropdown_name = block.getFieldValue('NAME');
    var operator = block.getFieldValue('comparison')
    var value_compare = Blockly.JavaScript.valueToCode(
      block,
      'rhs',
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = 'if (' + dropdown_name + ' ' + operator + ' ' + value_compare + ') {break;}\n';
    return code;
  };
};


Blockly.Blocks['move_southeast'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('SE')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/SE_135_00_twyq3g.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_southwest'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('SW')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/SW_225_00_wg8qgb.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_northeast'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('NE')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/NE_45_00_paakkv.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['move_northwest'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('NW')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/NW_315_00_nmxgpg.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_east'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump E')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/E_90_00_sozunf.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_west'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump W')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/W_270_00_l6kdhp.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_north'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump N')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/N_0_00_tkxpmz.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_south'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump S')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/S_180_00_nfs8c3.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_northeast'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump NE')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/NE_45_00_paakkv.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_southwest'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump SW')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/SW_225_00_wg8qgb.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_northwest'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump NW')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/NW_315_00_nmxgpg.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Blocks['jump_southeast'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('jump SE')
      .appendField(
        new Blockly.FieldImage(
          'https://res.cloudinary.com/csexercises/image/upload/v1615247287/blocks/SE_135_00_twyq3g.png',
          50,
          50,
          { alt: '*', flipRtl: 'FALSE' }
        )
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.JavaScript['move_northeast'] = function (block) {
  var code = 'await moveNorthEast();\n';
  return code;
};

Blockly.JavaScript['move_southeast'] = function (block) {
  var code = 'await moveSouthEast();\n';
  return code;
};

Blockly.JavaScript['move_northwest'] = function (block) {
  var code = 'await moveNorthWest();\n';
  return code;
};

Blockly.JavaScript['move_southwest'] = function (block) {
  var code = 'await moveSouthWest();\n';
  return code;
};

Blockly.JavaScript['jump_east'] = function (block) {
  var code = 'await jumpEast();\n';
  return code;
};

Blockly.JavaScript['jump_west'] = function (block) {
  var code = 'await jumpWest();\n';
  return code;
};

Blockly.JavaScript['jump_north'] = function (block) {
  var code = 'await jumpNorth();\n';
  return code;
};

Blockly.JavaScript['jump_south'] = function (block) {
  var code = 'await jumpSouth();\n';
  return code;
};

Blockly.JavaScript['jump_northeast'] = function (block) {
  var code = 'await jumpNorthEast();\n';
  return code;
};

Blockly.JavaScript['jump_southwest'] = function (block) {
  var code = 'await jumpSouthWest();\n';
  return code;
};

Blockly.JavaScript['jump_northwest'] = function (block) {
  var code = 'await jumpNorthWest();\n';
  return code;
};

Blockly.JavaScript['jump_southeast'] = function (block) {
  var code = 'await jumpSouthEast();\n';
  return code;
};

export {
  createVariableBlocks
};

