import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const MAX_SPEED = 100;

const SpeedSlider = ({ setWaitTime, setIsMaxspeed }) => {
  const [value, setValue] = React.useState(90);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Speed slider value is from 1-100
    // We want the wait time interval to be at least 150ms and at most about 1000ms
    // This formula makes the wait time linear to speed slider value, from 250ms to 750ms
    if (value !== MAX_SPEED)
      setWaitTime(800 - value * 5);
    else setWaitTime(0);
    //setWaitTime(501 - value * 5);
  }, [value, setWaitTime]);

  return (
    <div>
      <Box fontWeight="fontWeightBold" m={1}>
        <Typography id="non-linear-slider" gutterBottom>
          Adjust Speed
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <img
            src="https://res.cloudinary.com/csexercises/image/upload/v1596541143/utils/iconfinder_turtle-24_302349_lp7qne.png"
            alt="Turtle"
          />
        </Grid>
        <Grid item xs={8}>
          <Slider
            value={value}
            min={1}
            step={1}
            max={100}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Grid>
        <Grid item xs={2}>
          <img
            src="https://res.cloudinary.com/csexercises/image/upload/v1596541149/utils/iconfinder_running_rabbit-24_302340_j2y2jf.png"
            alt="Turtle"
          />
        </Grid>
      </Grid>
    </div>
  );
};

SpeedSlider.propTypes = {
  setWaitTime: PropTypes.func.isRequired,
  setIsMaxspeed: PropTypes.func,
};

export default SpeedSlider;
