import React, { useEffect, useState } from 'react';
import CodeInputArea from '../../CodeInputArea';
import { GeneralText } from '../../../common/CommonStyles';
import styled from '@emotion/styled';

const CodeInput = ({ title, type, onSubmission, initialCode, language, theme, index, minLines, maxLines, }) => {
    const [code, setCode] = useState(initialCode ? initialCode : '');
    const [previousCode, setPreviousCode] = useState('');

    useEffect(() => {
        if (code !== previousCode) {
            // Splits the code string into an array of lines, excluding escaped newline sequences
            if (type == "code-input") {
                setPreviousCode(code);
                onSubmission([code], index);
            }
            if (type == "code-input-only") {
                const codeLines = code.split(/(?<!\\)\n/).filter(line => line.trim() !== '');
                setPreviousCode(code);
                onSubmission(codeLines, index);
            }
        }
    }, [code]);

    return (
        <CodeBox>
            {title && (
                <GeneralText style={{ fontWeight: "bold", textAlign: "left", marginBottom: "10px" }}>{title}</GeneralText>
            )}
            <CodeInputArea
                language={language}
                code={code} setCode={setCode}
                theme={theme} minLines={minLines} maxLines={maxLines}
            />
        </CodeBox>
    );
};

export default CodeInput;

const CodeBox = styled.div`
 margin: 10px 0px;
`