import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GeneralText } from '../../common/CommonStyles';

const Introduction = ({ content }) => {
    return (
        <GeneralText align="left" style={{ color: "#2196f3" }} >
            <ReactMarkdown>{content}</ReactMarkdown>
        </GeneralText>
    );
};

export default Introduction;