import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    start: { 
      width: "20px",
      height:"20px",

      borderRadius:"10px",

      background:"#F37463",
      position:"absolute",
      left:"45%",
      bottom: "-12px",
      zIndex:"999"
    }
  }));

const ConnectorDotStart = (props) => {

  const classes = useStyles();

  const [connectors, setConnectors] = useState([]);

  const updateConnectors = (connectorIndex, index) => {

    let updated = connectors.slice();
    updated.push(connectorIndex);
    updated.sort();

    setConnectors(updated);
  }


  useEffect(() => {
    props.updateConnections(connectors, props.index);
  }, [connectors])

  const drop = (event) => {
      event.preventDefault();
      const connectorId = event.dataTransfer.getData('connector_id');
      
      const connectorIndex = event.dataTransfer.getData('value');

      const connector = document.getElementById(connectorId);
      const board = document.getElementById(props.parentId);
      if (connector == null) {
          return;
      }
      connector.style.left = "45%";
      connector.style.bottom = "50px";
      connector.style.opacity = "0";
      connector.style.top = "62px";
      connector.style.zIndex = "0";
      connector.style.pointerEvents = "none";
      if (board != null) {
        board.appendChild(connector);
      }

      updateConnectors(connectorIndex, props.index);
  }

  const dragOver = (event) => {
      event.preventDefault();
  }

    return (
      <div
      id={props.id}
      onDrop={drop}
      onDragOver={dragOver}
      className={classes.start}>
          { props.children }
      </div>
    )
}

export default ConnectorDotStart;