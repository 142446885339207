/* eslint-disable no-underscore-dangle */

import _ from 'lodash';
import { SET_EXERCISES, SET_CURR_EXE, COMPLETE_EXERCISE, SET_ATTEMPT, SOUND_ON, SOUND_OFF, SET_SOLVE_TIME, SET_WORKSPACE } from '../actions';

const INITIAL_STATE = {
  ids: [],
  performance: [],
  current: null,
  isSoundOn: true,
  solveTime: 0,
  workspace: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXERCISES:
      return {
        ...state,
        ids: action.payload.ids.map((exercise) => ({
          id: exercise.exercise_code,
          previous_code: exercise.previous_code,
          previous_time: exercise.previous_time,
          completed: false,
          type: exercise.exercise_type.toLowerCase()
        }))
      };
    case SET_CURR_EXE:
      return {
        ...state,
        current: action.payload
      };
    case COMPLETE_EXERCISE:
      if (state.current) {
        const exerciseIdx = state.ids.findIndex(
          (elem) => elem.id === state.current._id
        );
        if (exerciseIdx === -1) {
          return state;
        } else {
          return {
            ...state,
            // // This removes the exercise from exercise list
            // ids: state.ids.filter((element) => {
            //   return element.id !== state.current._id;
            // })
            // This preserves the exercise in the exercise list with flag completed turned into true
            ids: state.ids.map((element) => {
              if (element.id === state.current._id) {
                return {
                  ...element,
                  completed: true
                };
              } else {
                return element;
              }
            })
          };
        }
      }
      return state;
    case SET_ATTEMPT:
      if (state.current) {
        const exerciseIdx = state.ids.findIndex(
          (elem) => elem.id === state.current._id
        );
        if (exerciseIdx === -1) {
          return state;
        } else {
          return {
            ...state,
            ids: state.ids.map((element) => {
              if (element.id === state.current._id) {
                return {
                  ...element,
                  previous_code: action.payload.previous_code,
                  previous_line: action.payload.previous_line,
                  previous_time: action.payload.previous_time
                };
              } else {
                return element;
              }
            })
          };
        }
      }
      return state;
    case SET_SOLVE_TIME:
      return {
        ...state,
        solveTime: action.payload,
      }
    case SOUND_ON:
      return {
        ...state,
        isSoundOn: true,
      }
    case SOUND_OFF:
      return {
        ...state,
        isSoundOn: false,
      }
    case SET_WORKSPACE:
      return {
        ...state,
        workspace: action.payload,
      }
    default:
      return state;
  }
};
