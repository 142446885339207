import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import LoadingPage from './utils/LoadingPage';
import { signIn, signOut, setExercises } from '../actions';
import { storeUserCookies } from './utils/utils';

const Direct = (props) => {
  const { location, history } = props;
  const parsed = queryString.parse(location.search);

  if (!parsed.tempToken || !parsed.id || !parsed.type) {
    props.enqueueSnackbar('Invalid exercise link', {
      variant: 'error'
    });
    props.signOut();
    history.push('/signin');
  }

  axios
    .post(`${process.env.REACT_APP_LMS_API}/generatepermanenttoken`, null, {
      headers: { Authorization: `Token ${parsed.tempToken}` }
    })
    .then((response) => {
      storeUserCookies({
        user: response.data.username,
        token: response.data.permanant_token,
        expiry: new Date(response.data.expiry_date)
      });

      // Add the directed id at the beginning of the exercise list
      props.setExercises(parsed.type, response.data.exercise_list);

      props.signIn();

      if (parsed.history && parsed.exercisecode && parsed.exerciseattemptcounter && parsed.attemptstatus) {
        const historyRequestBody = {
          ExerciseCode: parsed.exerisecode,
          exerciseattemptcounter: parsed.exerciseattemptcounter,
          attemptstatus: parsed.attemptstatus
        };
        axios.post(`${process.env.REACT_APP_LMS_API}/exercisehistory`, historyRequestBody, {
          headers: { Authorization: `Token ${parsed.tempToken}` }
        }).then((response) => {
          console.log(response);
        })
      }
      history.push(`/${parsed.type}/${parsed.id}`);
    })
    .catch(() => {
      props.enqueueSnackbar('Invalid exercise link', {
        variant: 'error'
      });
      props.signOut();
      history.push('/signin');
    });

  return <LoadingPage />;
};

Direct.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
  signOut: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  setExercises: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default connect(null, { signIn, signOut, setExercises })(
  withSnackbar(Direct)
);
