import React from 'react';

export default function WarningIcon() {
  return (
    <svg style={{width: '75px', height: '75px'}}class="warning_icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 38.092 33.992" >
      <g id="Polygon_2" data-name="Polygon 2" transform="translate(0.046 -0.508)" fill="#ffd400" stroke-linecap="round" stroke-linejoin="round">
        <path d="M 34.54066848754883 33.75 L 3.459330081939697 33.75 C 2.465840101242065 33.75 1.575839996337891 33.23650741577148 1.078580021858215 32.37643051147461 C 0.5813199877738953 31.51632881164551 0.5803999900817871 30.48882865905762 1.076110005378723 29.62784957885742 L 16.61677932739258 2.636149168014526 C 17.11352920532227 1.77337908744812 18.00444984436035 1.258299112319946 19 1.258299112319946 C 19.99555015563965 1.258299112319946 20.88647079467773 1.77337908744812 21.38320922851562 2.636149168014526 L 36.92388916015625 29.62784957885742 C 37.41960144042969 30.48881912231445 37.41867828369141 31.51631927490234 36.92142868041992 32.37641906738281 C 36.42417144775391 33.23650741577148 35.53416061401367 33.75 34.54066848754883 33.75 Z" stroke="none"/>
        <path d="M 18.99999809265137 2.00830078125 C 18.32569313049316 2.00830078125 17.6513843536377 2.342323303222656 17.26675033569336 3.010368347167969 L 1.726081848144531 30.0020694732666 C 0.9584007263183594 31.33539962768555 1.920791625976562 33 3.459320068359375 33 L 34.54066848754883 33 C 36.07920837402344 33 37.04159164428711 31.33539962768555 36.27391815185547 30.0020694732666 L 20.73324012756348 3.010368347167969 C 20.34860992431641 2.342323303222656 19.6743049621582 2.00830078125 18.99999809265137 2.00830078125 M 19 0.50830078125 C 20.26707077026367 0.50830078125 21.40096092224121 1.163860321044922 22.03318023681641 2.261928558349609 L 37.57384872436523 29.25362014770508 C 38.20476150512695 30.34941864013672 38.20359039306641 31.65714836120605 37.57070922851562 32.75180816650391 C 36.93783950805664 33.84646987915039 35.80511093139648 34.5 34.54066848754883 34.5 L 3.459320068359375 34.5 C 2.194881439208984 34.5 1.062149047851562 33.84646987915039 0.4292793273925781 32.75180816650391 C -0.2036018371582031 31.65713882446289 -0.2047691345214844 30.34940910339355 0.4261512756347656 29.25362014770508 L 15.96681976318359 2.261920928955078 C 16.59902954101562 1.163860321044922 17.73292922973633 0.50830078125 19 0.50830078125 Z" stroke="none"/>
      </g>
      <path id="Path_9641" data-name="Path 9641" d="M3.271-8.992l-.453-6.783q-.127-1.982-.127-2.846a2.58,2.58,0,0,1,.616-1.834,2.116,2.116,0,0,1,1.621-.658,1.632,1.632,0,0,1,1.628.843,5.7,5.7,0,0,1,.411,2.428,18.523,18.523,0,0,1-.1,1.9L6.259-8.963a5.472,5.472,0,0,1-.425,1.912,1.113,1.113,0,0,1-1.076.666A1.071,1.071,0,0,1,3.7-7.031,6.411,6.411,0,0,1,3.271-8.992ZM4.843-.674a2.228,2.228,0,0,1-1.508-.559A1.962,1.962,0,0,1,2.69-2.8a2.034,2.034,0,0,1,.616-1.494,2.051,2.051,0,0,1,1.508-.616,2.1,2.1,0,0,1,1.522.616A2.011,2.011,0,0,1,6.967-2.8,1.977,1.977,0,0,1,6.33-1.241,2.168,2.168,0,0,1,4.843-.674Z" transform="translate(14.046 29.492)"/>
    </svg>
  );
}