import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  end: { 
      width: "20px",
      height:"20px",

    borderRadius:"10px",

    background:"#90EE90",
    position:"absolute",
    left:"45%",
    top:"-10px",
    cursor:"pointer",

  }
  }));

const ConnectorDot = (props) => {

  const classes = useStyles();

    return (
      <div
      id={props.id}
      className={classes.end}>
      </div>
    )
}

export default ConnectorDot;