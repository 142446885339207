import * as React from "react";
import { useState, useEffect } from 'react';
import { Card, Grid, CardContent, Typography, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const TrueFalse = ({ onSubmission, index }) => {

    const [trueBorder, setTrueBorder] = useState("2px solid transparent");
    const [falseBorder, setFalseBorder] = useState("2px solid transparent");
    const [trueSelected, setTrueSelected] = useState(false);
    const [falseSelected, setFalseSelected] = useState(false);
    //const [buttonText, setButtonText] = useState("SKIP");

    const useStyles = makeStyles((theme) => ({
        root: {
            alignItems: "centre",
            justifyContent: "centre",
            display: "flex"
        },
        card: {
            width: "100%",
            margin: "5px",
            height: "50px"
        },
        cardContent: {
            textAlign: 'left',
            //color: theme.palette.text.secondary
        },
        choice: {
            fontSize: "18px",
            // margin: "2px",
            textAlign: "center"
        }
    }));

    // const reset = () => {
    //     setFalseSelected(false);
    //     setFalseBorder("2px solid transparent");
    //     setTrueSelected(false);
    //     setTrueBorder("2px solid transparent");
    //     setButtonText("SKIP");
    // }

    const changeTrueButton = () => {
        if (falseSelected) {
            setFalseSelected(false);
            setFalseBorder("2px solid transparent");
        }
        setTrueSelected(true);
        setTrueBorder("2px solid #3f51b5");
        //setButtonText("SUBMIT");
    }

    const changeFalseButton = () => {
        if (trueSelected) {
            setTrueSelected(false);
            setTrueBorder("2px solid transparent");
        }
        setFalseSelected(true);
        setFalseBorder("2px solid #3f51b5");
        //setButtonText("SUBMIT");
    }

    useEffect(() => {
        if (trueSelected !== falseSelected) {
            const submission = trueSelected ? ['true'] : ['false'];
            onSubmission(submission, index);
            //reset();
        }
    }, [trueSelected, falseSelected]);

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container >
                <Grid item xs={6} className={classes.root}>
                    <Card className={classes.card} style={{ border: trueBorder, background: "#ECECEC" }}>
                        <CardActionArea onClick={changeTrueButton} style={{ height: "100%" }}>
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom className={classes.choice} component="div">
                                    TRUE
                                    {/* <FaCheck style={{color: '#47b982', fontSize: '25px'}}/>  */}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} className={classes.root}>
                    <Card className={classes.card} style={{ border: falseBorder, background: "#ECECEC" }}>
                        <CardActionArea onClick={changeFalseButton} style={{ height: "100%" }}>
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom className={classes.choice} component="div">
                                    FALSE
                                    {/* <FaTimes style={{color: '#e82c2c', fontSize: '25px'}}/> */}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default TrueFalse;
