import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Xarrow, {useXarrow, Xwrapper} from 'react-xarrows';
import { update } from 'lodash';


const useStyles = makeStyles((theme) => ({
    end: { 
        width: "20px",
        height:"20px",

      borderRadius:"10px",

      background:"#90EE90",
      position:"absolute",
      left:"45%",
      top:"-10px",
      cursor:"pointer",

    }
  }));

  const ConnectorDotEnd = (props) => {

    const classes = useStyles();

    const updateXarrow = useXarrow();

    const dragStart = (event) => {
        const target = event.target;

        event.dataTransfer.setData("connector_id", target.id);
        
        event.dataTransfer.setData("value", props.index);
    }

    const dragOver = (event) => {
        event.stopPropagation();
    }

    return (
        <div
            id={props.id}
            className={classes.end}
            draggable={props.draggable}
            onDragStart={dragStart}
            onDrag={updateXarrow}
            onDragEnd={updateXarrow}
            onDragOver={dragOver}
        >
            { props.children }
        </div>
    )
}
export default ConnectorDotEnd;