import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Grid } from '@material-ui/core';
import _ from 'lodash';

const TextBoxes = ({ onSubmission, content, index }) => {
  const TYPOGRAPHY_H6_SIZE = 20;
  // TODO: Add more?
  const CHARACTER_WIDTH = 0.625 * TYPOGRAPHY_H6_SIZE; // TODO: Fix with all size
  const LABEL_SIZE = 0.75 * TYPOGRAPHY_H6_SIZE; // TODO: Fix with all size
  const TEXT_FIELD_BOTTOM_MARGIN = 0.75 * TYPOGRAPHY_H6_SIZE; // TODO: Fix with all size

  const [output, setOutput] = useState([]);
  const [textFieldIndexMapping, setTextFieldIndexMapping] = useState({});

  const getTextBoxInputID = (groupIndex, contentIndex, index) => {
    return "text-box-input-" + index.toString() +
      '-' + groupIndex.toString() +
      '-' + contentIndex.toString();
  }

  useEffect(() => {
    // Initialize mapping so that fragment of type input will be mapped to a 
    // unique index in the output state
    let counter = 0;
    let indexMapping = {};
    for (let groupIndex = 0; groupIndex < content.length; ++groupIndex) {
      // Group of one line for text box input
      for (let componentIndex = 0; componentIndex < content[groupIndex].length; ++componentIndex) {
        if (content[groupIndex][componentIndex].type === 'input') {
          // Text box input id
          let textBoxInputID = getTextBoxInputID(groupIndex, componentIndex, index);
          indexMapping[textBoxInputID] = counter;
          counter++;
        }
      }
    }
    setTextFieldIndexMapping(indexMapping);
    setOutput(Array(counter).fill(""));
  }, [])

  useEffect(() => {
    onSubmission(output, index);
  }, [output])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      m={1}
      p={1}
      bgcolor="background.paper"
    >
      <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ display: "block" }}>
        {content.map((group, groupIndex) => {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              {
                group.map((component, componentIndex) => {
                  if (component.type === 'text') {
                    return (
                      <Typography variant="h6"
                        style={{
                          fontFamily: "Courier New",
                          marginLeft: CHARACTER_WIDTH
                        }}
                      >
                        {component.value}
                      </Typography>
                    );
                  } else if (component.type === 'input') {
                    let props = {
                      defaultValue: component.defaultValue !== undefined ? component.defaultValue : "",
                      style: {
                        width: CHARACTER_WIDTH * component.length,
                        marginBottom: TEXT_FIELD_BOTTOM_MARGIN,
                        marginLeft: CHARACTER_WIDTH
                      },
                      InputProps: {
                        style: {
                          fontFamily: 'Courier New',
                          textAlign: 'center',
                          fontSize: TYPOGRAPHY_H6_SIZE,
                          fontWeight: 'bold'
                        }
                      }
                    }
                    if (component.label) {
                      props.label = component.label;
                      props.InputLabelProps = {
                        style: {
                          fontFamily: 'Courier New',
                          textAlign: 'center',
                          fontSize: LABEL_SIZE
                        }
                      };
                    }
                    // TODO: Maybe different output if label is present or not to make it easy to balance
                    return (
                      <TextField
                        placeholder="write here"
                        id={getTextBoxInputID(groupIndex, componentIndex, index)} variant="standard"
                        onChange={(event) => {
                          // Shallow copy original output array
                          let newOutput = [...output];
                          // Edit part of the corresponding output in the newOutput variable
                          const outputIndex = textFieldIndexMapping[event.target.id];
                          newOutput[outputIndex] = event.target.value;
                          setOutput(newOutput);
                        }}
                        {...props}
                      />
                    );
                  } else {
                    return (
                      <Typography variant="h6"
                        style={{
                          fontFamily: "Courier New", marginLeft: 5, color: "red"
                        }}
                      >
                        {component.type} is not a valid prop type
                      </Typography>
                    );
                  }
                })
              }
              < Typography display={"block"} />
            </Box>
          );
        })
        }
      </Grid >
    </Box >
  );
};

export default TextBoxes;