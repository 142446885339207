/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectionBoard from './connectors/SelectionBoard';
import DiagramLayout from './connectors/DiagramLayout';

import {  Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      //color: theme.palette.text.secondary,
      height: "850px"
  },
  question: {
      padding: theme.spacing(2),
      //color: theme.palette.text.secondary
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));


const DragDropArrow = ({onSubmission, index, content}) => {

  // Component styling and screen resolution
  const classes = useStyles();
  // If styling requires screen width and height use these
  // const { screenWidth, screenHeight } = useWindowDimensions();
  // const [solveTime, setSolveTime] = useState(0);

  const [answers, setAnswers] = useState([]);
  const [connections, setConnections] = useState([]);



  useEffect(() => {
    if (answers.length > 0 && connections.length > 0){
      // need to include connectors as well
      onSubmission({"answers": answers, "connections": connections}, index);
    }
  },[answers, connections])


  const updateAnswer = (value, index) => {
    let updated = answers.slice();
    updated[index] = value;

    setAnswers(updated);
  }

  const updateConnections = (connectors, index) => {
    let updated = connections.slice();
    updated[index] = connectors;


    setConnections(updated);
  }

  // Try to use Material UI (currently v4, upgrading to v5 when have the time) 
  // instead of primitive HTML tags
  // https://v4.mui.com=
  return (
    <div className={classes.root}> 
              <Paper style={{ padding:"20px" }}>
                <SelectionBoard id="board-1" cards={content.cards}/>
                <DiagramLayout
                  root={content.root}
                  maxDepth={content.maxDepth}
                  updateAnswer={updateAnswer}
                  updateConnections={updateConnections}
                />
              </Paper>   
    </div>
  );
};





export default DragDropArrow;