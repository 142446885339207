import React from "react";
import { Typography, Paper, Grid, Box, Button, Tab, Tabs } from '@material-ui/core';
import Board from './Board';
import Card from "./Card";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    board: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "150px",
      height:"60px",
      padding: "5px",
      borderRadius:"8px",
      margin: "20px"
    },
    card: {
      padding: "15px 5px",  
      cursor:"pointer",
      borderRadius:"5px",
      margin: "8px 5px",
    }
  }));



const DiagramLayout = (props) => {

    const classes = useStyles();

    const getCards = (root, level) => {
        if (root == null) {
            return;
        }
        let items = [];
        
        let queue = [];
        queue.push(root)
        
        while (queue.length > 0) {
            let size = queue.length;
            for (let i = 0; i < size; i++) {
                let currentCard = queue.shift();

                if (currentCard.children != null) {
                    for (let j = 0; j < currentCard.children.length; j++) {
                        queue.push(currentCard.children[j]);
                    }
                }

                if (currentCard.level == level) {
                    items.push(currentCard);
                }
            }
        }
        return items;
    }

    return (
        <div className={classes.root}>
            <div className={classes.row}  style={{ zIndex: "3" }}>
                {
                    getCards(props.root, 0).map((element) => {
                        return (
                            <Board
                            key={`board-${element.id}-${element.level}`}
                            id={`board-${element.id}-${element.level}`}
                            depth={0}
                            maxDepth={props.maxDepth}
                            updateAnswer={props.updateAnswer}
                            // updateConnections={props.updateConnections}
                            index={element.id}/>
                        )}
                    )
                }
            </div>
            <div className={classes.row}  style={{ zIndex: "2" }}>
                {
                    getCards(props.root, 1).map((element) => {
                        return (
                            <Board
                            key={`board-${element.id}-${element.level}`}
                            id={`board-${element.id}-${element.level}`}
                            depth={1}
                            maxDepth={props.maxDepth}
                            updateAnswer={props.updateAnswer}
                            // updateConnections={props.updateConnections}
                            index={element.id}/>
                        )}
                    )
                }
            </div>
            <div className={classes.row} style={{ zIndex: "1" }}>
                {
                    getCards(props.root, 2).map((element) => {
                        return (
                            <Board
                            key={`board-${element.id}-${element.level}`}
                            id={`board-${element.id}-${element.level}`}
                            depth={2}
                            maxDepth={props.maxDepth}
                            updateAnswer={props.updateAnswer}
                            // updateConnections={props.updateConnections}
                            index={element.id}/>
                        )}
                    )
                }
            </div>
        </div>
    );
}

export default DiagramLayout;
