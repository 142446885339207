import React, { useState, useRef } from "react";
import ReactMarkdown from 'react-markdown';
import Draggable from "react-draggable";
import { Button, Box, Typography } from "@mui/material";

const connections = { class: [], interface: {} };

const DragDropArrow = (props) => {
  const { cards, onConnectionsUpdate, onReset } = props;
  const [arrows, setArrows] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startCard, setStartCard] = useState(null);
  const [lineType, setLineType] = useState("class");
  const containerRef = useRef(null);
  const cardRefs = useRef({});

  const toggleDrawingMode = (type) => {
    if (isDrawing && lineType === type) {
      setIsDrawing(false);
      setStartCard(null);
    } else {
      setIsDrawing(true);
      setLineType(type);
      setStartCard(null);
    }
  };

  const handleCardClick = (cardId) => {
    if (!isDrawing) return;
    if (startCard === null) {
      setStartCard(cardId);
    } else {
      const newArrow = { from: startCard, to: cardId, type: lineType };
      setArrows((prev) => [...prev, newArrow]);

      if (lineType === "class") {
        connections.class.push({ [startCard]: cardId });
      } else if (lineType === "interface") {
        if (!connections.interface[startCard]) {
          connections.interface[startCard] = [];
        }
        connections.interface[startCard].push(cardId);
      }

      const updatedConnections = { ...connections };
      if (onConnectionsUpdate) {
        onConnectionsUpdate(updatedConnections);
      }

      setStartCard(null);
      setIsDrawing(false);
    }
  };

  const getCardCenter = (cardId) => {
    const card = cardRefs.current[cardId];
    if (!card) return { x: 0, y: 0 };
    const rect = card.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    return {
      x: rect.left - containerRect.left + rect.width / 2,
      y: rect.top - containerRect.top + rect.height / 2,
    };
  };

  const renderArrows = () => {
    return arrows.map((arrow, index) => {
      const start = getCardCenter(arrow.from);
      const end = getCardCenter(arrow.to);
      return (
        <line
          key={index}
          x1={start.x}
          y1={start.y}
          x2={end.x}
          y2={end.y}
          stroke="#1565c0"
          strokeWidth="1.5"
          strokeDasharray={arrow.type === "interface" ? "5,5" : "0"}
          markerEnd="url(#arrowhead)"
        />
      );
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button
          variant={isDrawing && lineType === "class" ? "contained" : "outlined"}
          color="primary"
          onClick={() => toggleDrawingMode("class")}
          sx={{ fontSize: "0.8rem", padding: "4px 8px", minWidth: "80px" }}
        >
          {isDrawing && lineType === "class" ? "Cancel" : "Draw Class"}
        </Button>
        <Button
          variant={isDrawing && lineType === "interface" ? "contained" : "outlined"}
          color="primary"
          onClick={() => toggleDrawingMode("interface")}
          sx={{ fontSize: "0.8rem", padding: "4px 8px", minWidth: "80px" }}
        >
          {isDrawing && lineType === "interface" ? "Cancel" : "Draw Interface"}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            connections.class = [];
            connections.interface = {};
            setArrows([]);
            if (onConnectionsUpdate) {
              onConnectionsUpdate({ class: [], interface: {} });
            }
            onReset();
          }}
          sx={{ fontSize: "0.8rem", padding: "4px 8px", minWidth: "80px" }}
        >
          Reset
        </Button>
      </Box>

      <Box
        ref={containerRef}
        sx={{
          width: "100%",
          height: "350px",
          position: "relative",
          border: "2px solid #bdbdbd",
          borderRadius: "10px",
          backgroundColor: "#fafafa",
          backgroundImage: "radial-gradient(#e0e0e0 1px, transparent 1px)",
          backgroundSize: "20px 20px",
        }}
      >
        <svg
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
          }}
        >
          <defs>
            <marker
              id="arrowhead"
              markerWidth="10"
              markerHeight="10"
              refX="20"
              refY="3"
              orient="auto"
            >
              <path d="M0,0 L0,6 L9,3 z" fill="#fafafa" stroke="#2196f3" strokeWidth="1" />
            </marker>
          </defs>
          {renderArrows()}
        </svg>

<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-start",
    height: "100%",
    overflowY: "auto",
  }}
>
  {cards.map((card) => (
    <Draggable
      key={card.id}
      bounds="parent"
      defaultPosition={{ x: 0, y: 0 }}
    >
      <Box
        ref={(el) => (cardRefs.current[`card-${card.id}`] = el)}
        sx={{
          position: "relative",
          backgroundColor: "#2196f3",
          color: "white",
          padding: "8px 12px",
          borderRadius: "6px",
          cursor: "pointer",
          boxShadow: 3,
          "&:hover": { backgroundColor: "#1565c0" },
          whiteSpace: "pre-wrap",
          textAlign: "center",
        }}
        onClick={() => handleCardClick(`card-${card.id}`)}
      >
        <Typography variant="body1">{card.value.replace(/\\n/g, "\n")}</Typography>
      </Box>
    </Draggable>
  ))}
</Box>

      </Box>
    </Box>
  );
};

const DragDropArrowWrapper = ({ cards, captureArrow }) => {
  const [key, setKey] = useState(0);

  const handleReset = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const handleConnectionsUpdate = (updatedConnections) => {
    if (!updatedConnections) {
        captureArrow([""]);
        return;
    }

    const sortedConnections = JSON.parse(JSON.stringify(updatedConnections));

    if (Array.isArray(sortedConnections.class)) {
        sortedConnections.class.sort((a, b) => {
        const keyA = Object.keys(a)[0]; 
        const keyB = Object.keys(b)[0];
        return keyA.localeCompare(keyB); 
        });
    }

    if (sortedConnections.interface) {
        Object.keys(sortedConnections.interface).forEach((key) => {
        sortedConnections.interface[key].sort((a, b) => a.localeCompare(b));
        });
    }

    const formattedConnections = [JSON.stringify(sortedConnections)];
    captureArrow(formattedConnections);
  };


  return (
    <DragDropArrow
      key={key}
      cards={cards}
      onReset={handleReset}
      onConnectionsUpdate={handleConnectionsUpdate}
    />
  );
};

export default DragDropArrowWrapper;
