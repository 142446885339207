import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import BlocklyComponent from '../blockly/Blockly';
import useWindowDimensions from '../utils/useWindowDimensions';
import Grid from '@material-ui/core/Grid';
import Timer from '../utils/Timer';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minWidth: 350,
    },
    minWidth: {
        minWidth: '300px',
    },
    containerMinWidth: {
        minWidth: '350px',
    },
    title: {
        background: '#F9E104',
    },
    number: {
        background: '#FFF',
        color: 'blue',
    },
    table: {
        width: '100%',
    }
}));

const CustomBlocklyWorkspace = ({ workspace, exercise, setProgram, setCodeView, setWorkspace, isMute, solveTime }) => {
    // Blockly workspace styling
    const classes = useStyles();
    const { screenWidth, screenHeight } = useWindowDimensions();
    const [workspaceWidth, setWorkspaceWidth] = useState(1000);
    const [workspaceHeight, setWorkspaceHeight] = useState(1000);
    const [isRender, setIsRender] = useState(false);

    useEffect(() => {
        setIsRender(true);
    }, []);


    useEffect(() => {
        // Edit workspace height and width based on screen size
        if (screenWidth > 1200) {
            // Vertical split
            setWorkspaceWidth(0.4 * screenWidth);
        } else {
            // No vertical split
            setWorkspaceWidth(0.8 * screenWidth);
        }
        setWorkspaceHeight(0.8 * screenHeight);
    }, [screenWidth, screenHeight])

    return (
        <Box
            className={classes.containerMinWidth}
            display="flex"
            justifyContent="center"
            m={1}
            p={1}
            bgcolor="background.paper"
        >
            <Paper className={classes.paper}>
                {/* Blocks used count */}
                <h4 className={classes.title}>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <Timer time={solveTime} />
                        </Grid>

                        <Grid item xs={8}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    {`Exercise ${exercise._id} work space`}
                                </Grid>
                                <Grid item xs={6}>
                                    Blocks used:{' '}
                                    <span className={classes.number}>
                                        {workspace ? workspace.getAllBlocks().length - 1 : 0}
                                    </span>
                                </Grid>
                                <Grid item xs={6}>
                                    Expected blocks:{' '}
                                    <span className={classes.number}>
                                        {exercise.expectedBlocks}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </h4>
                { /* Actual blockly workspace*/}
                <BlocklyComponent
                    width={workspaceWidth}
                    height={workspaceHeight}
                    setProgram={setProgram}
                    initialWorkspace={exercise.initialWorkspace}
                    toolCategories={
                        exercise.toolCategories.length > 0
                            ? exercise.toolCategories
                            : undefined
                    }
                    toolBlocks={
                        exercise.toolBlocks.length > 0
                            ? exercise.toolBlocks
                            : undefined
                    }
                    setWorkspace={setWorkspace}
                    setCodeView={setCodeView}
                    varNames={
                        exercise.variables && exercise.variables.length > 0
                            ? exercise.variables.map((variable) => variable[1])
                            : undefined
                    }
                    isMute={isMute}
                />
            </Paper>
        </Box>
    )
}

CustomBlocklyWorkspace.propTypes = {
    SCALE: PropTypes.number,
    exercise: PropTypes.object,
    setProgram: PropTypes.func,
    setCodeView: PropTypes.func,
    setWorkspace: PropTypes.func
}

const mapStateToProps = (state) => ({
    solveTime: state.exercises.solveTime,
});

export default connect(mapStateToProps)(withSnackbar(CustomBlocklyWorkspace));
