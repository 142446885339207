import React from 'react';
import { Grid, Box } from '@mui/material';

const boxStyles = {
    width: 40, 
    height: 40, 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e0e0e0', 
    border: '1px solid #bdbdbd', 
    borderRadius: '8px', 
    fontFamily: "Courier New",
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#424242', 
    textAlign: 'center',
    wordWrap: 'break-word',
    margin: '5px', 
};

export const GridLine = ({ payload }) => {
    const payloadString = Array.isArray(payload) ? payload[0] : payload;

    const contentArray = typeof payloadString === 'string' 
        ? payloadString.split('~').map(item => item.trim())
        : [];

    return (
        <Grid container spacing={1} justifyContent="center">
            {contentArray.map((item, index) => (
                <Grid item key={index}>
                    <Box sx={boxStyles}>
                        {item}
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};
