import React from 'react';

const Image = ({ content }) => {
    return (
        <div>
            <img
                src={content.src}
                alt={content.alt}
                style={{ width: "100%", height: "100%", display: "inline-block" }}
            />
            <br />
        </div>
    );
};

export default Image;