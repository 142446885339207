import React from 'react';
import { Circle, Line, Group } from 'react-konva';
const Paths = ({ paths, scale, colour, width }) => {

    const generateElem = paths.map((path) => {
        const [x1, y1, x2, y2] = path.path;
        return (
            <Group>
                <Circle
                    radius={0.1 * (width ? width : scale)}
                    fill={colour ? colour : path.colour}
                    x={x1 * scale}
                    y={y1 * scale}
                    opacity={path.alpha / 100}



                />
                <Line
                    points={[x1 * scale, y1 * scale, x2 * scale, y2 * scale]}
                    stroke={colour ? colour : path.colour}
                    strokeWidth={0.07 * path.width * (width ? width * 1.5 : scale)}
                    opacity={path.alpha / 100}




                />
                <Circle
                    radius={0.1 * (width ? width : scale)}
                    fill={colour ? colour : path.colour}
                    x={x2 * scale}
                    y={y2 * scale}
                    opacity={path.alpha / 100}



                />

            </Group>
        );
    });


    return (
        generateElem
    );
};

export default Paths;